import { Inject, Injectable, InjectionToken, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  constructor(@Inject(PLATFORM_ID) private platformId: any) {}

  setItem(key: string, value: any): void {
    if (isPlatformBrowser(this.platformId)) localStorage.setItem(key, value);
  }

  getItem(key: string): any {
    if (isPlatformBrowser(this.platformId)) return localStorage.getItem(key);
  }

  removeItem(key: string): void {
    if (isPlatformBrowser(this.platformId)) localStorage.removeItem(key);
  }

  clear(): void {
    if (isPlatformBrowser(this.platformId)) localStorage.clear();
  }

  sessionLength(): number {
    if (isPlatformBrowser(this.platformId)) return sessionStorage.length;
    return 0
  }

  clearSession(): void {
    if (isPlatformBrowser(this.platformId)) sessionStorage.clear();
  }
}
