import { NgModule } from '@angular/core';
import { CountryStoreModule } from './country-store/country-store.module';
import { CultureStoreModule } from './culture-store/culture-store.module';
import { UserLocationStoreModule } from './user-location-store/user-location-store.module';

@NgModule({
  imports: [CountryStoreModule, CultureStoreModule, UserLocationStoreModule],
  exports: [CountryStoreModule, CultureStoreModule, UserLocationStoreModule],
})
export class SharedStoreModule {}
