import { Component, OnInit, Inject, ViewEncapsulation, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ImageViewer } from '../../models/image.viewer';

@Component({
  selector: 'app-image-viewer',
  templateUrl: './image-viewer.component.html',
  styleUrls: ['./image-viewer.component.scss'],
  encapsulation: ViewEncapsulation.None 
})
export class ImageViewerComponent implements OnInit, AfterViewInit {

  // tslint:disable-next-line: max-line-length
  public tempVideoUrl = 'https://liveguide.imgix.net/collection/53227e9b-96e6-4203-b5f9-948106248fb7/c4e885a4-0c82-49f4-829b-56e4aa027d26.mp4';

  public previewerConfig = {
    btnClass: 'default', // The CSS class(es) that will apply to the buttons
    zoomFactor: 0.1, // The amount that the scale will be increased by
    containerBackgroundColor: '#000', // The color to use for the background. This can provided in hex, or rgb(a).
    wheelZoom: true, // If true, the mouse wheel can be used to zoom in
    allowFullscreen: true, // If true, the fullscreen button will be shown, allowing the user to entr fullscreen mode
    allowKeyboardNavigation: true, // If true, the left / right arrow keys can be used for navigation
    btnIcons: { // The icon classes that will apply to the buttons. By default, font-awesome is used.
      zoomIn: 'fa fa-plus',
      zoomOut: 'fa fa-minus',
      rotateClockwise: 'fa fa-repeat',
      rotateCounterClockwise: 'fa fa-undo',
      next: 'fa fa-arrow-right',
      prev: 'fa fa-arrow-left',
      fullscreen: 'fa fa-arrows-alt',
    },
    btnShow: {
      zoomIn: true,
      zoomOut: true,
      rotateClockwise: false,
      rotateCounterClockwise: false,
      next: true,
      prev: true
    }
  };

  @ViewChild('videoPlayer', { static: false}) videoplayer: ElementRef;

  constructor(
    public dialog: MatDialogRef<ImageViewerComponent>,
    @Inject(MAT_DIALOG_DATA) public _data: ImageViewer,
  ) { }

  ngOnInit(): void {
  }

  ngAfterViewInit(): void {
    if (this._data.isVideo) {
      this.videoplayer.nativeElement.play();
    }
  }

}
