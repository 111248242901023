import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { AppState } from './app.state';
import * as fromAction from './app.action';
import * as fromSelector from './app.selector';

@Injectable({
  providedIn: 'root',
})
export class AppStoreService {
  constructor(private store: Store<AppState>) {}

  getError(): Observable<any> {
    return this.store.select(fromSelector.getError);
  }

  getDropdownResult(): Observable<boolean> {
    return this.store.select(fromSelector.getDropdownResult);
  }

  setDropdownResult(isView: boolean): void {
    return this.store.dispatch(fromAction.ShowDropdown({ view: isView }));
  }

  getColorChangeResult(): Observable<boolean> {
    return this.store.select(fromSelector.getColorChangeResult);
  }

  setColorChangeResult(change: boolean): void {
    return this.store.dispatch(fromAction.DropdownColorNotChange({ change: change }));
  }

  getRedirectStopResult(): Observable<boolean> {
    return this.store.select(fromSelector.getRedirectPermission);
  }

  setRedirectStop(change: boolean): void {
    return this.store.dispatch(fromAction.StopRedirectForDropDown({ change: change }));
  }

  setHomePageFlag(change: boolean): void {
    return this.store.dispatch(fromAction.HomePage({ change: change }));
  }

  getHomePageFlag(): Observable<boolean> {
    return this.store.select(fromSelector.getHomePageFlag);
  }

  getShareGeolocation(): Observable<boolean> {
    return this.store.select(fromSelector.getShareGeolocation);
  }

  setShareGeolocation(change: boolean): void {
    return this.store.dispatch(fromAction.ShareGeolocation({ change: change }));
  }

  getCurrentLocation(): Observable<{
    coords: { lon: number; lat: number };
    address: any;
  }> {
    return this.store.select(fromSelector.getCurrentLocation);
  }

  setCurrentLocation(change: { coords: { lon: number; lat: number }; address: any }): void {
    return this.store.dispatch(fromAction.CurrentLocation({ change: change }));
  }

  getInfoPopupShown(): Observable<boolean> {
    return this.store.select(fromSelector.getInfoPopupShown);
  }

  setInfoPopupShown(change: boolean): void {
    return this.store.dispatch(fromAction.InfoPopupShown({ change: change }));
  }

  getSelectMessagePost(): Observable<boolean> {
    return this.store.select(fromSelector.getSelectMessagePost);
  }

  setSelectMessagePost(change: boolean): void {
    return this.store.dispatch(fromAction.SelectMessagePost({ change: change }));
  }
}
