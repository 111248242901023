import { environment } from '@env/environment';

export interface Config {
  env: string;
  authentication: AuthConfig;
  services: ServicesConfig;
  googleMaps: GoogleMapsConfig;
  googleTagManager: GoogleTagManagerConfig;
  campaignId: string;
  courseId: string;
  moodleUrl: string;
  adminWebUrl: string;
  harbourSurveyUrl: string;
  stripe: StripeConfig;
  mapBox: MapBoxConfig;
  onWaterIo: OnWaterIo;
  maintenance: MaintenanceConfig;
  securboatAppLink: SecurboatAppLinkConfig;
}

export interface AuthConfig {
  clientId: string;
  domain: string;
  knownAuthorities: string;
  scopes: Array<string>;
  baseUrl: string;
  endSessionUrl: string;
  redirectUrl: string;
}

export interface ServicesConfig {
  booking: string;
  experience: string;
  course: string;
  identity: string;
  payment: string;
  policy: string;
  partner: string;
  classroom: string;
}

export interface GoogleMapsConfig {
  apiKey: string;
}

export interface GoogleTagManagerConfig {
  gtm: string;
  gtmAuth: string;
  gtmPreview: string;
}

export interface StripeConfig {
  public_key: string;
}

export interface MapBoxConfig {
  accessToken: string;
}

export interface OnWaterIo {
  url: string;
  accessToken: string;
}

export interface MaintenanceConfig {
    showBanner: boolean;
    isOnGoing: boolean;
}

export interface SecurboatAppLinkConfig {
  android: string;
  ios: string;
}

export const devConfig: Config = {
  env: environment.env,
  authentication: {
    clientId: environment.auth.clientId,
    domain: environment.auth.domain,
    knownAuthorities: environment.auth.knownAuthorities,
    scopes: environment.auth.scopes,
    baseUrl: environment.auth.baseUrl,
    endSessionUrl: environment.auth.endSessionUrl,
    redirectUrl: environment.auth.redirectUrl,
  },
  services: {
    booking: environment.services.booking,
    experience: environment.services.experience,
    course: environment.services.course,
    identity: environment.services.identity,
    payment: environment.services.payment,
    policy: environment.services.policy,
    partner: environment.services.partner,
    classroom: environment.services.classroom,
  },
  campaignId: environment.campaignId,
  courseId: environment.courseId,
  moodleUrl: environment.moodleUrl,
  adminWebUrl: environment.adminWebUrl,
  harbourSurveyUrl:environment.harbourSurveyUrl,
  googleMaps: {
    apiKey: environment.googleMaps.apiKey,
  },
  googleTagManager: {
    gtm: environment.googleTagManager.gtm,
    gtmAuth: environment.googleTagManager.gtmAuth,
    gtmPreview: environment.googleTagManager.gtmPreview,
  },
  stripe: {
    public_key: environment.stripe.public_key,
  },
  mapBox: {
    accessToken: environment.mapbox.accessToken,
  },
  onWaterIo: {
    url: environment.onWaterIo.url,
    accessToken: environment.onWaterIo.accessToken
  },
  maintenance: {
    showBanner: environment.maintenance.showBanner,
    isOnGoing: environment.maintenance.isOnGoing,
  },
  securboatAppLink: {
    android: environment.securboatAppLink.android,
    ios: environment.securboatAppLink.ios
  }
};
