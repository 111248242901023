import { Injectable } from '@angular/core';
import { CultureApiService } from './services/culture.api.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { CultureStoreService } from './services/culture.store.service';
import { Observable } from 'rxjs';
import * as fromAction from './culture.action';
import { Action } from '@ngrx/store';
import { map, switchMap } from 'rxjs/operators';
import { cultureFeatureKey as culture } from './culture.state';
import { EffectHelperService } from '@modules/core/services/effect-helper.service';

@Injectable()
export class CultureStoreEffects {
  constructor(
    private cultureApiService: CultureApiService,
    private actions$: Actions,
    private cultureStoreService: CultureStoreService,
    private effectHelperService: EffectHelperService
  ) {}

 
  fecthCultureEffect$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(fromAction.FetchCultures),
    switchMap((payload) =>
      this.cultureApiService
        .getAllCultures(payload.search, payload.pageIndex, payload.pageSize, payload.onlyNeutral, payload.locations)
        .pipe(
          map((res) => {
            this.cultureStoreService.addTotalCount(res.total);
            return this.effectHelperService.handleSuccess(fromAction.AddCultures({ cultures: res.items }))
          }),
          this.effectHelperService.handleError(fromAction.AddError, true, culture)
        )
    )
  ));


  getByIdEffect$: Observable<Action> =  createEffect(() => this.actions$.pipe(
    ofType(fromAction.GetCulture),
    switchMap((payload) =>
      this.cultureApiService.getById(payload.id).pipe(
        map((culture) => 
        this.effectHelperService.handleSuccess(fromAction.AddCulture({ culture }))),
        this.effectHelperService.handleError(fromAction.AddError, true, culture)
      )
    )
  ));
}
