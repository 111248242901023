import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges
} from '@angular/core';
import { Subject } from 'rxjs';
import { Router } from '@angular/router';
import { UserProfile } from '@modules/identity/models/user';
import { ROUTES_WITH_BACKGROUND_BANNER, ROUTES_WITHOUT_BACKGROUND_BANNER } from '../../contants/routes-with-banners';
import { HEADER_ANIMATIONS } from '../../contants/header-animations';

@Component({
  selector: 'toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss'],
  animations: HEADER_ANIMATIONS,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ToolbarComponent implements OnInit, OnDestroy {
  @Input() isMobile: boolean;
  @Input() isAuthenticated: boolean;
  @Input() user: UserProfile | null = null;
  @Input() scrolled: boolean;
  @Input() currentUrl: string;
  @Output() preventScrolling: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() signOut: EventEmitter<void> = new EventEmitter<void>();
  isActiveMenu: boolean = false;
  isShowBackground: boolean;
  toolbarTextColor: string = 'text-dark';
  private destroy$: Subject<any> = new Subject<any>();

  constructor(private router: Router) {
  }


  ngOnInit() {
    this.disableRouteReuseStrategy();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.scrolled?.currentValue !== undefined) {
      this.isShowBackground = changes.scrolled.currentValue || Boolean(this.currentUrl === '/kart');
      this.setToolbarColor();
    }
    if (changes?.currentUrl?.currentValue) {
      if (this.isActiveMenu) {
        this.toggleMenu();
      }
      this.setToolbarColor();
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public logout(): void {
    this.signOut.emit();
  }

  public toggleMenu() {
    this.isActiveMenu = !this.isActiveMenu;
    this.setToolbarColor();
    if(this.isActiveMenu && this.isMobile) {
      this.preventScrolling.emit(true);
    }
    if(!this.isActiveMenu && this.isMobile) {
      this.preventScrolling.emit(false);
    }
  }

  private setToolbarColor(): void {
    const hasBackgroundPage = this.pageWithBackground();
    this.toolbarTextColor = (!hasBackgroundPage || this.isActiveMenu || this.isShowBackground) ? 'text-dark' : 'text-white';
  }

  private pageWithBackground(): boolean {
    if(this.currentUrl === '/') {
      return true;
    }
    else {
      return ROUTES_WITH_BACKGROUND_BANNER.includes(this.router.parseUrl(this.currentUrl).root?.children?.primary?.segments?.slice(-2,-1).pop()?.path) && !ROUTES_WITHOUT_BACKGROUND_BANNER.includes(this.router.parseUrl(this.currentUrl).root?.children?.primary?.segments?.pop()?.path) && this.router.parseUrl(this.currentUrl).root?.children?.primary?.segments?.length <=3 ;
    }
  }

  private disableRouteReuseStrategy(): void {
    if (this.router.routeReuseStrategy)
      this.router.routeReuseStrategy.shouldReuseRoute = ()=> false;
  }
}
