import { NgModule } from '@angular/core';
import { MultipleDayPickerComponent } from './components/multiple-day-picker/multiple-day-picker.component';
import { CommonModule } from '@angular/common';
import { MultipleDayPickerDirective } from './directives/day-picker.directive';
import { MaterialModule } from '@shared/material/material';

@NgModule({
  imports: [CommonModule, MaterialModule],
  declarations: [MultipleDayPickerComponent, MultipleDayPickerDirective],
  exports: [MultipleDayPickerComponent, MultipleDayPickerDirective],
  entryComponents: [MultipleDayPickerComponent],
})
export class MultipleDayPickerModule {}
