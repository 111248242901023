import { Pipe, PipeTransform } from '@angular/core';
import { has } from 'lodash';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { Country } from '../models/country';
import { CountryStoreService } from '../store/country-store/services/country.store.service';

@Pipe({
  name: 'countryName'
})
export class CountryNamePipe implements PipeTransform {

  constructor(private countryStoreService: CountryStoreService) {
  }

  transform(countryCode: string, ...args: unknown[]): Observable<string> {
    return this.countryStoreService.getCountryByCode(countryCode).pipe(filter((country) => has(country,'englishName')),map((country: Country) => country.englishName));
  }

}
