import { Injectable } from '@angular/core';
import { TranslocoService } from '@ngneat/transloco';
@Injectable({
  providedIn: 'root'
})
export class AlertMessagesService {
  constructor(
    private translateService: TranslocoService) {
  }

  translateErrorMessage(translationKey: string, domain: string): string {
    const translatedMessage = this.translateService.translate(`alertmessages.response.error.${domain}.${translationKey}`, {}, this.translateService.getActiveLang());
    return translatedMessage === `alertmessages.response.error.${domain}.${translationKey}` ? null : translatedMessage
  }

  translateTitle(translationKey: string): string {
    return this.translateService.translate(`alertmessages.response.title.${translationKey}`);
  }

  translateSuccessMessage(translationKey: string, domain: string): string {
    const [translateMessageText, ...translateDynamicFields] = translationKey.split('|');
    const translateDynamicValues = translateDynamicFields.reduce((result, value, index) => ({ ...result, [`v${index}`]: value }), {});
    const translatedMessage = this.translateService.translate(`alertmessages.response.success.${domain}.${translateMessageText}`, translateDynamicValues , this.translateService.getActiveLang());
    return translatedMessage === `alertmessages.response.success.${domain}.${translationKey}` ? null : translatedMessage
  }

}
