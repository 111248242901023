import { createAction, props } from '@ngrx/store';
import { RsUserProfile, UserProfile } from '../models/user';

export enum IdentityAction {
  FetchUserProfile = '[Api] FETCH USER PROFILE',
  CreateUserProfile = '[Api/Identity] CREATE NEW USER PROFILE',
  UpdateUserProfile = '[Api/Identity] UPDATE USER PROFILE',
  SendVerificationOtp = '[Api/Identity] SEND OTP TO RS MEMBER MOBILE',
  RedeemCode = '[Api/Identity] REDEEM MEMBER OTP CODE',
  AddUserProfile = 'ADD USER PROFILE',
  AddError = 'ADD IDENTITY ERROR',
  AddTotal = 'ADD IDENTITY TOTAL COUNT',
  AddVerificationOtp = 'ADD VERIFICATION OTP',
  RemoveVerificationOtp = 'REMOVE VERIFICATION OTP',
  UpdateRedeemVerifyStatus = 'UPDATE REDEEM VERIFY STATUS',
  LastLoggedIn = 'UPDATE LAST LOGGED IN',
}

// Effects
export const LastLoggedIn = createAction(IdentityAction.LastLoggedIn);
export const FetchUserProfile = createAction(IdentityAction.FetchUserProfile);
export const CreateUserProfile = createAction(IdentityAction.CreateUserProfile, props<{ user: RsUserProfile }>());
export const UpdateUserProfile = createAction(
  IdentityAction.UpdateUserProfile,
  props<{ id: string; user: UserProfile }>()
);
export const SendVerificationOtp = createAction(
  IdentityAction.SendVerificationOtp,
  props<{ campaignId: string; phoneNo: string }>()
);
export const RedeemCode = createAction(IdentityAction.RedeemCode, props<{ otp: string; bookingId: string; courseId: string; userId: string }>());

export const AddUserProfile = createAction(IdentityAction.AddUserProfile, props<{ user: UserProfile }>());
export const AddError = createAction(IdentityAction.AddError, props<{ error: any }>());
export const AddTotalCount = createAction(IdentityAction.AddTotal, props<{ totalCount: number }>());
export const AddVerificationOtp = createAction(IdentityAction.AddVerificationOtp, props<{ sendOtp: boolean }>());
export const RemoveVerificationOtp = createAction(IdentityAction.RemoveVerificationOtp);
export const UpdateRedeemVerifyStatus = createAction(
  IdentityAction.UpdateRedeemVerifyStatus,
  props<{ isValidOtp: boolean }>()
);
