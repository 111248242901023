<div class="date-picker-wrapper">
  <div class="date-range-picker d-flex">
    <div class="left">
      <app-calendar
        #fromCalendar
        [(initial)]="firstCalendarInitial"
        [(from)]="fromDate"
        [(to)]="toDate"
        [selectDateRange]="true"
        [firstDaySelected]="false"
        (changeMonth)="changeFromMonth($event)"
      ></app-calendar>
    </div>
    <div class="right">
      <app-calendar
        #toCalendar
        [(initial)]="secondCalendarInitial"
        [(from)]="fromDate"
        [(to)]="toDate"
        [forCalendar]="fromCalendar"
        [selectDateRange]="true"
        (changeMonth)="changeToMonth($event)"
        [firstDaySelected]="false"
      ></app-calendar>
    </div>
  </div>
  <div class="filter-footer-wrapper d-flex justify-content-between">
    <button type="button" (click)="fromCalendar.clear();toCalendar.clear();clearAll()" class="rounded-0 btn btn-outline-success">
     {{ 'shared.action_buttons.clear' | transloco }}
    </button>
    <button type="button" (click)="saveDate()" class="rounded-0 btn btn-primary"> {{ 'shared.action_buttons.save' | transloco }} </button>
  </div>
</div>
