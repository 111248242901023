import { Injectable } from '@angular/core';
import { LocationApiService } from './services/location-api.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { LocationStoreService } from './services/location-store.service';
import { Observable } from 'rxjs';
import { Action } from '@ngrx/store';
import * as fromAction from './location.action';
import { map, switchMap } from 'rxjs/operators';
import { locationFeatureKey } from './location.state';
import { EffectHelperService } from '@modules/core/services/effect-helper.service';

@Injectable()
export class LocationEffects {
  fetchLocationEffect$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(fromAction.FetchLocations),
      switchMap((payload) =>
        this.locationApiService
          .getAllLocation(
            payload.search,
            payload.excludeEmpty,
            payload.pageIndex,
            payload.pageSize,
            payload.position,
            payload.country
          )
          .pipe(
            map((res) => {
              this.locationStore.addTotalCount(res.total);
              return this.effectHelperService.handleSuccess(fromAction.AddLocations({ locations: res.items }))
            }),
            this.effectHelperService.handleError(fromAction.AddError, true, locationFeatureKey)

          )
      )    
      )
  );

  getByIdEffect$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(fromAction.GetLocation),
      switchMap((payload) =>
        this.locationApiService
          .getByLocationId(payload.id)
          .pipe(
            map((location) => this.effectHelperService.handleSuccess(fromAction.AddLocation({ location }))),
            this.effectHelperService.handleError(fromAction.AddError, true, locationFeatureKey)
          )
      )
    )
  );

  constructor(
    private locationApiService: LocationApiService,
    private actions$: Actions,
    private locationStore: LocationStoreService,
    private effectHelperService: EffectHelperService

  ) { }
}
