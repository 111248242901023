import { Component, OnInit, ViewEncapsulation } from '@angular/core';
//import { AuthService } from 'src/app/modules/shared/services/auth/auth.service';

@Component({
  selector: 'app-forbidden-page',
  templateUrl: './forbidden-page.component.html',
  styleUrls: ['./forbidden-page.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ForbiddenPageComponent implements OnInit {
  constructor() // public authService: AuthService
  {}

  ngOnInit() {}
}
