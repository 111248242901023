import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PlatformService {
  constructor(@Inject(PLATFORM_ID) private platform: string) {}

  get isBrowser(): boolean {
    if (isPlatformBrowser(this.platform)) return true;
    return false;
  }
}
