import { Component, Inject, OnDestroy, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { Router } from '@angular/router';

@Component({
  selector: 'app-location-permission',
  templateUrl: './location-permission.component.html',
  styleUrls: ['./location-permission.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class LocationPermissionComponent implements OnDestroy {
  private destroy$: Subject<any>;

  constructor(
    public matDialogRef: MatDialogRef<LocationPermissionComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private router: Router
  ) {
    this.destroy$ = new Subject();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public redirectToMyPage() {
    this.matDialogRef.close(true);
    this.router.navigate(['/bruker/min-side']);
  }

  public close() {
    this.matDialogRef.close(false);
  }
}
