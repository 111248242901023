import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { tap, map, filter, take } from 'rxjs/operators';
import { IdentityStoreService } from '@modules/identity/store/services/identity-store.service';
import { UserProfile } from '@modules/identity/models/user';
import { AuthService } from '@core/services/auth.service';
import { NotificationService } from '@core/services/notification.service';
import { TranslocoService } from '@ngneat/transloco';

@Injectable()
export class UserExcludeResolver implements Resolve<UserProfile> {
  constructor(
    private identityStoreService: IdentityStoreService,
    private authService: AuthService,
    private notificationService: NotificationService,
    private router: Router,
    private translate: TranslocoService
  ) {
    translate.setTranslation(
      {
        'NO-ACCESS': 'Your access is denied. Please contact with administrator.',
      },
      'en'
    );
    translate.setTranslation(
      {
        'NO-ACCESS': 'Du har ikke tilgang. Vennligst ta kontakt med administratoren.',
      },
      'nb'
    );
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<UserProfile> {
    var exclude = route.data['excludeRole'] as string[];

    if (this.authService.isAuthenticated()) {
      return this.identityStoreService.getUserProfile().pipe(
        tap((user) => {
          if (!user) {
            this.identityStoreService.fetchUserProfile();
          }
        }),
        filter((user) => {
          if (user) {
            return true;
          }

          return false;
        }),
        take(1),
        map((user) => {
          if (user) {
            if (this.isInExclude(user.roles, exclude)) {
              this.translate
                .selectTranslate('NO-ACCESS')
                .subscribe((res: string) => this.notificationService.warning(res));
              this.router.navigate(['/']);
            }
            return user;
          }

          return null;
        })
      );
    } else {
      return of(null);
    }
  }

  private isInExclude(all: string[], exclude: string[]): boolean {
    return all.some((x) => exclude.some((f) => f === x));
  }
}
