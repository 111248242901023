import { NgModule } from '@angular/core';
import { CountryApiService } from './services/country.api.service';
import { CountryStoreService } from './services/country.store.service';
import { CountryStoreEffects } from './country.effects';
import { CountryResolver } from '../../resolvers/country.resolver';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { countryStoreReducer } from './country.reducer';
import { countryFeatureKey } from './country.state';

@NgModule({
  declarations: [],
  exports: [StoreModule, EffectsModule],
  imports: [StoreModule.forFeature(countryFeatureKey, countryStoreReducer), EffectsModule.forFeature([CountryStoreEffects])],
  providers: [CountryApiService, CountryResolver, CountryStoreService],
})
export class CountryStoreModule {}
