import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { tap, first } from 'rxjs/operators';
import { Culture } from '../models/culture';
import { CultureStoreService } from '../store/culture-store/services/culture.store.service';

@Injectable()
export class CultureResolver implements Resolve<Culture[]> {
  constructor(private cultureStoreService: CultureStoreService) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Culture[]> {
    return this.cultureStoreService.getAllCultures().pipe(
      tap((country) => {
        if (country.length === 0) {
          this.cultureStoreService.fetchAllCulture('', 0, 1000, true);
        }
      }),
      first()
    );
  }
}
