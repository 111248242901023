import { Component, OnInit, ViewEncapsulation, EventEmitter, Output, Input, OnChanges } from '@angular/core';
import * as moment from 'moment';


@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class CalendarComponent implements OnInit, OnChanges {

  public showMonth = [];

  // public selectedRow = null;
  // public selectedCol = null;

  public fromDate: any = null;
  public toDate: any = null;

  public startDate: any = null;


  @Output()
  public dateRange = new EventEmitter();

  @Output()
  public availableSlots = new EventEmitter();

  @Output()
  public selectedDay = new EventEmitter();

  @Output()
  public changeMonth = new EventEmitter();

  @Input()
  availableDates = [];

  @Input()
  public get initial() {
    return this.startDate;
  }

  @Output()
  public initialChange = new EventEmitter();

  public set initial(val) {
    this.startDate = val;
    this.initialChange.emit(this.startDate);
  }

  @Input()
  public selectedDate: any = null;

  @Input()
  public firstDaySelected: boolean = true;

  @Input()
  public multiple: boolean = false;

  @Input()
  public forCalendar: CalendarComponent = null;

  @Input()
  public get from() {
    return this.fromDate;
  }

  @Output()
  public fromChange = new EventEmitter();

  public set from(val) {
    this.fromDate = val;
    this.fromChange.emit(this.fromDate);
  }

  @Input()
  public get to() {
    return this.toDate;
  }

  @Output()
  public toChange = new EventEmitter();

  public set to(val) {
    this.toDate = val;
    this.toChange.emit(this.toDate);
  }

  @Input()
  public selectedDates: any[] = [];

  @Input()
  public selectDateRange: boolean = false;

  constructor() { }

  ngOnInit() {

    if (this.startDate) {
      this.initial = moment(this.startDate).startOf('month').format('YYYY-MM-DD');
    } else {
      this.initial = moment().startOf('month').format('YYYY-MM-DD');
    }

    const lastDate = moment(this.startDate).endOf('month').format('YYYY-MM-DD');;
    this.dateRange.emit({ from: this.startDate, to: lastDate });

    this.loadCalendar();
  }

  ngOnChanges() {
    this.loadCalendar();
  }

  private loadCalendar(): void {
    this.getMonthCalender(this.startDate);

    if (this.showMonth.length > 0) {
      if (moment(this.startDate).isSame(new Date(), 'month')) {
        const firstday = moment().format('YYYY-MM-DD');
        this.activeOnload(firstday);
      } else {
        const firstday = moment(this.startDate).format('YYYY-MM-DD');
        this.activeOnload(firstday);
      }
    }
  }

  activeOnload(firstday) {
    for (let i = 0; i < this.showMonth.length; i++) {
      for (let j = 0; j < 7; j++) {
        if (this.firstDaySelected) {
          if (this.showMonth[i][j] && this.showMonth[i][j].date === firstday) {
            this.showSlot(this.showMonth[i][j]);
          }
        }
      }
    }
  }

  public getMonthCalender(startDate) {
    this.showMonth = [];
    const endDate = moment(startDate).add(1, 'months');

    const weekStartdays = this.getWeekFirstDays(startDate, endDate);

    for (let i = 0; i < weekStartdays.length; i++) {
      this.showMonth.push(this.getWeekArray(weekStartdays[i], endDate));
    }
  }

  public getWeekFirstDays(startDate, endDate) {
    const weekMoment = [];
    weekMoment.push(moment(startDate).startOf('month'));

    let i = 0;
    let inRange = true;

    while (inRange) {
      const prev = weekMoment[i];
      const day = 7 - prev.day();
      const newDate = moment(prev).add(day, 'days');
      if (newDate.isBefore(endDate)) {
        weekMoment.push(newDate);

      } else {
        inRange = false;
      }
      i++;

    }

    for (const day in weekMoment) {
      if (day) {
        weekMoment[day] = weekMoment[day].format('YYYY-MM-DD');
      }
    }

    return weekMoment;
  }

  public getWeekArray(firstDay, endDate) {
    const weekday = moment(firstDay, 'YYYY-MM-DD').day();

    const weekarr = [null, null, null, null, null, null, null];
    let i = weekday;
    let count = 0;
    while (i < 7) {
      const eachday = moment(firstDay, 'YYYY-MM-DD').add(count, 'days');
      if (eachday.isBefore(endDate)) {
        let availableData = [];
        if (this.availableDates) {
          availableData = this.availableDates.filter(dateInfo => dateInfo['date'] === eachday.format('YYYY-MM-DD'));
        }

        weekarr[i] = {
          date: eachday.format('YYYY-MM-DD'),
          availability: availableData
        };
      }

      i++;
      count++;
    }

    return weekarr;
  }

  public nextMonth() {
    this.showMonth = [];
    this.availableDates = null;
    this.availableSlots.emit(null);

    this.initial = moment(this.startDate).add(1, 'months').format('YYYY-MM-DD');

    this.changeMonth.emit(this.startDate);

    const lastDate = moment(this.startDate).endOf('month').format('YYYY-MM-DD');
    this.dateRange.emit({ from: this.startDate, to: lastDate });

    this.loadCalendar();

  }

  public prevMonth() {
    if (moment(this.startDate).isAfter(moment().startOf('month'))) {
      this.showMonth = [];
      this.availableDates = null;
      this.availableSlots.emit(null);

      this.initial = moment(this.startDate).subtract(1, 'months').format('YYYY-MM-DD');

      this.changeMonth.emit(this.startDate);

      const lastDate = moment(this.startDate).endOf('month').format('YYYY-MM-DD');;
      this.dateRange.emit({ from: this.startDate, to: lastDate });

      this.loadCalendar();
    }
  }

  public showSlot(data) {
    if (data) {
      if (data.availability && data.availability.length > 0) {
        this.availableSlots.emit(data.availability[0].slots);
      }
      this.selectedDay.emit(data.date);
      this.selectedDate = data.date;

      if (this.selectDateRange) {
        if (this.forCalendar && this.forCalendar.fromDate) {
          this.to = data.date;
        } else {
          if (this.from) {
            if (moment(data.date).isSameOrBefore(this.fromDate)) {
              this.from = data.date;
            } else {
              this.to = data.date;
            }
          } else {
            this.from = data.date;
          }
        }
      }
    }
  }

  public isSelected(date): boolean {
    if (this.selectDateRange) {
      if (moment(date).isSame(this.fromDate)) {
        return true;
      } else if (moment(date).isAfter(this.fromDate) && moment(date).isBefore(this.toDate)) {
        return true;
      } else if (moment(date).isSame(this.toDate)) {
        return true;
      } else {
        return false;
      }
    } else {
      return moment(date).isSame(this.selectedDate);
    }
  }

  public isSelectionClass(date): string {
    if (this.selectDateRange) {
      if (moment(date).isSame(this.fromDate)) {
        if (this.toDate) {
          return 'active start-date';
        }

        return 'active';
      }

      if (moment(date).isAfter(this.fromDate) && moment(date).isBefore(this.toDate)) {
        return 'in-range';
      }

      if (moment(date).isSame(this.toDate)) {
        return 'in-range end-date';
      }

      return '';
    } else {
      if (moment(date).isSame(this.selectedDate)) {
        return 'active';
      }
      return '';
    }
  }

  public checkDay(day) {
    const today = moment().format('YYYY-MM-DD');

    if (today === day) {
      return 'today';
    } else if (moment(day).isBefore(moment(today))) {
      return 'off';
    } else {
      return '';
    }
  }

  public clear(): void {
    this.selectedDate = null;
    this.availableDates = null;
    this.availableSlots.emit(null);
    this.from = null;
    this.to = null;
  }
}
