import { Injectable } from '@angular/core';
import { Culture } from '../../../models/culture';
import { BaseApiService } from '@core/http/base.api.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ConfigService } from '@core/services/config.service';
import { Observable } from 'rxjs';
import { PageList } from '@core/models/page-list';

@Injectable()
export class CultureApiService extends BaseApiService {
  constructor(public http: HttpClient, private configService: ConfigService) {
    super(http);
  }

  public get BaseUrl(): string {
    const config = this.configService.getCurrent();
    return `${config.services.experience}/api/culture`;
  }

  public getAllCultures(
    search: string,
    pageIndex: number,
    pageSize: number,
    onlyNeutral: boolean = true,
    locations?: string[]
  ): Observable<PageList<Culture>> {
    const params = new HttpParams()
      .append('name', search)
      .append('onlyNeutral', `${onlyNeutral}`)
      .append('locations', locations ? locations.join(',') : '')
      .append('pageOffset', pageIndex.toString())
      .append('pageSize', pageSize.toString());

    return super.getAllWithPagination(`${this.BaseUrl}`, params);
  }

  public getById(code: string): Observable<Culture> {
    return super.get(`${this.BaseUrl}`, code);
  }

  public create(create: Partial<Culture>): Observable<Culture> {
    return super.register(this.BaseUrl, create);
  }

  public edit(id: string, change: Partial<Culture>): Observable<Culture> {
    return super.update(`${this.BaseUrl}/${id}`, change);
  }

  public remove(id: string): Observable<any> {
    return super.delete(`${this.BaseUrl}/${id}`);
  }
}
