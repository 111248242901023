import { Component, OnInit, ViewEncapsulation, Input, OnChanges } from '@angular/core';

import { Observable } from 'rxjs';
import { Multimedia } from 'src/app/modules/shared/models/multimedia';

import { map } from 'rxjs/operators';
import { CultureStoreService } from '@shared/store/culture-store/services/culture.store.service';
import { Instructor } from '@modules/instructor/models/instructor';
import { Country } from '@shared/models/country';
import { CountryStoreService } from '@shared/store/country-store/services/country.store.service';
import { Culture } from '@shared/models/culture';

@Component({
  selector: 'app-instructor-card',
  templateUrl: './instructor-card.component.html',
  styleUrls: ['./instructor-card.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class InstructorCardComponent implements OnInit, OnChanges {
  @Input()
  public instructor: Instructor = null;

  country$: Observable<Country>;

  langueges$: Observable<string>;
  constructor(private countryStoreService: CountryStoreService, private cultureStoreService: CultureStoreService) {}

  ngOnInit() {
    if (this.instructor) {
      this.country$ = this.countryStoreService.getCountryByCode(this.instructor.address.country);

      this.langueges$ = this.cultureStoreService
        .getCulturesBySearch((x) => this.instructor.languages.some((l) => l === x.twoLetterISOLanguageCode))
        .pipe(
          map((results) => {
            return results.map((x) => x.englishName).join(', ');
          })
        );
    }
  }

  ngOnChanges(): void {
    this.instructor = {
      ...this.instructor,
      description: this.instructor?.description.replace(/\n/g, '<br>')
    };
  }

  public getCountryName(countryCode: string): Observable<Country> {
    return this.countryStoreService.getCountryByCode(countryCode);
  }

  public getGuideDefaultMedia(mediaList: Multimedia[]): Multimedia {
    return mediaList.find((x) => x.default && x.category === 'profile');
  }

  public getCulture(twoLetterCode: string): Observable<Culture> {
    return this.cultureStoreService.getCultureByCode(twoLetterCode);
  }
}
