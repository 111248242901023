import { Location } from '@angular/common';
import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class BackNavigationService {
  public routeHistory: string[] = [];
  private unsubscribeAll$ = new Subject<void>();

  constructor(private location: Location, private router:Router) { }

  initRouterEvents() {
    this.getCurrentRoute();
  }

  goBack(param?: any): void {
    if(this.isRoutedFromMapPage() && !Object.values(param).includes(null)) {
      this.router.navigate(['/kart',param]);
    }
    else {
      this.location.historyGo(-1);
    }
  }

  unsubscribeHistoryObservables() {
    this.unsubscribeAll$.next();
    this.unsubscribeAll$.complete();
  }

  getCurrentRoute() {
    this.router.events
      .pipe(
        filter((navigationEvent): navigationEvent is NavigationEnd => navigationEvent instanceof NavigationEnd ),
        takeUntil(this.unsubscribeAll$)
        )
        .subscribe((navigationEvent) => {
          this.routeHistory = this.routeHistory.concat(navigationEvent.urlAfterRedirects).slice(-2);
       });
  }

  private isRoutedFromMapPage(): boolean {
    const [lastVisitedRoute] = this.routeHistory;
    return Boolean(lastVisitedRoute.includes('/kart'));
  }

}
