import { Injectable } from "@angular/core";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { PrivacyModelData } from "../../models/model-data";
import { ModalComponent } from "../modal/modal.component";

@Injectable()
export class PrivacyModalService {
  constructor(private dialog: MatDialog) {}

  public openTermsAndCondition(confirm?: string): MatDialogRef<ModalComponent> {
    const data: PrivacyModelData = {
      type: "terms",
      title: "Vilkår og betingelser",
      confirm: confirm,
    };

    return this.dialog.open(ModalComponent, {
      panelClass: "contact-form-dialog",
      data: data,
    });
  }

  public openInstructorTermsAndCondition(confirm?: string): MatDialogRef<ModalComponent> {
    const data: PrivacyModelData = {
      type: "instructor-terms",
      title: "Vilkår og betingelser",
      confirm: confirm,
    };

    return this.dialog.open(ModalComponent, {
      panelClass: "contact-form-dialog",
      data: data,
    });
  }

  public openPrivacyAndPolicy(): MatDialogRef<ModalComponent> {
    const data: PrivacyModelData = {
      type: "privacy",
      title: "Privacy and Policy",
    };

    return this.dialog.open(ModalComponent, {
      panelClass: "contact-form-dialog",
      data: data,
    });
  }
}
