import { Injectable, Predicate } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { Country } from '@shared/models/country';
import { CountryState } from '../country.state';
import * as fromActions from '../country.action';
import * as fromSelector from '../counrty.selector';

@Injectable()
export class CountryStoreService {
  constructor(private store: Store<CountryState>) {}

  public fetchAllCountry(search: string = '', pageIndex: number = 0, pageSize: number = 4): void {
    this.store.dispatch(
      fromActions.FetchCountries({
        search: search,
        pageIndex: pageIndex,
        pageSize: pageSize,
      })
    );
  }

  public getAllCountries(): Observable<Country[]> {
    return this.store.select(fromSelector.getAllCountries);
  }

  public getCountriesBySearch(predicate: Predicate<Country>): Observable<Country[]> {
    return this.store.select(fromSelector.getCountriesBySearch(predicate));
  }

  public getCountryByCode(twoLetterCode: string): Observable<Country> {
    return this.store.select(fromSelector.getCountryBySearch((c) => c.twoLetterISOCode === twoLetterCode));
  }

  public getCountryById(id: string): Observable<Country> {
    return this.store.select(fromSelector.getCountryById(id));
  }

  public addTotalCount(data: number): void {
    this.store.dispatch(fromActions.AddTotalCount({ totalCount: data }));
  }

  public getTotalCount(): Observable<number> {
    return this.store.select(fromSelector.getTotalCount);
  }

  public loadError(error: any): void {
    this.store.dispatch(fromActions.AddError({ error }));
  }

  public fetchCountryById(id: string): void {
    this.store.dispatch(fromActions.GetCountry({ id }));
  }

  public getCountriesByIds(ids: string[]): Observable<Country[]> {
    return this.store.select(fromSelector.getCountriesById(ids));
  }
}
