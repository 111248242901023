import { Component, Inject, OnDestroy, OnInit, PLATFORM_ID, Renderer2 } from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {
  EmergencyContactRequestService
} from "@modules/privacy-policy/components/services/emergency-contact-request.service";
import {EmergencyContactRequest, RequestStatus} from "@modules/privacy-policy/models/EmergencyContactRequest";
import {take} from "rxjs/operators";
import {EmergencyContactRequestStatus} from "@modules/privacy-policy/models/EmergencyContactRequestStatus";
import { isPlatformBrowser } from '@angular/common';

@Component({
    selector: 'app-emergency-contact-request',
    templateUrl: './emergency-contact-request.component.html',
    styleUrls: ['./emergency-contact-request.component.scss']
})
export class EmergencyContactRequestComponent implements OnInit, OnDestroy {
  token : string;
  apiResponse = false;
  requestStatus : EmergencyContactRequestStatus;
  faq1Expanded = false;
  faq2Expanded = false;
  requestBody : EmergencyContactRequest;

  constructor(
    @Inject(PLATFORM_ID) private platformId: any,
    private emergencyContactRequestService: EmergencyContactRequestService,
    private activatedRoute : ActivatedRoute,
    private router: Router,
    private renderer: Renderer2
  ) { }

  ngOnInit() {
    if (isPlatformBrowser(this.platformId)) {
      this.renderer.setStyle(document.body, 'background-color', '#F5F6FA');
    }

    this.token = this.activatedRoute.snapshot.queryParams.ectoken;

    if(this.token === null || this.token === undefined) {
      this.router.navigate(['not-found']);
    }
    else {
      this.emergencyContactRequestService.getEmergencyContactRequestStatus(this.token)
        .pipe(take(1))
        .subscribe((response) => {
          if(response != null) {
            this.requestStatus = response;
          }
          this.apiResponse = true;
        },
        () => {
          this.router.navigate(['error/server-error']);
        })


    }
  }

  ngOnDestroy() {
    if (isPlatformBrowser(this.platformId)) {
      this.renderer.setStyle(document.body, 'background-color', '#FFFFFF');
    }
  }

  acceptEmergencyContactRequest() {
    this.requestBody = {
      token : this.token,
      status : RequestStatus.APPROVED
    }

    this.updateEmergencyContactRequestStatus(this.requestBody);
  }

  rejectEmergencyContactRequest() {
    this.requestBody = {
      token : this.token,
      status : RequestStatus.REJECTED
    }

    this.updateEmergencyContactRequestStatus(this.requestBody);
  }

  updateEmergencyContactRequestStatus(body: EmergencyContactRequest){
    this.emergencyContactRequestService.updateEmergencyContactRequest(this.requestBody)
      .pipe(take(1))
      .subscribe((res) => {
        if(res === RequestStatus.APPROVED || res === RequestStatus.REJECTED )
          window.location.reload();
      },
      () => {
        this.router.navigate(['error/server-error']);
      }
    );
  }

  toggleFirstFAQ() {
    this.faq1Expanded = !this.faq1Expanded;
  }

  toggleSecondFAQ() {
    this.faq2Expanded = !this.faq2Expanded;
  }

  get windowRef() {
    return window;
  }

  isApproved(): boolean {
    return this.requestStatus?.status === RequestStatus.APPROVED;
  }

  isRejected(): boolean {
    return this.requestStatus?.status === RequestStatus.REJECTED;
  }
}
