import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ModuleWithProviders, NgModule, Optional, SkipSelf } from '@angular/core';
import { getGoogleTagManagerId } from '@core/factories/gtm.factory';
import { ErrorInterceptor } from './interceptors/error.interceptors';
import { FlatpickrModule } from 'angularx-flatpickr';
import { ToastrModule } from 'ngx-toastr';
import { ConfigService } from './services/config.service';
import { AppStoreModule } from './store/app-store.module';
import { AuthInterceptor, AuthModule, StsConfigLoader } from "angular-auth-oidc-client";
import { OidcRedirectComponent } from './components/oidc-redirect/oidc-redirect.component';
import { authFactory } from "@core/factories/auth.factory";
import { FilterBottomSheetComponent } from './components/filter-bottom-sheet/filter-bottom-sheet.component';
import { TranslocoModule } from '@ngneat/transloco';
import { SharedModule } from '@shared/shared.module';

@NgModule({
  declarations: [
    OidcRedirectComponent,
    FilterBottomSheetComponent
  ],
  imports: [
    CommonModule,
    AppStoreModule,
    AuthModule.forRoot({
      loader: {
        provide: StsConfigLoader,
        useFactory: authFactory,
        deps: [ConfigService],
      },

    }),
    ToastrModule.forRoot({
      positionClass: 'toast-top-center',
    }),
    FlatpickrModule.forRoot(),
    TranslocoModule,
    SharedModule,
  ],
  exports: [AppStoreModule,AuthModule, OidcRedirectComponent],
  providers: [
  ],
})
export class CoreModule {
  constructor(@Optional() @SkipSelf() coreModule?: CoreModule) {
    if (coreModule) {
      throw new Error(
        'CoreModule is already loaded. Import it in the AppModule only');
    }
  }

  static forRoot(): ModuleWithProviders<CoreModule> {
    return {
      ngModule: CoreModule,
      providers: [
        { provide: HTTP_INTERCEPTORS,
          useClass: AuthInterceptor,
          multi: true
        },
        {
          provide: HTTP_INTERCEPTORS,
          useClass: ErrorInterceptor,
         // deps: [AlertMessagesService],
          multi: true,
        },
        {
          provide: 'googleTagManagerId',
          useFactory: getGoogleTagManagerId,
          deps: [ConfigService],
        }
      ]
    }
  }
}
