<div id="error-403" class="d-flex justify-content-center">

  <div class="content d-flex justify-content-center flex-column align-items-center">

    <img class="logo" src="assets/icons/logo.png" alt="">
    <div class="error-code">403</div>

    <div class="message">{{ 'layout.pages.error.forbidden' | transloco}}</div>

    <!-- <div class="search" fxLayout="row" fxLayoutAlign="start center">
          <mat-icon class="icon s-24">search</mat-icon>
          <input placeholder="Search for something" fxFlex>
      </div> -->

    <!--<a class="back-link" (click)="authService.logout()">Login with another user</a>-->

  </div>

</div>
