import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AppStoreService } from '@core/store/app-store.service';
import { AuthService } from '@core/services/auth.service';
import { MapboxService } from '../services/mapbox.service';
import { PlatformService } from "@core/services/platform.service";

@Injectable()
export class UserLocationPermissionResolver implements Resolve<boolean> {
  constructor(
    private shareStoreService: AppStoreService,
    private authService: AuthService,
    private mapboxService: MapboxService,
    private platformService: PlatformService
  ) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (this.platformService.isBrowser && this.authService.isAuthenticated()) {
      this.mapboxService.handlePermission();
    } else {
      this.shareStoreService.setShareGeolocation(false);
    }

    return true;
  }
}
