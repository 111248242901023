export class DialogOptions {
  public titleTranslate: string;
  public messageTranslate: string;
  public okButtonTextTranslate: string;
  public cancelButtonTextTranslate: string;
  public icon: string;
}

export class DefaultOption extends DialogOptions {
  constructor() {
    super();
    this.titleTranslate = 'Confirmation!';
    this.messageTranslate = 'Are you sure to do this';
    this.okButtonTextTranslate = 'Ok';
    this.cancelButtonTextTranslate = 'Cancel';
    this.icon = 'warning';
  }
}
