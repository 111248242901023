import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CalendarComponent } from './components/calendar/calendar.component';
import { ClassroomCalendarComponent } from './components/classroom-calendar/classroom-calendar.component';


@NgModule({
  declarations: [
    CalendarComponent,
    ClassroomCalendarComponent,
  ],
  imports: [
    CommonModule
  ],
  exports: [
    CalendarComponent,
    ClassroomCalendarComponent,
  ],
  providers: [
  ]
})
export class CalendarModule { }
