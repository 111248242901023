import { isPlatformBrowser } from "@angular/common";
import { Component, Inject, OnInit, PLATFORM_ID, ViewEncapsulation } from "@angular/core";
import { ConfigService } from "@modules/core/services/config.service";


@Component({
  selector: 'securboat-app-link-page',
  templateUrl: './securboat-app-link.component.html',
  encapsulation: ViewEncapsulation.None
})
export class SecurboatAppLinkComponent implements OnInit {

  constructor( @Inject(PLATFORM_ID) private platformId, private configService: ConfigService ) { }

  ngOnInit() {
    const isAndroid = /android/i.test(navigator.userAgent);
    const isiOS = /(iPad|iPhone|iPod)/i.test(navigator.userAgent);

    if(isPlatformBrowser(this.platformId)) {

      if (isAndroid) {
        // Redirect to the Play Store
        window.location.href = this.configService.getCurrent().securboatAppLink.android;
      } else if (isiOS) {
        // Redirect to the App Store
        window.location.href = this.configService.getCurrent().securboatAppLink.ios;
      } else {
        window.location.href = this.configService.getCurrent().authentication.baseUrl;
      }
    }
  }

}
