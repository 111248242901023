import { CultureState } from './culture.state';
import * as fromAction from './culture.action';
import { adapter } from './culture.entity';
import { Action, createReducer, on } from '@ngrx/store';

export const initialState: CultureState = adapter.getInitialState({
  error: null,
  totalCount: 0,
});

const reducer = createReducer(
  initialState,
  on(fromAction.AddCulture, (state, { culture }) => {
    return adapter.addOne(culture, state);
  }),

  on(fromAction.AddCultures, (state, { cultures }) => {
    return adapter.setAll(cultures, state);
  }),

  on(fromAction.AddTotalCount, (state, { totalCount }) => {
    return adapter.addMany([], { ...state, totalCount: totalCount });
  }),

  on(fromAction.AddError, (state, { error }) => {
    return adapter.addMany([], { ...state, error: error });
  })
);

export function cultureStoreReducer(state: CultureState = initialState, action: Action): CultureState {
  return reducer(state, action);
}
