import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { first, tap } from 'rxjs/operators';
import { Location } from '../../models/location';
import { LocationStoreService } from './location-store.service';

@Injectable()
export class LocationListResolver implements Resolve<any> {
  constructor(private locationStoreService: LocationStoreService) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Location[]> {
    return this.locationStoreService.getAllLocations().pipe(
      tap((locations) => {
        if (locations.length === 0) {
          this.locationStoreService.fetchAllLocation('', 0, 1000, true, '');
        }
      }),
      first()
    );
  }
}
