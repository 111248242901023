import { Injectable } from '@angular/core';
import * as moment from 'moment';

@Injectable()
export class DurationService {
    constructor() {}

    public getDurationFromTime(timeFormat: string): string {
        const time = timeFormat.split(':');
        const duration = (Number(time[0]) * 3600) + ( Number(time[1]) * 60 ); 
        return moment.duration(duration, 'seconds').toISOString();
    }

    public getMinuteDurationFromTime(timeFormat: string): number {
        const time = timeFormat.split(':');
        return (Number(time[0]) * 60) + ( Number(time[1]));
    }

    public getTimeFromDuation(duration: string): string {
        const x = moment.duration(duration);
        return x.hours() + ':' + x.minutes();
    }

    public getTimeFromMinuteDuation(duration: number): string {
        return moment.utc().startOf('day').add({ minutes: duration }).format('H:mm');
    }

    public getHourDurationWithPadding(duration: number, pad: number = 2): string {
        return this.padding(moment.utc().startOf('day').add({ minutes: duration }).format('H'));
    }

    public getMiniuteDurationWithPadding(duration: number, pad: number = 2): string {
        return this.padding(moment.utc().startOf('day').add({ minutes: duration }).format('mm'));
    }

    public padding(value: any, padString: string = '0', pad: number = 2): string {
        while (value.length < pad) {
            value = padString + value;
        }

        return value;
    }

    public getDetailsTimeFromDuration(duration: string): string {
        try
        {
            const x = moment.duration(duration);
            const minute = x.minutes() > 0 ? x.minutes() + 'm' : '';
            return x.hours() + 'h ' + minute;
        }catch {
            return '';
        }      
    }
}
