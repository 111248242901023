import { Action, createReducer, on } from '@ngrx/store';

import { IdentityState } from './identity.state';
import * as fromAction from './identity.action';

export const initialState: IdentityState = {
  error: null,
  totalCount: 0,
  userProfile: null,
  sendOtp: false,
  isValidOtp: false,
};

const reducer = createReducer(
  initialState,
  on(fromAction.AddUserProfile, (state, { user }) => {
    return { ...state, userProfile: user };
  }),
  on(fromAction.AddVerificationOtp, (state, { sendOtp }) => {
    return { ...state, sendOtp };
  }),
  on(fromAction.RemoveVerificationOtp, (state) => {
    return { ...state, sendOtp: false };
  }),
  on(fromAction.UpdateRedeemVerifyStatus, (state, { isValidOtp }) => {
    return { ...state, isValidOtp };
  })
);

export function identityStoreReducer(state: IdentityState = initialState, action: Action): IdentityState {
  return reducer(state, action);
}
