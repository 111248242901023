import { Action, createReducer, on } from '@ngrx/store';

import { LocationState } from './location.state';
import * as fromAction from './location.action';
import { locationEntityAdapter } from './location.entity';

export const initialState: LocationState = locationEntityAdapter.getInitialState({
  error: null,
  totalCount: 0,
  selected: null,
});

const reducer = createReducer(
  initialState,
  on(fromAction.AddLocations, (state, { locations }) => {
    return locationEntityAdapter.setAll(locations, state);
  }),
  on(fromAction.AddLocation, (state, { location }) => {
    return locationEntityAdapter.addOne(location, state);
  }),

  on(fromAction.AddSelectedLocation, (state, { location }) => {
    return locationEntityAdapter.addMany([], { ...state, selected: location });
  }),

  on(fromAction.RemoveSelectedLocation, (state) => {
    return locationEntityAdapter.addMany([], { ...state, selected: null });
  }),

  on(fromAction.AddTotalCount, (state, { totalCount }) => {
    return locationEntityAdapter.addMany([], { ...state, totalCount: totalCount });
  }),

  on(fromAction.AddError, (state, { error }) => {
    return locationEntityAdapter.addMany([], { ...state, error: error });
  })
);

export function locationStoreReducer(state: LocationState = initialState, action: Action): LocationState {
  return reducer(state, action);
}
