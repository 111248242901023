import { Injectable } from "@angular/core";
import { NotificationService } from "./notification.service";
import { MonoTypeOperatorFunction, Observable, of } from "rxjs";
import { Action } from "@ngrx/store";
import { catchError } from "rxjs/operators";
import { HttpErrorResponse } from "@angular/common/http";
import { ToastrPositionClassType } from "@modules/shared/enums/toastr-position-class-type";

@Injectable({
    providedIn: 'root'
})
export class EffectHelperService {
    constructor(private notificationService: NotificationService) { }


    handleError(action, showAlert: boolean = true, domain?: string, message?: string, positionClass: ToastrPositionClassType = ToastrPositionClassType.TOP_CENTER, timeOut: number = 10000): MonoTypeOperatorFunction<Action> {
        return catchError((error: HttpErrorResponse): Observable<Action> => {
            if (showAlert) {
                if (message?.length > 0)
                    this.notificationService.error(message, domain, positionClass, timeOut);
                else this.notificationService.serverError(error, domain, positionClass, timeOut)
            }
            return of(action(error));
        })
    }

    handleSuccess(action: Action, showAlert: boolean = false, messsage?: string, domain?: string, title: string = 'success', positionClass: ToastrPositionClassType = ToastrPositionClassType.TOP_CENTER, timeOut: number = 10000): Action {
        if (showAlert) this.notificationService.success(messsage, domain, title, positionClass, timeOut);
        return action;
    }
}