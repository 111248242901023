   <div class="instructor-card">
      <div class="header-title">
        <h4 class="h4">{{'shared.titles.about_instructor' | transloco}}</h4>
      </div>
      <div class="profile-section">
        <div class="verified" *ngIf="instructor.status === 'verified'">
          <img class="verified-icon" alt="verified">
          <span>{{'shared.verified_by_redningsselskap' | transloco}}</span>
        </div>
        <div class="row d-flex align-items-center">
          <div class="col-3 col-sm-3 col-md-2 col-lg-3 col-xl-2">
            <div class="profile-thum" ngImagePixel #instructorImage="imagePixel">
              <img *ngIf="instructor.medias.length <= 0" class="profile-image-circle" src="assets/images/user.png"
                alt="">
              <img *ngIf="instructor.medias.length > 0" class="img-fluid h-100 w-100" src="{{getGuideDefaultMedia(instructor.medias)?.url}}?{{instructorImage.getImagePixel()}}&mask=ellipse&fm=png&auto=format&fit=crop&ixlib=js-2.0.0&s=7d959b35672fa88a8838a963507e9dcd" alt="">
            </div>
          </div>
          <div class="col-9 col-sm-9 col-md-10 col-lg-9 col-xl-10">
            <div class="profile-location">
              <h6 class="h6">{{instructor.firstName}}</h6>
            </div>
            <div class="d-inline-flex mx-0 align-items-center">
              <ngx-show-rating
                *ngIf="instructor.rating > 0"
                class="d-block"
                color="#013240"
                [rate]="instructor.rating ?? 0"
                [max]="5"
              ></ngx-show-rating>
              <div [ngClass]="(instructor?.rating > 0) ? 'px-2' : 'px-0'"> {{ instructor?.reviewCount }} <span *ngIf="instructor?.reviewCount !==1">{{'shared.reviews' | transloco}}</span><span *ngIf="instructor?.reviewCount === 1">{{'shared.review' | transloco}}</span></div>
            </div>
          </div>
        </div>
      </div>

      <!-- <div *ngIf="instructor.description.length > 254; else instructorAbout">
        <p class="paragraph expand-less">
          {{ instructor.description }}
        </p>
      </div>
      <ng-template #instructorAbout>
        <p class="paragraph">
          {{ instructor.description }}
        </p>
      </ng-template> -->
      <p class="paragraph" [innerHTML]="instructor?.description"></p>
      <button class="see-profile" [routerLink]="['/instruktor/profil', instructor.id]">
        {{'shared.action_buttons.see_my_profile' | transloco}} <i class="fas fa-arrow-right"></i
      ></button>
    </div>
