import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ConfigService } from '@core/services/config.service';
import { PlatformService } from '@core/services/platform.service';
import { AppStoreService } from '@core/store/app-store.service';
import { LocationPermissionComponent } from '@shared/components/location-permission/location-permission.component';
import { CurrentPosition, Feature } from '@shared/models/mapbox';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { MapboxDialogComponent } from '../components/mapbox-dialog/mapbox-dialog.component';
import { IMarker } from '../models/marker';

@Injectable()
export class MapboxService {
  public currentPosition: CurrentPosition = null;
  public userId: string = null;

  public currentLocation: {
    coords: { lon: number; lat: number };
    address: any;
  } = null;

  public permission: any = null; // 'granted', 'prompt' or 'denied'

  constructor(
    private http: HttpClient,
    private matDialog: MatDialog,
    private shareStoreService: AppStoreService,
    private config: ConfigService,
    private platform: PlatformService
  ) { }

  public setCurrentPosition(): void {
    if(this.platform.isBrowser) {
      if(navigator){
        navigator.geolocation.getCurrentPosition((position) => {
          if (position) {
            this.currentPosition =  {
              lon: position.coords.longitude,
              lat: position.coords.latitude,
              address: ''
            };
          } else {
            this.currentPosition = { lon: 10.7522, lat: 59.9139, address: '' };
          }

          this.shareStoreService.setCurrentLocation({
            coords: { lon: this.currentPosition.lon, lat: this.currentPosition.lat },
            address: null,
          });
        });
      }
    }
  }

  public getCurrentPositionString() {
    if (this.currentPosition) return String(this.currentPosition.lat) + ',' + String(this.currentPosition.lon);
    else return null;
  }

  public handlePermission() {
    if(this.platform.isBrowser) {
      if (navigator && (navigator as any).permissions)
      (navigator as any).permissions.query({ name: 'geolocation' }).then((result: any) => {
        this.permission = result.state;

        if (result.state == 'granted') {
          this.fetchCurrentLocation();
        } else if (result.state == 'prompt') {
          this.matDialog.open(LocationPermissionComponent, {
            data: { isPermissionDenied: false },
            hasBackdrop: false,
            panelClass: 'location-permission-modal',
          });
        } else if (result.state == 'denied') {
          this.matDialog.open(LocationPermissionComponent, {
            data: { isPermissionDenied: true },
            hasBackdrop: false,
            panelClass: 'location-permission-modal',
          });
        }

        result.onchange = () => {
          if (result.state == 'granted') {
            this.fetchCurrentLocation();
          }
        };
      });
      // browser not compatible
      else {
        this.fetchCurrentLocation();
      }
    }
  }

  public fetchCurrentLocation(): void {
    if(this.platform.isBrowser && navigator) {
      navigator.geolocation.getCurrentPosition((position: any) => {
        if (position && position.coords) {
          let formattedPosition = {
            lat: position.coords.latitude,
            lon: position.coords.longitude,
          };

          this.currentPosition = formattedPosition;
          this.shareStoreService.setCurrentLocation({
            coords: formattedPosition,
            address: null,
          });
        }
      });
    }
  }

  public getCurrentAddress<Object>(lat: number, long: number): Observable<Object> {
    const resp = this.http.get<Object>(
      `https://api.mapbox.com/geocoding/v5/mapbox.places/${long},${lat}.json?` +
        `&access_token=` +
        this.config.getCurrent().mapBox.accessToken
    );
    resp.subscribe((resp: any) => {
      if (resp.features[0].place_name) {
        this.currentPosition = {
          ...this.currentPosition,
          address: resp.features[0].place_name,
        };
      }
      return this.currentPosition.address;
    });

    return resp;
  }

  public search_word(query: string, autocomplete: boolean = true) {
    const autoCompleteString = autocomplete ? 'autocomplete=true' : '';
    const currentPositionString = this.currentPosition
      ? 'proximity=' + this.currentPosition.lon + ',' + this.currentPosition.lat + '&'
      : '';
    const url = 'https://api.mapbox.com/geocoding/v5/mapbox.places/';
    return this.http
      .get(
        url +
          query +
          '.json?' +
          currentPositionString +
          autoCompleteString +
          'fuzzyMatch=true' +
          '&access_token=' +
          this.config.getCurrent().mapBox.accessToken
      )
      .pipe(
        map((res: any) => {
          return res.features;
        })
      );
  }

  public formatAddress(location: Feature) {
    let address = '';
    let postalcode = '';
    let city = '';
    let country = '';
    // check the Feature object
    if (location.id.search('poi') >= 0) address = location.properties.address;
    if (location.id.search('address') >= 0) address = location.text + ' ' + location.address;
    if (location.id.search('postcode') >= 0) postalcode = location.text;
    if (location.id.search('place') >= 0) city = location.text;
    if (location.id.search('country') >= 0) country = location.text;
    // chech the context
    for (let i = 0; i < location.context.length; i++) {
      if (location.context[i].id.search('poi') >= 0) address = location.context[i].properties.address;
      if (location.context[i].id.search('address') >= 0)
        address = location.context[i].text + ' ' + location.context[i].address;
      if (location.context[i].id.search('postcode') >= 0) postalcode = location.context[i].text;
      if (location.context[i].id.search('place') >= 0) city = location.context[i].text;
      if (location.context[i].id.search('country') >= 0) country = location.context[i].text;
    }
    return {
      streetAddress: address,
      region: '',
      postalCode: postalcode,
      city: city,
      country: country,
      comment: '',
    };
  }

  public mapboxDirection(
    routingProfile: string,
    coordinates: string
  ): Observable<{ distance: number; duration: number }> {
    return this.http
      .get(
        `https://api.mapbox.com/directions/v5/mapbox/${routingProfile}/${coordinates}?geometries=geojson&access_token=${
          this.config.getCurrent().mapBox.accessToken
        }`
      )
      .pipe(
        map((res: any) => {
          if (res.code === 'Ok') {
            return { distance: res.routes[0].distance, duration: res.routes[0].duration };
          } else {
            return { distance: null, duration: null };
          }
        })
      );
  }

  public openMapBoxDialog(marker: IMarker): MatDialogRef<MapboxDialogComponent> {
      return this.matDialog.open(MapboxDialogComponent,{
        panelClass: 'contact-form-dialog',
        data: {
          markers: marker ? [marker] : [],
          isDragable: true,
          type: 'position',
        }
      });
  }
}
