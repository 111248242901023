<div class="dialog-component">
  <h1 mat-dialog-title>{{data.titleTranslate}}</h1>
  <div mat-dialog-content>
    <div fxLayout="row" fxLayoutAlign="start center">
      <mat-icon>{{data.icon}}</mat-icon>
      <p class="ml-3 mb-0" [innerHTML]="data.messageTranslate"></p>
    </div>
  </div>

<!-- <div fxLayout="row" mat-dialog-actions>
  <span fxFlex></span>
  <button mat-button (click)="onNoClick()">{{data.cancelButtonTextTranslate}}</button>
  <button mat-button class="btn-ok" [mat-dialog-close]="true" cdkFocusInitial>{{data.okButtonTextTranslate}}</button>
</div> -->

  <div mat-dialog-actions>
    <div class="modal-footer">
      <button type="button" (click)="onNoClick()" class="btn btn-primary mr-3">
        {{data.cancelButtonTextTranslate}}
      </button>
      <button type="button" class="btn btn-primary" [mat-dialog-close]="true" cdkFocusInitial>
        {{data.okButtonTextTranslate}}
      </button>
    </div>
  </div>
</div>