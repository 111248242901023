<div class="drop-calender">
  <div class="calendar-table">
    <div class="month-header month-header d-flex justify-content-center align-items-center">
      <div class="prev" (click)="prevMonth()">
        <span class="icon-down-arrow2"></span>
      </div>
      <div colspan="5" class="month">{{startDate | date:'MMMM yyyy '}}</div>
      <div class="next" (click)="nextMonth()"><span class="icon-down-arrow2"></span></div>
    </div>
    <table class="table-condensed">
      <thead>
        <tr class="day-header">
          <th>S</th>
          <th>M</th>
          <th>T</th>
          <th>W</th>
          <th>T</th>
          <th>F</th>
          <th>S</th>
        </tr>
      </thead>
      <tbody>
        <!--
        <tr>
          <td class="weekend off available" data-title="r0c0"></td>
          <td class="off available" data-title="r0c1"></td>
          <td class="off available" data-title="r0c2"></td>
          <td class="off available" data-title="r0c3"></td>
          <td class="off available" data-title="r0c4">1</td>
          <td class="off available" data-title="r0c5">2</td>
          <td class="off available" data-title="r0c6">3</td>
        </tr>
        <tr>
          <td class="weekend off available" data-title="r1c0">4</td>
          <td class="off available" data-title="r1c1">5</td>
          <td class="off available" data-title="r1c2">6</td>
          <td class="off available" data-title="r1c3">7</td>
          <td class="off available" data-title="r1c4">8</td>
          <td class="off available" data-title="r1c5">9</td>
          <td class="weekend off available" data-title="r1c6">10</td>
        </tr>
        <tr>
          <td class="weekend off available" data-title="r2c0">11</td>
          <td class="off available" data-title="r2c1">12</td>
          <td class="active start-date available" data-title="r2c2">13</td>
          <td class="in-range available" data-title="r2c3">14</td>
          <td class="in-range available" data-title="r2c4">15</td>
          <td class="in-range available" data-title="r2c5">16</td>
          <td class="weekend in-range available" data-title="r2c6">17</td>
        </tr>
        <tr>
          <td class="in-range available" data-title="r3c0">18</td>
          <td class="active end-date in-range available" data-title="r3c1">
            19
          </td>
          <td class="block" data-title="r3c2">20</td>
          <td class="block" data-title="r3c3">21</td>
          <td class="block" data-title="r3c4">22</td>
          <td class="block" data-title="r3c5">23</td>
          <td class="weekend available" data-title="r3c6">24</td>
        </tr>
        <tr>
          <td class="weekend available" data-title="r4c0">25</td>
          <td class=" active available" data-title="r4c1">26</td>
          <td class="available" data-title="r4c2">27</td>
          <td class="pending active" data-title="r4c3">28</td>
          <td class="available" data-title="r4c4">29</td>
          <td class="available" data-title="r4c5">30</td>
          <td class="weekend off available" data-title="r4c6"></td>
        </tr>
      -->

        <tr *ngFor="let row of showMonth; let i=index">
          <td [ngClass]="[(day?.availability[0]?.slots.length>0)?'available':'',
              day?.availability[0]?.blocked? 'block':'',
              isSelectionClass(day?.date),
            checkDay(day?.date)]" *ngFor="let day of row; let j=index" (click)="showSlot(day)">
            {{day?.date | date:'d'}}

          </td>
        </tr>



      </tbody>
    </table>
  </div>
</div>