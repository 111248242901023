import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { LocationState } from '../location.state';
import { Observable } from 'rxjs';

import { Location } from '@modules/location/models/location';
import * as fromAction from '../location.action';
import * as fromSelector from '../location.selector';

@Injectable()
export class LocationStoreService {
  constructor(private store: Store<LocationState>) {}

  public fetchAllLocation(
    search: string = '',
    pageIndex: number = 0,
    pageSize: number = 4,
    excludeEmpty: boolean = false,
    position: string = '',
    country: string = ''
  ): void {
    this.store.dispatch(
      fromAction.FetchLocations({
        search: search,
        pageIndex: pageIndex,
        pageSize: pageSize,
        excludeEmpty: excludeEmpty,
        position: position,
        country: country,
      })
    );
  }

  public getAllLocations(): Observable<Location[]> {
    return this.store.pipe(select(fromSelector.getAllLocations));
  }

  public getAllLocationsBySearch(search: string): Observable<Location[]> {
    return this.store.pipe(select(fromSelector.getLocationsBySearch(search)));
  }

  public getLocationById(id: string): Observable<Location | null> {
    return this.store.select(fromSelector.getLocationById(id));
  }

  public addTotalCount(data: number): void {
    this.store.dispatch(fromAction.AddTotalCount({ totalCount: data }));
  }

  public getTotalCount(): Observable<number> {
    return this.store.select(fromSelector.getTotalCount);
  }

  public loadError(error: any): void {
    this.store.dispatch(fromAction.AddError(error));
  }

  public setSelectedLocation(location: Location): void {
    this.store.dispatch(fromAction.AddSelectedLocation({ location: location }));
  }

  public getSelectedLocation(): Observable<Location | null> {
    return this.store.select(fromSelector.getSelectedLocation);
  }

  public removeSelectedLocation(): void {
    this.store.dispatch(fromAction.RemoveSelectedLocation());
  }

  public fetchLocationById(id: string): void {
    this.store.dispatch(fromAction.GetLocation({ id: id }));
  }
}
