import { Injectable } from '@angular/core';
import { CountryApiService } from './services/country.api.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { CountryStoreService } from './services/country.store.service';
import { Observable } from 'rxjs';
import * as fromAction from './country.action';
import { Action } from '@ngrx/store';
import { map, switchMap } from 'rxjs/operators';
import { countryFeatureKey as country } from './country.state';
import { EffectHelperService } from '@modules/core/services/effect-helper.service';

@Injectable()
export class CountryStoreEffects {
  constructor(
    private countryApiService: CountryApiService,
    private actions$: Actions,
    private countryStoreService: CountryStoreService,
    private effectHelperService: EffectHelperService


  ) {}

 
  featchCountryEffect$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(fromAction.FetchCountries),
    switchMap((payload) =>
      this.countryApiService
        .getAllData(payload.search, payload.pageIndex, payload.pageSize)
        .pipe(
          map((res) => {
            this.countryStoreService.addTotalCount(res.total);
            return this.effectHelperService.handleSuccess(fromAction.AddCountries({ countries: res.items }))
          }),
        this.effectHelperService.handleError(fromAction.AddError, true, country))
    )
  ));

 
  getByIdEffect$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(fromAction.GetCountry),
    switchMap((payload) =>
      this.countryApiService.getById(payload.id).pipe(
        map((country) => this.effectHelperService.handleSuccess(fromAction.AddCountry({ country }))),
        this.effectHelperService.handleError(fromAction.AddError, true, country)
      )
    )
  ));
}
