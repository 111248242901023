import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ImageViewerComponent } from './components/image-viewer/image-viewer.component';
import { ImagePreviewService } from './services/image.preview.service';
import { ImageViewerModule } from 'ngx-image-viewer';
import { MaterialModule } from '@shared/material/material';

@NgModule({
  imports: [CommonModule, MaterialModule, ImageViewerModule.forRoot()],
  declarations: [ImageViewerComponent],
  providers: [ImagePreviewService],
  exports: [],
  entryComponents: [ImageViewerComponent],
})
export class ImageUplaoderModule {}
