import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'error-layout',
  templateUrl: './error-layout.component.html'
})
export class ErrorPageLayoutComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
