import { NgModule } from '@angular/core';

import { StoreModule } from '@ngrx/store';
import { cultureStoreReducer } from '@shared/store/culture-store/culture.reducer';
import { EffectsModule } from '@ngrx/effects';
import { CultureStoreEffects } from '@shared/store/culture-store/culture.effects';
import { CultureApiService } from '@shared/store/culture-store/services/culture.api.service';
import { CultureStoreService } from '@shared/store/culture-store/services/culture.store.service';
import { CultureResolver } from '@shared/resolvers/culture.resolver';
import { cultureFeatureKey } from './culture.state';

@NgModule({
  imports: [
    StoreModule.forFeature(cultureFeatureKey, cultureStoreReducer),
    EffectsModule.forFeature([CultureStoreEffects]),
  ],
  exports: [],
  providers: [CultureApiService, CultureStoreService, CultureResolver],
})
export class CultureStoreModule {}
