import { CountryState } from './country.state';
import * as fromAction from './country.action';
import { countryAdapter } from './country.entity';
import { Action, createReducer, on } from '@ngrx/store';

export const initialState: CountryState = countryAdapter.getInitialState({
  error: null,
  totalCount: 0,
});

const reducer = createReducer(
  initialState,
  on(fromAction.AddCountry, (state, { country }) => {
    return countryAdapter.addOne(country, state);
  }),

  on(fromAction.AddCountries, (state, { countries }) => {
    return countryAdapter.setAll(countries, state);
  }),

  on(fromAction.AddTotalCount, (state, { totalCount }) => {
    return countryAdapter.addMany([], { ...state, totalCount: totalCount });
  }),

  on(fromAction.AddError, (state, { error }) => {
    return countryAdapter.addMany([], { ...state, error: error });
  })
);

export function countryStoreReducer(state: CountryState = initialState, action: Action): CountryState {
  return reducer(state, action);
}
