export const environment = {
  production: true,
  env: 'production',
  baseUrl: 'https://sjoliv.rs.no/',
  auth: {
    clientId: 'Sjoliv',
    domain: 'https://authentication.rsservices.rs.no',
    knownAuthorities: 'https://authentication.rsservices.rs.no',
    scopes: ['openid email phone rs_info offline_access'],
    baseUrl: 'https://sjoliv.rs.no/',
    endSessionUrl: 'https://authentication.rsservices.rs.no/connect/endsession',
    redirectUrl: 'https://sjoliv.rs.no/signin-oidc',
  },
  services: {
    identity: 'https://api-sjoliv.rs.no',
    booking: 'https://api-sjoliv.rs.no',
    policy: 'https://api-sjoliv.rs.no',
    experience: 'https://api-sjoliv.rs.no',
    course: 'https://api-sjoliv.rs.no',
    payment: 'https://api-sjoliv.rs.no',
    partner: 'https://api-sjoliv.rs.no',
    classroom: 'https://api-sjoliv.rs.no',
  },
  googleMaps: {
    apiKey: 'AIzaSyC4ecfr352_k6bGCL_NDV6bmBk7z5ZOokM',
  },
  googleTagManager: {
    gtm: 'GTM-MS3G2DB',
    gtmAuth: 'xWThr82py_yxDT9e9YaL2A',
    gtmPreview: 'env-5',
  },
  mapbox: {
    accessToken: 'pk.eyJ1IjoibGluZWFuZG9yc2VuIiwiYSI6ImNqajVxdWhqYTF3aGkzcHBxYTdjMjl5ZmkifQ.aOv78EYDe0uQBTwsDlc3nw',
  },
  stripe: {
    public_key:
      'pk_test_51Hp8lVFoXZzV7NsYMFGtX6dG8KNp4MCL3Wn5wWnhsWageCsMnp2ZGc007nsNf2LMIpGrKBGPJC6aUMOVmqgR37Qp005T3QTguK',
  },
  campaignId: 'XKG9C7JUCZ7QAXQL',
  courseId: 'f43ecb27-d89c-4ef1-a39b-bafc91ed7b4a',
  moodleUrl: 'http://learning.rs.no/auth/rsauth/login.php',
  adminWebUrl: 'https://admin-sjoliv.rs.no',
  harbourSurveyUrl: 'https://sjoliv.rs.no/',
  onWaterIo: {
    url: 'https://api.onwater.io/api/v1/results',
    accessToken: 'LJAYTYQ3Qygxdch7BsPy'
  },
  maintenance: {
    showBanner: false,
    isOnGoing: false,
  },
  securboatAppLink: {
    android: "market://details?id=com.sjoliv.staging_tryggbaat.rs.android",
    ios: "itms-apps://itunes.apple.com/app/com.sjoliv.staging-tryggbaat.rs.ios"
  }
};
