import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpEvent, HttpEventType } from '@angular/common/http';
import { map, filter, first } from 'rxjs/operators';

@Injectable()
export class ImageUploaderServce {
  constructor() {}

  public uploadFileWithProgress<T>(fileEvent: Observable<HttpEvent<T>>): Observable<T> {
    return fileEvent.pipe(map((event) => this.getEventMessage<T>(event))).pipe(
      filter((media) => media !== null),
      map((media) => media),
      first()
    );
  }

  private getEventMessage<T>(event: HttpEvent<T>): T {
    switch (event.type) {
      case HttpEventType.UploadProgress: {
        return null;
      }

      case HttpEventType.Response:
        return event.body;

      default:
        return null;
    }
  }

  //   private fileUploadProgress(event): void {
  //     const percentDone = Math.round(100 * event.loaded / event.total);
  //     this.fileProgressService.setProgressPercentage(percentDone);
  //     if (percentDone === 100) {
  //         setTimeout(() => {
  //             this.toastrService.clear();
  //         }, 2000);
  //     }
  //   }
}
