import { Injectable } from '@angular/core';
import { Multimedia } from '@shared/models/multimedia';
import { DigitalCourse } from '@modules/course/digitalcourse/models';
import { Meta, Title } from '@angular/platform-browser';
import { ConfigService } from '@core/services/config.service';
import { ClassroomCourse } from '@modules/course/classroom/models';
import { Partner } from '@modules/partner/models';
import { Template } from '@modules/template/student/models/template';
import { Harbour } from '@modules/harbour/models/harbour';

@Injectable({
  providedIn: 'root',
})
export class MetaTagService {
  constructor(private meta: Meta, private title: Title, private config: ConfigService) {}

  digitalCourseOGMetaTag(course: DigitalCourse): void {
    this.title.setTitle(course.title);
    this.meta.updateTag({ property: 'og:title', content: course.title });
    this.meta.updateTag({ property: 'og:description', content: this.htmlToPlainText(course.description) },);
    this.meta.updateTag({ property: 'og:image', content: `${this.getImage(course.medias).url}?w=1200&h=600&fit=crop` });
    this.meta.updateTag({ property: 'og:image:secure_url', content: `${this.getImage(course.medias).url}?w=1200&h=600&fit=crop` });
    this.meta.updateTag({ property: 'og:image:width', content: '1200 ' });
    this.meta.updateTag({ property: 'og:image:height', content: '600' });
    this.meta.updateTag({ property: "og:image:type", content: this.getImage(course.medias).contentType });
    this.meta.updateTag({ property: 'og:url', content: `${this.getUrl()}digitalkurs/${course.courseSlug}` });
  }

  classroomCourseOGMetaTag(course: ClassroomCourse): void {
    this.title.setTitle(course.title);
    this.meta.updateTag({ property: 'og:title', content: course.title });
    this.meta.updateTag({ property: 'og:description', content: this.htmlToPlainText(course.about) },);
    this.meta.updateTag({ property: 'og:image', content: `${this.getImage(course.media).url}?w=1200&h=600&fit=crop` });
    this.meta.updateTag({ property: 'og:image:secure_url', content: `${this.getImage(course.media).url}?w=1200&h=600&fit=crop` });
    this.meta.updateTag({ property: 'og:image:width', content: '1200' });
    this.meta.updateTag({ property: 'og:image:height', content: '600'  });
    this.meta.updateTag({ property: "og:image:type", content: this.getImage(course.media).contentType });
    this.meta.updateTag({ property: 'og:url', content: `${this.getUrl()}kurs/${course.slug}` });
  }

  harbourOGMetaTag(harbour: Harbour): void {
    this.title.setTitle(harbour.name);
    this.meta.updateTag({ property: 'og:title', content: harbour.name });
    this.meta.updateTag({ property: 'og:description', content: (Boolean(harbour?.otherInformation) ? this.htmlToPlainText(harbour.otherInformation) : '') },);
    if(harbour.image) {
        this.meta.updateTag({ property: 'og:image', content: `${harbour.image}` });
        this.meta.updateTag({ property: 'og:image:width', content: '1200' });
        this.meta.updateTag({ property: 'og:image:height', content: '600'  });
        this.meta.updateTag({ property: "og:image:type", content: `image/${harbour.image.split('.').pop().toLowerCase()}`  });
        this.meta.updateTag({ property: 'og:image:secure_url', content: `${harbour.image}` });
      }
      else {
        this.meta.updateTag({ property: 'og:image', content: `${this.defaultHarbourBanner}?w=1200&h=600&fit=crop`});
        this.meta.updateTag({ property: 'og:image:secure_url', content: `${this.defaultHarbourBanner}?w=1200&h=600&fit=crop`});
        this.meta.updateTag({ property: 'og:image:width', content: '1200' });
        this.meta.updateTag({ property: 'og:image:height', content: '600'  });
        this.meta.updateTag({ property: "og:image:type", content: 'image/jpg' });
      }
      this.meta.updateTag({ property: 'og:url', content: `${this.getUrl()}/havn/${harbour.type.toLowerCase()}/${harbour.slug}` });
  }

  partnerOGMetaTag(partner: Partner): void {
    this.title.setTitle(partner.title);
    this.meta.updateTag({ property: 'og:title', content: partner.title });
    this.meta.updateTag({ property: 'og:description', content: this.htmlToPlainText(partner.description) });
    this.meta.updateTag({ property: 'og:image', content: `${this.getImage(partner.medias).url}?w=1200&h=600&fit=crop` });
    this.meta.updateTag({ property: 'og:image:secure_url', content: `${this.getImage(partner.medias).url}?w=1200&h=600&fit=crop` });
    this.meta.updateTag({ property: 'og:image:width', content: '1200' });
    this.meta.updateTag({ property: 'og:image:height', content: '600'  });
    this.meta.updateTag({ property: "og:image:type", content: this.getImage(partner.medias).contentType });
    this.meta.updateTag({ property: 'og:url', content: `${this.getUrl()}partner/${partner.partnerSlug}` });
  }

  studentTemplateOGMetaTag(template: Template): void {
    this.title.setTitle(template.title);
    this.meta.updateTag({ property: 'og:title', content: template.title });
    this.meta.updateTag({ property: 'og:description', content: this.htmlToPlainText(template.content) },);
    this.meta.updateTag({ property: 'og:image', content: `${this.getImage(template.media).url}?w=1200&h=600&fit=crop` });
    this.meta.updateTag({ property: 'og:image:secure_url', content: `${this.getImage(template.media).url}?w=1200&h=600&fit=crop` });
    this.meta.updateTag({ property: 'og:image:width', content: '1200' });
    this.meta.updateTag({ property: 'og:image:height', content: '600'  });
    this.meta.updateTag({ property: "og:image:type", content: this.getImage(template.media).contentType });
    this.meta.updateTag({ property: 'og:url', content: `${this.getUrl()}kursoversikt/${template.slug}` });
  }

  instructorTemplateOGMetaTag(): void {
    this.title.setTitle('Tilby dine kurs på RS Sjøliv');
    this.meta.updateTag({ property: 'og:title', content: 'Tilby dine kurs på RS Sjøliv' });
    this.meta.updateTag({ property: 'og:description', content: 'Vi i Redningsselskapet ønsker økt kunnskap på sjøen, og har derfor utviklet RS Sjøliv, hvor du som instruktør kan legge ut dine kurs som vi igjen markedsfører til våre over 350 000 medlemmer, samarbeidspartnere og følgere. Vårt mål er å inspirere flere til å ta flere kurs for å øke sin kompetanse på sjøen – og vi håper at du vil være med å bidra! Redningsselskapet godkjenner instruktører, utvikler kursinnhold, markedsfører kursene, samt utvikler og drifter Sjøliv-plattformen.' },);
    this.meta.updateTag({ property: 'og:image', content: `${this.InstructorTemplateBannerImage}?w=1200&h=600&fit=crop`});
    this.meta.updateTag({ property: 'og:image:secure_url', content: `${this.InstructorTemplateBannerImage}?w=1200&h=600&fit=crop` });
    this.meta.updateTag({ property: 'og:image:width', content: '1200' });
    this.meta.updateTag({ property: 'og:image:height', content: '600'  });
    this.meta.updateTag({ property: "og:image:type", content: 'image/png' });
    this.meta.updateTag({ property: 'og:url', content: `${this.getUrl()}kursoversikt-for-tilbydere` });
  }

  courseOverviewOGMetaTag(): void {
    this.title.setTitle('Kursoversikt');
    this.meta.updateTag({ property: 'og:title', content: 'Kursoversikt' });
    this.meta.updateTag({ property: 'og:description', content: 'På denne siden finner du oversikt over alle kurs som tilbys på Sjøliv. Du vil finne både nettkurs og andre kurstyper som arrangeres av selvstendige instruktører verifisert av RS. Bla for å finne det kurset som passer deg!' },);
    this.meta.updateTag({ property: 'og:image', content: `${this.courseBannerImage}?w=1200&h=600&fit=crop` });
    this.meta.updateTag({ property: 'og:image:secure_url', content: `${this.courseBannerImage}?w=1200&h=600&fit=crop` });
    this.meta.updateTag({ property: 'og:image:width', content: '1200' });
    this.meta.updateTag({ property: 'og:image:height', content: '600'  });
    this.meta.updateTag({ property: "og:image:type", content: 'image/png' });
    this.meta.updateTag({ property: 'og:url', content: `${this.getUrl()}kursoversikt` });
  }

  explorePageOGMetaTag(): void {
    this.title.setTitle('RS Sjøliv - for deg som er på sjøen!');
    this.meta.updateTag({ property: 'og:title', content: 'RS Sjøliv - for deg som er på sjøen!' });
    this.meta.updateTag({ property: 'og:description', content: 'Her finner du kart, havner, kurs og meldinger fra sjøvenner langs norskekysten. Registrer deg og logg inn for å ta i bruk alle tjenestene.' },);
    this.meta.updateTag({ property: 'og:image', content: `${this.exploreBannerImage}?w=1200&h=600&fit=crop`});
    this.meta.updateTag({ property: 'og:image:secure_url', content: `${this.exploreBannerImage}?w=1200&h=600&fit=crop`});
    this.meta.updateTag({ property: 'og:image:width', content: '1200' });
    this.meta.updateTag({ property: 'og:image:height', content: '600'  });
    this.meta.updateTag({ property: "og:image:type", content: 'image/jpg' });
    this.meta.updateTag({ property: 'og:url', content: `${this.getUrl()}` });
  }

  aboutPageOGMetaTag(): void {
    this.title.setTitle('Om Sjøliv');
    this.meta.updateTag({ property: 'og:title', content: 'RS Sjøliv - for deg som er på sjøen!' });
    this.meta.updateTag({ property: 'og:description', content: 'RS Sjøliv er et digitalt fellesskap for folk på og ved sjøen. På nettsiden finner du blant annet kurs, maritime tjenester, kart, havneinformasjon og du kan raskt og enkelt komme i kontakt med andre på sjøen i nærheten av deg.' },);
    this.meta.updateTag({ property: 'og:image', content: `${this.aboutBannerImage}?w=1200&h=600&fit=crop` });
    this.meta.updateTag({ property: 'og:image:secure_url', content: `${this.aboutBannerImage}?w=1200&h=600&fit=crop` });
    this.meta.updateTag({ property: 'og:image:width', content: '1200' });
    this.meta.updateTag({ property: 'og:image:height', content: '600'  });
    this.meta.updateTag({ property: "og:image:type", content: 'image/png' });
    this.meta.updateTag({ property: 'og:url', content: `${this.getUrl()}om-sjoliv` });
  }

  messagePageOGMetaTag() {
    this.title.setTitle('Sjøvenn');
    this.meta.updateTag({ property: 'og:title', content: 'Sjøvenn' });
    this.meta.updateTag({ property: 'og:description', content: 'Bli en sjøvenn du og! Har du sjø- eller båtrelaterte spørsmål du gjerne skulle hatt svar på? Start en forumtråd og få svar fra andre sjøvenner i nærheten.'},);
    this.meta.updateTag({ property: 'og:image', content: `${this.messageBannerImage}?w=1200&h=600&fit=crop`});
    this.meta.updateTag({ property: 'og:image:secure_url', content: `${this.messageBannerImage}?w=1200&h=600&fit=crop`});
    this.meta.updateTag({ property: 'og:image:width', content: '1200'});
    this.meta.updateTag({ property: 'og:image:height', content: '600'});
    this.meta.updateTag({ property: "og:image:type", content: 'image/png'});
    this.meta.updateTag({ property: 'og:url', content: `${this.getUrl()}meldinger`});
  }

  mapPageOGMetaTag() {
    this.title.setTitle('Kart');
    this.meta.updateTag({ property: 'og:title', content: 'Kart' });
    this.meta.updateTag({ property: 'og:description', content: 'Utforsk kartet på Sjøliv! Her vil du finne gjestehavner, uthavner, sjøvenner og meldinger, samt andre tilbud i nærheten!'},);
    this.meta.updateTag({ property: 'og:image', content: `${this.mapBannerImage}?w=1200&h=600&fit=crop`});
    this.meta.updateTag({ property: 'og:image:secure_url', content: `${this.mapBannerImage}?w=1200&h=600&fit=crop`});
    this.meta.updateTag({ property: 'og:image:width', content: '1200'});
    this.meta.updateTag({ property: 'og:image:height', content: '600'});
    this.meta.updateTag({ property: "og:image:type", content: 'image/png'});
    this.meta.updateTag({ property: 'og:url', content: `${this.getUrl()}kart`});
  }

  highSpeedCourseOGMetaTag(): void {
    this.title.setTitle('Høyhastighetsbevis');
    this.meta.updateTag({ property: 'og:title', content: 'Høyhastighetsbevis' });
    this.meta.updateTag({ property: 'og:description', content: 'Høyhastighetskurset består av en teoretisk del, en praktisk del og en teoretisk prøve. Teoridelen varer i 6 timer inkludert prøven og praktisk del 2-4 timer avhengig av antall deltagere.' });
    this.meta.updateTag({ property: 'og:image', content: `${this.highSpeedCourseBanner}?w=1200&h=600&fit=crop` });
    this.meta.updateTag({ property: 'og:image:secure_url', content: `${this.highSpeedCourseBanner}?w=1200&h=600&fit=crop` });
    this.meta.updateTag({ property: 'og:image:width', content: '1200' });
    this.meta.updateTag({ property: 'og:image:height', content: '600'  });
    this.meta.updateTag({ property: "og:image:type", content: 'image/jpg'});
    this.meta.updateTag({ property: 'og:url', content: `${this.getUrl()}kursoversikt/informasjon/hoyhastighetsbevis` });
  }

   allHarboursOGMetaTag(): void {
    this.title.setTitle('Havner');
    this.meta.updateTag({ property: 'og:title', content: 'Havner' });
    this.meta.updateTag({ property: 'og:description', content: 'Utforsk alle havner og se fasiliteter og relevant informasjon om havnen!' });
    this.meta.updateTag({ property: 'og:image', content: `${this.allHarboursBanner}?w=1200&h=600&fit=crop` });
    this.meta.updateTag({ property: 'og:image:secure_url', content: `${this.allHarboursBanner}?w=1200&h=600&fit=crop` });
    this.meta.updateTag({ property: 'og:image:width', content: '1200' });
    this.meta.updateTag({ property: 'og:image:height', content: '600'  });
    this.meta.updateTag({ property: "og:image:type", content: 'image/jpg'});
    this.meta.updateTag({ property: 'og:url', content: `${this.getUrl()}/havn/alle` });
  }

  removeOGMetaTag(): void {
    this.title.setTitle('RS Sjøliv - for deg som er på sjøen!');
    this.meta.updateTag({ property: 'og:title', content: "RS Sjøliv - for deg som er på sjøen!" });
    this.meta.updateTag({ property: 'og:description', content: "Her finner du kart, havner, kurs og meldinger fra sjøvenner langs norskekysten. Registrer deg og logg inn for å ta i bruk alle tjenestene." },);
    this.meta.updateTag({ property: 'og:image', content: `${this.exploreBannerImage}?w=1200&h=600&fit=crop` });
    this.meta.updateTag({ property: 'og:image:secure_url', content: `${this.exploreBannerImage}?w=1200&h=600&fit=crop` });
    this.meta.updateTag({ property: 'og:url', content: `${this.getUrl()}}` });
  }

  private getImage(medias: Multimedia[]): Multimedia {
    const media = medias.find((x) => x.default);
    return media;
  }

  private getUrl(): string {
    return this.config.getCurrent().authentication.baseUrl;
  }

  private get courseBannerImage(): string {
    const env = this.config.getCurrent().env;
    switch (env) {
      case 'production':
        return 'https://rssjoliv-prod.imgix.net/seo/kursoversikt.png';
      case 'staging':
        return 'https://rssjoliv-staging.imgix.net/seo/kursoversikt.png';
      default:
        return 'https://rssjoliv-dev.imgix.net/seo/kursoversikt.png';
    }
  }

  private get InstructorTemplateBannerImage(): string {
    const env = this.config.getCurrent().env;
    switch (env) {
      case 'production':
        return 'https://rssjoliv-prod.imgix.net/seo/kursoversikt-for-tilbydere.png';
      case 'staging':
        return 'https://rssjoliv-staging.imgix.net/seo/kursoversikt-for-tilbydere.png';
      default:
        return 'https://rssjoliv-dev.imgix.net/seo/kursoversikt-for-tilbydere.png';
    }
  }


  private get exploreBannerImage(): string {
    const env = this.config.getCurrent().env;
    switch (env) {
      case 'production':
        return 'https://rssjoliv-prod.imgix.net/explore/banner.jpg';
      case 'staging':
        return 'https://rssjoliv-staging.imgix.net/explore/banner.jpg';
      default:
        return 'https://rssjoliv-dev.imgix.net/explore/banner.jpg';
    }
  }

 private get aboutBannerImage(): string {
    const env = this.config.getCurrent().env;
    switch (env) {
      case 'production':
        return 'https://rssjoliv-prod.imgix.net/about-sjoliv/about-sjoliv.jpg';
      case 'staging':
        return 'https://rssjoliv-staging.imgix.net/about-sjoliv/about-sjoliv.jpg';
      default:
        return 'https://rssjoliv-dev.imgix.net/about-sjoliv/about-sjoliv.jpg';
    }
  }

  private get messageBannerImage(): string {
    const env = this.config.getCurrent().env;
    switch (env) {
      case 'production':
        return 'https://rssjoliv-prod.imgix.net/seo/sjovenn.png';
      case 'staging':
        return 'https://rssjoliv-staging.imgix.net/seo/sjovenn.png';
      default:
        return 'https://rssjoliv-dev.imgix.net/seo/sjovenn.png';
    }
  }

  private get mapBannerImage(): string {
    const env = this.config.getCurrent().env;
    switch (env) {
      case 'production':
        return 'https://rssjoliv-prod.imgix.net/seo/kart.png';
      case 'staging':
        return 'https://rssjoliv-staging.imgix.net/seo/kart.png';
      default:
        return 'https://rssjoliv-dev.imgix.net/seo/kart.png';
    }
  }
  private get defaultHarbourBanner(): string {
    const env = this.config.getCurrent().env;
    switch (env) {
      case 'production':
        return 'https://rssjoliv-prod.imgix.net/harbour/harbour_default_banner.png';
      case 'staging':
        return 'https://rssjoliv-staging.imgix.net/harbour/harbour_default_banner.png';
      default:
        return 'https://rssjoliv-dev.imgix.net/harbour/harbour_default_banner.png';
    }
  }

  private get highSpeedCourseBanner(): string {
    const env = this.config.getCurrent().env;
    switch (env) {
      case 'production':
        return 'https://rssjoliv-prod.imgix.net/hoyhastighet/hastighetskurs.jpg';
      case 'staging':
        return 'https://rssjoliv-staging.imgix.net/hoyhastighet/hastighetskurs.jpg';
      default:
        return 'https://rssjoliv-dev.imgix.net/hoyhastighet/hastighetskurs.jpg';
    }
  }

  private get allHarboursBanner(): string {
      const env = this.config.getCurrent().env;
      switch (env) {
        case 'production':
          return 'https://rssjoliv-prod.imgix.net/harbour/harbour_all.jpg';
        case 'staging':
          return 'https://rssjoliv-staging.imgix.net/harbour/harbour_all.jpg';
        default:
          return 'https://rssjoliv-dev.imgix.net/harbour/harbour_all.jpg';
      }
  }


  private htmlToPlainText(html) {
    return html.replace(/<(?:.|\n)*?>/gm, ' ');
  }
}
