import { Injectable } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { DialogComponent } from './dialog/dialog.component';
import { DialogOptions, DefaultOption } from './dialog.options';

@Injectable()
export class PromptDialogService {
    constructor(private dialog: MatDialog) { }

    public openPromptDialog(options: DialogOptions = new DefaultOption(), config: MatDialogConfig = new DefaultConfig()): MatDialogRef<DialogComponent, boolean> {
        config.data = options;
        return this.dialog.open(DialogComponent, config);
    }

    public openPromptDialogWithTranslate(translateKey: string, icon: string, config: MatDialogConfig = new DefaultConfig()): MatDialogRef<DialogComponent, boolean> {
        return this.dialog.open(DialogComponent, config);
    }
}

export class DefaultConfig extends MatDialogConfig<any> {
    constructor() {
        super();
        this.width = '500px';
    }
}
