import { Injectable } from '@angular/core';
import { HttpParams, HttpClient, HttpHeaders, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { PageList } from '../models/page-list';

@Injectable({
  providedIn: 'root'
})
export class BaseApiService {
  constructor(public http: HttpClient) {
  }

  public getAll<T>(
    url: string,
    options?: {
      headers?:
        | HttpHeaders
        | {
        [header: string]: string | string[];
      };
      observe?: 'body';
      params?:
        | HttpParams
        | {
        [param: string]: string | string[];
      };
      reportProgress?: boolean;
      responseType?: 'json';
      withCredentials?: boolean;
    }
  ): Observable<T[]> {
    return this.http.get<T[]>(url, options);
  }

  public getAllWithPagination<T>(url: string, params?: HttpParams): Observable<PageList<T>> {
    return this.http.get<T[]>(url, {params: params, observe: 'response'}).pipe(
      map((response) => {
        const total = response.headers.has('x-total-count')
          ? (response.headers.get('x-total-count') as any as number)
          : 0;

        let result: PageList<T> = {
          items: response.body,
          total: total,
        };
        return result;
      })
    );
  }

  public get<T>(
    url: string,
    id: string | number,
    options?: {
      headers?:
        | HttpHeaders
        | {
        [header: string]: string | string[];
      };
      observe?: 'body';
      params?:
        | HttpParams
        | {
        [param: string]: string | string[];
      };
      reportProgress?: boolean;
      responseType?: 'json';
      withCredentials?: boolean;
    }
  ): Observable<T> {
    return this.http.get<T>(`${url}/${id}`, options);
  }

  public update<T>(
    url: string,
    body: Partial<T>,
    options?: {
      headers?:
        | HttpHeaders
        | {
        [header: string]: string | string[];
      };
      observe?: 'body';
      params?:
        | HttpParams
        | {
        [param: string]: string | string[];
      };
      reportProgress?: boolean;
      responseType?: 'json';
      withCredentials?: boolean;
    }
  ): Observable<T> {
    return this.http.put<T>(url, body, options);
  }

  public register<T>(
    url: string,
    body: Partial<T>,
    options?: {
      headers?:
        | HttpHeaders
        | {
        [header: string]: string | string[];
      };
      observe?: 'body';
      params?:
        | HttpParams
        | {
        [param: string]: string | string[];
      };
      reportProgress?: boolean;
      responseType?: 'json';
      withCredentials?: boolean;
    }
  ): Observable<T> {
    return this.http.post<T>(url, body, options);
  }

  public delete<T>(
    url: string,
    options?: {
      headers?:
        | HttpHeaders
        | {
        [header: string]: string | string[];
      };
      observe?: 'body';
      params?:
        | HttpParams
        | {
        [param: string]: string | string[];
      };
      reportProgress?: boolean;
      responseType?: 'json';
      withCredentials?: boolean;
    }
  ): Observable<T> {
    return this.http.delete<T>(url, options);
  }

  public uploadMedia<T>(url: string, formData: FormData, params?: HttpParams): Observable<HttpEvent<T[]>> {
    return this.http.post<T[]>(url, formData, {params: params, reportProgress: true, observe: 'events'});
  }
}
