import { UserProfile } from '../models/user';

export const identityFeatureKey = 'identity';

export interface IdentityState {
  error: any;
  totalCount: number;
  userProfile: UserProfile | null;
  sendOtp: boolean;
  isValidOtp: boolean;
}
