import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { AlertMessagesService } from './alert-messages.service';
import { HttpErrorResponse } from '@angular/common/http';
import { ToastrPositionClassType } from '@modules/shared/enums/toastr-position-class-type';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  constructor(
    private toastr: ToastrService,
    private alertMessagesService: AlertMessagesService) {

    }

  public success(message: string, domain?: string, title: string = 'success', positionClass: ToastrPositionClassType = ToastrPositionClassType.TOP_CENTER, timeOut: number = 10000): void {
    const translatemessage = this.alertMessagesService.translateSuccessMessage(message, domain) ?? message;
    const translatetitle  = this.alertMessagesService.translateTitle(title);

    this.toastr.success(translatemessage, translatetitle, {
      timeOut: timeOut,
      positionClass: positionClass
    });
  }

  public info(message: string, positionClass: ToastrPositionClassType = ToastrPositionClassType.TOP_CENTER, timeOut: number = 10000): void {
    this.toastr.info(message, 'Information', {
      timeOut: timeOut,
      positionClass: positionClass
    });
  }

  public warning(message: string, domain?: string, positionClass: ToastrPositionClassType = ToastrPositionClassType.TOP_CENTER, timeOut: number = 10000, title: string = 'warning'): void {
    const translatemessage = this.alertMessagesService.translateErrorMessage(message, domain) ?? message;
    const translatetitle = this.alertMessagesService.translateTitle(title);

    this.toastr.warning(translatemessage, translatetitle, {
      timeOut: timeOut,
      positionClass: positionClass
    });
  }

  public error(message: string, domain?: string, positionClass: ToastrPositionClassType = ToastrPositionClassType.TOP_CENTER, timeOut: number = 10000, title: string = 'error'): void {
    const translatemessage = this.alertMessagesService.translateErrorMessage(message, domain) ?? message;
    const translatetitle = this.alertMessagesService.translateTitle(title);

    this.toastr.error(translatemessage, translatetitle, {
      timeOut: timeOut,
      positionClass: positionClass
    });
  }

  public serverError(httpErrorResponse: HttpErrorResponse, domain?: string, positionClass?: ToastrPositionClassType, timeOut?: number): void {
    switch (httpErrorResponse.error.status) {
      case 400:
      case 404:
      case 500:
        this.error(httpErrorResponse.error.title, domain, positionClass, timeOut);
        break;
      case 403:
        this.error(httpErrorResponse.error.detail, domain, positionClass, timeOut);
        break;
      default:
        this.warning(httpErrorResponse.error.title, domain, positionClass, timeOut);
    }
  }
}
