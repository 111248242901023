<!-- Footer Start Here -->
<footer style="display: block;" class="customer_feedback">
  <div class="container px-md-0">
    <ng-container *ngIf="isFeedbackSent; else feedbackForm">
      <span class="font-weight-bold">{{ 'layout.customer_feedback.thank_you' | transloco }}</span><br><br>
      <span>{{ 'layout.customer_feedback.contact_info_l1' | transloco }}</span><br>
      <span>{{ 'layout.customer_feedback.contact_info_l2_p1' | transloco }} <a href="mailto:post@rs.no"><u>post@rs.no</u></a> {{ 'layout.customer_feedback.contact_info_l2_p2' | transloco }}</span>
    </ng-container>
    <ng-template #feedbackForm>
      <form [formGroup]="customerFeedbackForm">
        <h3>{{ 'layout.customer_feedback.heading' | transloco }}</h3>

        <label for="isFound">{{ 'layout.customer_feedback.labels.give_feedback' | transloco }}</label>

        <div>
          <mat-radio-group
            id="isFound"
            formControlName="isFound"
            (ngModelChange)="clearFeedback()"
          >
            <mat-radio-button color="primary"
                              value="Yes">{{ 'layout.customer_feedback.yes' | transloco }}</mat-radio-button>
            <br>
            <mat-radio-button color="primary"
                              value="No">{{ 'layout.customer_feedback.no' | transloco }}</mat-radio-button>
          </mat-radio-group>
        </div>

        <ng-container *ngIf="customerFeedbackForm.get('isFound').value !== null">
          <div>
            <label for="email">{{ 'layout.customer_feedback.labels.email' | transloco }}</label>
            <input
              id="email"
              class="form-control form-control-input rounded-0"
              formControlName="email"
              type="email"
            >
          </div>
          <div>
            <label for="feedback"
                   *ngIf="customerFeedbackForm.get('isFound').value === 'Yes'"> {{ 'layout.customer_feedback.labels.feedback_positive' | transloco }}
            </label>
            <label for="feedback"
                   *ngIf="customerFeedbackForm.get('isFound').value === 'No'"> {{ 'layout.customer_feedback.labels.feedback_negative' | transloco }}
            </label>
          </div>
          <div>
            <textarea type="text"
                      id="feedback"
                      formControlName="description"
                      class="form-control form-control-textarea rounded-0"
                      rows="5" cols="50"
                      maxlength="300"
                      required>
            </textarea>
            <span class="character-limit">
              {{ customerFeedbackForm.get('description').value?.length + ' ' + ('layout.customer_feedback.character_limit' | transloco) }}
            </span>
          </div>

          <div>
            <button (click)="submitCustomerFeedbackForm()" class="submit-feedback">
              <span>{{ 'layout.customer_feedback.send' | transloco }}</span>
            </button>
          </div>
        </ng-container>
      </form>
    </ng-template>
  </div>
</footer>
<!-- Footer End Here -->

