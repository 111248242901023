import { Component, OnInit } from '@angular/core';
import { PrivacyModalService } from '../services/modal.service';

@Component({
  selector: 'app-terms-and-condition',
  templateUrl: './terms-and-condition.component.html',
  styleUrls: ['./terms-and-condition.component.scss']
})
export class TermsAndConditionComponent implements OnInit {

  constructor(
    private privacyModalService: PrivacyModalService,
  ) { }

  ngOnInit() {
  }

  openInstructorTnc(): void {
    this.privacyModalService.openInstructorTermsAndCondition();
  }
}
