import { createSelector, createFeatureSelector } from '@ngrx/store';
import { adapter } from './culture.entity';
import { CultureState, cultureFeatureKey } from './culture.state';
import { Predicate } from '@angular/core';
import { Culture } from '../../models/culture';

export const { selectAll, selectEntities, selectIds, selectTotal } = adapter.getSelectors();

export const getCultureState = createFeatureSelector<CultureState>(cultureFeatureKey);

export const getAllCultures = createSelector(getCultureState, selectAll);

export const getCultureById = (id: string) => createSelector(getCultureState, (state) => state.entities[id]);

export const getCulturesBySearch = (predicate: Predicate<Culture>) =>
  createSelector(getAllCultures, (state) => state.filter(predicate));

export const getCultureBySearch = (predicate: Predicate<Culture>) =>
  createSelector(getAllCultures, (state) => state.find(predicate));

export const selectTotalCount = createSelector(getCultureState, (state) => state.totalCount);

export const selectError = createSelector(getCultureState, (state) => state.error);
