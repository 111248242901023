import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import * as fromAction from '../users-location.action';
import * as fromSelector from '../users-location.selector';
import { ConfigService } from '@core/services/config.service';
import { TokenService } from '@core/services/token-service';
import { createSignalRHub, findHub } from 'ngrx-signalr-core';
import { UserLocationState } from '../users-location.state';
import { UserLocation } from '@shared/models/user-location';

@Injectable({
  providedIn: 'root',
})
export class UserLocationStoreService {
  public hubDefinitions = {
    usersLocation: {
      hubName: 'users-locations',
      url:
        this.configService.getCurrent()?.services.booking +
        '/hubs/notification?access_token=' +
        this.tokenService.getToken(),
    },
  };

  constructor(
    private store: Store<UserLocationState>,
    private configService: ConfigService,
    private tokenService: TokenService
  ) {}

  public createHubs(): void {
    const usersLocationHubDefinition = this.hubDefinitions.usersLocation;

    this.hubDefinitions = {
      usersLocation: usersLocationHubDefinition,
    };
    if (!findHub(usersLocationHubDefinition)) this.store.dispatch(createSignalRHub(usersLocationHubDefinition));
  }

  public fetchAllUsersLocation(
    from: string = '',
    userId: string = '',
    search: string = '',
    pageIndex: number = 0,
    pageSize: number = 4,
    position: string = '',
    lastUpdatedFilter: string | number = ''
  ): void {
    this.store.dispatch(
      fromAction.FetchUserLocations({
        from: from,
        userId: userId,
        search: search,
        pageIndex: pageIndex,
        pageSize: pageSize,
        position: position,
        lastUpdatedFilter: lastUpdatedFilter,
      })
    );
  }

  public getAllUsersLocation(): Observable<UserLocation[]> {
    return this.store.pipe(select(fromSelector.getAllUserLocations));
  }

  public fetchLocationByUserId(userId: string): void {
    this.store.dispatch(fromAction.FetchUserLocation({ userId }));
  }

  public deleteUserLocationById(id: string): void {
    this.store.dispatch(
      fromAction.DeleteUserLocation({
        id: id,
      })
    );
  }

  public getUserLocationById(id: string): Observable<UserLocation> {
    return this.store.select(fromSelector.getUserLocationById(id));
  }

  public getUserLocationByAuthorId(authorId: string): Observable<UserLocation> {
    return this.store.select(fromSelector.getLocationByAuthorId(authorId));
  }

  public getSelectedUserLocation(): Observable<UserLocation> {
    return this.store.select(fromSelector.getSelectedUserLocation);
  }

  public setSelectedUserLocation(userLocation: UserLocation): void {
    this.store.dispatch(fromAction.AddSelectedUserLocation({ userLocation: userLocation }));
  }

  public addUserLocation(userLocation: UserLocation): void {
    this.store.dispatch(fromAction.AddUserLocation({ userLocation: userLocation }));
  }

  public addTotalCount(data: number): void {
    this.store.dispatch(fromAction.AddTotalCount({ totalCount: data }));
  }

  public toggleUserLocationPreference(userId: string, allowLocation: boolean): void {
    if (allowLocation) {
      this.store.dispatch(fromAction.EnableUserLocation({ userId, allowLocation }));
    } else {
      this.store.dispatch(fromAction.DisableUserLocation({ userId, allowLocation }));
    }
  }
}
