<div class="col-12 col-md-12 col-lg-12 col-xl-12">
<div class="terms-conditions-content">
<h1 class="h1"><strong>BRUKERVILKÅR FOR PLATTFORMEN RS SJØLIV</strong></h1>
<br>
<p class="paragraph">Følgende vilkår (heretter “vilkårene”) gjelder for brukere av RS Sjøliv og styrer din tilgang til og bruk av teknologiplattformen RS Sjøliv.  
</p>

<p class="paragraph">
    Ved å bruke RS Sjøliv bekrefter du å ha forstått og overholde disse vilkårene, inkludert de gjeldende regler om personvern, herunder
    <a target="_blank" href="https://rs.no/personvern/">
        RS personvernerklæring
    </a>
    og tillegget om felles behandlingsansvar for tjenestetilbydere.
</p>

 <blockquote class="paragraph">
    RS Sjøliv tilbys og er tilgjengelig for registrerte brukere som er 15 år eller eldre. Hvis du er under 15 år, kan du ikke registrere deg på RS Sjøliv. RS Sjøliv har også åpne kurs som er tilgjengelig for alle og ikke krever registrering.
</blockquote>

<ul class="conditions-item">
    <li>
         <h4 class="sub-title">
            1. DEFINISJONER
         </h4>
         <div class="paragraph">
            <strong>RS Sjøliv</strong>
            er en teknologiplattform (heretter «plattformen») bestående av en nettside og en applikasjon, utviklet, driftet og eiet av Redningsselskapet. På nettsiden har man tilgang til kursoversikt, kart med ulike kartoppføringer, meldingstjenesten «Sjøvenn» og en markedsplass, «Sjøtorget» (under utvikling). I applikasjonen har man tilgang til kart med ulike kartoppføringer, meldingstjenesten «Sjøvenn» og man kan i tillegg melde assistanse og nød direkte til Redningsselskapets servicetelefon eller til Telenor kystradio. 
            <br><br>
            <strong>Bruker</strong>
            betyr enhver fysisk person som er registrert med en unik konto på plattformen. Brukere er delt inn i kategoriene tjenestetilbyder og kunde.
            <br><br>
            <strong>
            Tjenestetilbyder
            </strong>
            betyr en fysisk person som tilbyr varer eller tjenester på egne vegne eller på vegne av en juridisk enhet via plattformen, og er registrert som tjenestetilbyder.
            <br><br>
            <strong>Instruktør</strong>
            er en type tjenestetilbyder som leverer digitale og/eller fysiske kurs via RS Sjøliv. For å være instruktør må vedkommende være tilknyttet en juridisk enhet og oppfylle gjeldende krav til det eller de spesifikke kursene vedkommende leverer, herunder krav satt av Sjøfartsdirektoratet. Instruktører skal oppfylle de til enhver tid gjeldende krav som RS Sjøliv setter for det konkrete kurs. Oversikt over kravene er tilgjengelig 
            <a target="_blank" href="https://rs.no/personvern/">
                her.
            </a>
            <br><br>
            <strong>Juridisk enhet</strong>
            betyr det foretak som tjenestetilbyder er tilknyttet og leverer varer eller tjenester på vegne av på plattformen. Dette inkluderer også enkeltpersonforetak.<br><br>
            <strong>Kunde</strong>
            betyr en fysisk person som kjøper varer eller tjenester via plattformen. Alle brukere av plattformen som ikke er registrert som tjenestetilbyder er regnet som kunde.<br><br>
            <strong>Kursdeltaker</strong>
            er en type kunde som kjøper kurs via RS Sjøliv. For enkelte kurs er det særlige krav til kursdeltakere, se til enhver tid gjeldende oversikt for de konkrete kurs på 
            <a target="_blank" href="https://sjoliv.rs.no/kursoversikt-for-tilbydere">
                RS Sjøliv.
            </a><br><br>
            <strong>Kursoversikt</strong>
            viser tilgjengelige kurs på RS Sjøliv, hvor RS Sjøliv formidler kontakt mellom kunde og tjenestetilbyder. RS Sjøliv leverer kvalitetssikret kursinnhold og Redningsselskapet godkjenner den enkelte instruktør. På plattformen får instruktører en administrasjonsside hvor de kan holde oversikten over sine kurs, bookinger og kursdeltakere.<br><br>
            <strong>Sjøvenn</strong>
            er en meldingstjeneste der man kan søke informasjon om hva som skjer på sjøen og kommunisere med fritidsfartøy i nærheten. Når man kommuniserer med båter rundt seg kan man enten forespørre eller gi informasjon eller be båter rundt seg om hjelp med trivielle ting. Merk at i tilfelle behov for assistanse må Redningsselskapets Servicetelefon kontaktes via 02016. Ved nød kontakt Telenor kystradio via 120 eller ring 112.<br><br>
            <strong>Sjøtorget</strong>
            er en markedsplassfunksjon på plattformen der det tilbys og avtales kjøp av ulike varer og tjenester. Markedsplassen gjør det mulig for tjenestetilbyder å liste opp varer og tjenester. Hvem som er tjenestetilbyder, vil være tydelig i hvert enkelt tilfelle. Redningsselskapet er uten ansvar for varer og tjenester som tilbys av andre enn Redningsselskapet.<br><br>
            <em>
            Sjøtorget er fremdeles under utvikling og enda ikke tilgjengelig for brukere på plattformen.
            </em><br><br>
            <strong>Vare</strong>
            betyr enhver fysisk gjenstand som tilbys av en tjenestetilbyder gjennom plattformen. Innhold og aktiviteter på Sjøvenn regnes ikke som varer etter disse vilkår.<br><br>    
            <strong>Tjeneste</strong>
            betyr enhver aktivitet, utflukt, leveranse eller tjeneste av kommersiell karakter, herunder digitale og fysiske kurs, som tilbys av en tjenestetilbyder gjennom plattformen. Innhold og aktiviteter på Sjøvenn regnes ikke som tjenester etter disse vilkår.<br><br>
            <strong>Innhold</strong>
            betyr tekst-, lyd-, visuelt og audiovisuelt innhold og informasjon som vises eller gjøres tilgjengelig gjennom plattformen, inkludert, men ikke begrenset til bilder, filmer og reklamemateriale.<br><br>
        </div>
    </li>

    <li>
        <h4 class="sub-title">
            2. PLATTFORMEN
         </h4>
         <div class="terms-conditions-content">
             <ul class="conditions-item">
                 <li>
                    <h4 class="sub-title">
                        2.1 Eierskap og Lisens
                    </h4>
                    <div class="paragraph">
                        Redningsselskapet eier plattformen, og innehar de immaterielle rettighetene til tekst, bilde, design, brukergrensesnitt og annet materiale og informasjon som er tilgjengelig for deg på plattformen. Det samme gjelder den underliggende programkoden for tjenestene. Slikt materiale kan ikke brukes på annen måte enn det en normal bruk av plattformen nødvendiggjør. 
                        <br><br><br>
                        Redningsselskapet gir brukere en begrenset, ikke-eksklusiv, ikke-underlisensierbar, tilbakekallbar, ikke-overførbar lisens for å få tilgang til, og bruke plattformen.
                        <br><br>
                        Med unntak av privat bruk, er det ikke tillatt å kopiere, reprodusere, publisere, laste opp, sende eller distribuere noe materiale eller informasjon fra plattformen uten forutgående skriftlig tillatelse fra Redningsselskapet.
                        <br><br>
                        Bruk av automatiserte tjenester samt andre fremgangsmåter for systematisk eller regelmessig kopi av innholdet på plattformen er heller ikke tillatt uten eksplisitt skriftlig samtykke fra Redningsselskapet.
                    </div>
                 </li>
                 <li>
                    <h4 class="sub-title">
                      2.2 Brukergenerert innhold
                    </h4>
                     <div class="paragraph">
                        Brukergenerert innhold refererer til innholdet som er lagt til av brukere i motsetning til innhold utviklet av plattformen. Alt innhold lastet opp til plattformen av brukere er brukergenerert innhold.
                        <br><br><br>
                        Enhver bruker er selv ansvarlig for at opplastet/opprettet innhold er korrekt og at bruker innehar de nødvendige rettigheter, og at innholdet ikke er i strid med gjeldende lover og regler. Redningsselskapet sjekker ikke innholdet for hensiktsmessighet, brudd på markedsføringsloven,  opphavsrettigheter, varemerker eller andre rettigheter eller plikter. Redningsselskapet inviterer alle til å rapportere brudd sammen med bevis på eierforhold etter behov. Rapportert krenkende innhold kan fjernes eller deaktiveres. Redningsselskapet forbeholder seg retten til å utestenge brukere som misbruker, manipulerer eller anvender plattformen i strid med disse vilkårene eller de forutsetningene som ligger til grunn for den.
                        <br><br>
                        Redningsselskapet kan benytte brukergenerert innhold i sin markedsføring av RS Sjøliv. 
                    </div>
                 </li>
                 <li>
                    <h4 class="sub-title">
                      2.3 Akseptabel bruk
                     </h4>
                     <div class="paragraph">
                        Brukere skal bruke plattformen utelukkende til dens formål og vi oppfordrer alle til å bruke en trivelig og saklig tone både i oppføringer og i kommunikasjon med andre brukere og med Redningsselskapet.
                        <br><br><br>
                        Som bruker forplikter du deg til å opptre profesjonelt, og fremme gode holdninger og sjøsikkerhet. Brudd på dette kan føre til at du mister tilgang til plattformen og/eller at ditt publiserte innhold blir endret eller fjernet.
                        <br><br>
                        Følgende er eksempel på handlinger og oppførsel som kan resultere i utestengelse fra plattformen:
                        <br>
                        <ul class="terms-conditions-list">
                            <li>bruke plattformen i strid med disse vilkårene og eventuelt tilleggsvilkår, Redningsselskapets retningslinjer, lover eller forskrifter eller for noe formål som bryter med dette;</li>
                            <li>lage oppføringer som ikke er relatert til en konkret vare eller tjeneste;</li>
                            <li>eksplisitt eller implisitt, skriftlig eller muntlig, foreslå eller kreve at ytelsen eller innholdet av en vare eller tjeneste skal avhenge av eventuelle betalinger eller tjenester eller andre forhold som ikke er tydelig angitt i oppføringen;</li>
                            <li>bruke plattformen til å sende ut generell markedsføring eller spam, eller publisere uriktig eller upassende innhold på plattformen;</li>
                            <li>diskriminere eller trakassere noen på grunnlag av rase, nasjonal opprinnelse, religion, kjønn, kjønnsidentitet, fysisk eller psykisk funksjonshemning, medisinsk tilstand, sivilstand, alder eller seksuell legning;</li>
                            <li>opptre på en måte som Redningsselskapet anser upassende overfor andre brukere, Redningsselskapet eller andre;</li>
                            <li>omgå betalingsløsningen for salg av kurs inkludert på RS Sjøliv, ved å gjøre en avtale om betaling utenfor plattformen med en annen bruker som du fant gjennom plattformen;</li>
                            <li>kopiere, lagre eller på annen måte bruke informasjon som er innhentet gjennom plattformen til formål som ikke uttrykkelig er tillatt i henhold til disse vilkårene;</li>
                            <li>krenke Redningsselskapets rettigheter eller andre tredjepersons eller enheters rettigheter, inkludert, uten begrensning, deres immaterielle rettigheter, personvern, publisitet eller kontraktsrettigheter.</li>
                        </ul>
                        For å sikre plattformens integritet forbeholder Redningsselskapet seg retten til når som helst, etter eget skjønn, å blokkere brukere fra å få tilgang til plattformen. Redningsselskapet forbeholder seg også retten til å endre eller fjerne brukergenerert innhold som Redningsselskapet mener ikke er i tråd med disse vilkårene
                        <br><br>
                        Tjenestetilbydere eller juridiske enheter kan ikke benytte plattformen til generell beskrivelse og markedsføring av en bedrift eller generelt tilbud uten særskilt godkjenning fra Redningsselskapet.
                        <br><br>
                        Tjenestetilbydere forplikter seg til enhver tid å overholde gjeldende lover og øvrig regelverk under planlegging, gjennomføring og etterarbeid av tjenester som leveres via plattformen. Brudd på dette kan føre til utestenging av plattformen.
                        <br><br>
                        Juridiske enheter er ansvarlig for at deres tjenestetilbydere overholder de til enhver tid gjeldende vilkår for RS Sjøliv. Ved brudd på vilkårene fra enkelte tjenestetilbydere forbeholder Redningsselskapet seg retten til når som helst, etter eget skjønn, å blokkere den juridiske enheten som sådan, inkludert alle deres tilknyttede tjenestetilbydere, fra å få tilgang til plattformen.
                        <br>
                    </div>
                 </li>
             </ul>
         </div>
    </li>
    <li>
        <h4 class="sub-title">
            3. KONTRAKTSFORHOLD
         </h4>
         <div class="paragraph">
            Ved bestilling og kjøp av varer eller tjenester gjennom plattformen etableres et kontraktsforhold mellom tjenestetilbyder og kunde. Partene i handelen skal til enhver tid overholde lover og øvrig regelverk gjeldende for det konkrete avtaleforhold.
            <br><br><br>
            Redningsselskapet er ikke part i kontraktsforholdet og har heller intet ansvar for feil eller mangler. Redningsselskapet er ikke ansvarlig for eventuelle reklamasjoner. Alle innsigelser og eventuelle krav om misligholdsbeføyelser skal ikke rettes mot Redningsselskapet, men gjøres gjeldende overfor den relevante motpart (tjenestetilbyder eller kunde).         </div>
    </li>
    <li>
        <h4 class="sub-title">
            4. TILLEGGSVILKÅR
         </h4>
         <div class="paragraph">
            Tilleggsvilkår til disse vilkårene kan gjelde for bestemte deler av varene eller tjenestene som tilbys. Disse skal følge klart av det konkrete tilbud. Hvis det er en konflikt mellom disse vilkårene og tilleggsvilkårene som er lagt ut for en spesifikk vare eller tjeneste, vil tilleggsvilkårene ha forrang med hensyn til den konkrete varen eller tjenesten, såfremt disse er mer fordelaktig for kunden. Tjenestetilbyder er ansvarlig for at slike tilleggsvilkår ikke begrenser kundenes rettigheter etter gjeldende lover og øvrige regelverk.        </div>
    </li>
    <li>
        <h4 class="sub-title">
            5. REGISTRERING
         </h4>
         <div class="paragraph">
            <ul>
                <li>
                    <h4 class="sub-title">
                      5.1 Generelt
                    </h4>
                    <div class="paragraph">
                        Ved registrering som bruker på RS Sjøliv må du oppgi personlig informasjon som Redningsselskapet anser nødvendig for din bruk av plattformen. 
                        <br><br><br>
                        Du er ansvarlig for å oppdatere informasjonen i kontoen din slik at informasjonen til enhver tid er nøyaktig og fullstendig. Unnlatelse av å oppgi korrekt og tilstrekkelig informasjon kan føre til at Redningsselskapet nekter din tilgang til plattformen. 
                        <br><br>
                        Med mindre annet er tillatt av Redningsselskapet skriftlig, kan du bare ha én konto. Du kan ikke på noen måte overføre kontoen din til noen annen person eller enhet.
                        <br><br>
                        Du er ansvarlig for alle handlinger som blir utført gjennom kontoen din, uavhengig av om slike handlinger blir utført av deg eller noen andre, og uansett om de blir utført med eller uten ditt samtykke. Du samtykker i å ikke gjøre brukernavnet og passordet ditt tilgjengelig for andre enn deg selv og ta nødvendige forholdsregler for å unngå uautorisert tilgang til kontoen din.
                        <br><br>
                        Ved å registrere deg som bruker i RS Sjøliv, blir du også registrert i Redningsselskapets kundedatabase og vil kunne motta tilbud fra Redningsselskapet om andre varer og tjenester.

                    </div>
                </li>
                <li>
                    <h4 class="sub-title">
                      5.2 Særlig for tjenestetilbydere
                    </h4>
                    <div class="paragraph">
                        Godkjente tjenestetilbydere vil få tilgang til plattformen, hvor tjenestetilbyderen kan publisere varer og tjenester.
                        <br><br><br>
                        Det er den tjenestetilbyder som er oppført som instruktør for kurset som skal avholde dette, med mindre det foreligger en særlig grunn og god og saklig årsak til endring. Dette skal meldes inn av tjenestetilbyder til 
                        <a target="_blank" href="mailto:post@rs.no">post@rs.no</a>
                        før kurset avholdes, slik at RS Sjøliv kan endre nødvendige opplysninger.
                    </div>
                </li>
            </ul>
        </div>
    </li>
    <li>
        <h4 class="sub-title">
            6. BESTILLING
         </h4>
         <div class="paragraph">
            Når kunder ber om en bestilling, inngås en avtale mellom tjenestetilbyderen og kunden på det tidspunktet da kunden mottar en bekreftelse. 
            <br><br><br>
            Tjenestetilbyder setter selv avbestillingsfrist for den oppførte vare eller tjeneste.  Ved kurspåmelding er påmeldingen bindende for kunden etter at avmeldingsfristen er passert.
        </div>
    </li>
    <li>
        <h4 class="sub-title">
            7. ENDRING, AVBESTILLING OG KANSELLERING
         </h4>
         <div class="paragraph">
            Endring eller avbestilling av tjenestetilbyderens tjenester eller varer som tilbys på plattformen gjøres i henhold til avtalen mellom tjenestetilbyder og kunde. Endring eller avbestilling fra tjenestetilbyder gjøres via plattformen. Tjenestetilbyder skal sikre at alle kunder blir tilstrekkelig informert. Endring eller avbestilling fra kunde gjøres direkte med tjenestetilbyder. 
            <br><br><br>
            Ved kansellering av egne kurs skal instruktør selv utføre dette på sin administrasjonsside på plattformen og i tillegg gi beskjed til 
            <a  target="_blank" href="mailto:post@rs.no">post@rs.no</a>
            Ved kansellering av egne kurs er instruktør selv ansvarlig for å sikre at kunder mottar nødvendig informasjon om dette. 
            <br><br>
            Kansellering og endring av kurs skal så langt som mulig unngås og må kunne saklig begrunnes. Redningsselskapet forventer at de kurs som tilbys gjennomføres. Tjenestetilbyder skal gjennomføre alle kurs som er oppsatt på RS Sjøliv, med mindre en god og saklig årsak ligger til grunn. Årsak for kansellering må kunne dokumenteres til Redningsselskapet. 
            <br><br>
            Såfremt ikke annet er avtalt mellom tjenestetilbyder og kunden gjelder følgende:
            <br><br>
            <ul class="terms-conditions-list">
              <li>
                dersom kunde initierer endring eller avbestilling etter fristen vil kunden kunne bli belastet for hele beløpet. 
               </li>
               <li>
                disse vilkår innebærer ingen innskrenkninger i de regler som følger av angrerettloven for forbrukere. Tjenestetilbydere har ansvaret for at kunder er informert om gjeldende regler for angrerett for den tilbudte vare og/eller tjeneste.
               </li>
            </ul>
            Omplanlegging må avtales direkte mellom tjenestetilbyderen og kunden. Instruktør kan flytte kunde til annet kurs som tjenestetilbyder avholder dersom kunde ønsker/aksepterer dette. Tjenestetilbyder utfører selv slik omplanlegging via administrasjonssiden på plattformen. 
        </div>
    </li>
    <li>
        <h4 class="sub-title">
            8. BETALING
         </h4>
         <div class="paragraph">
            Som kunde forstår og godtar du at bruk av plattformen kan føre til gebyrer for deg og for varene eller tjenestene du mottar fra en tjenestetilbyder.
            <br><br><br>
            Før en bestilling finner sted, skal tjenestetilbyderen informere kunden om totalprisen for varen og/eller tjenesten, inkludert evt. moms (merverdiavgift). 
            <br><br>
            RS Sjøliv benytter seg av Stripe som betalingsløsning. Stripe sine egne vilkår må aksepteres før betalingstjenesten tas i bruk. Transaksjoner via Stripe går direkte fra kunde til tjenestetilbyder. Tjenestetilbyder er ansvarlig for å dekke enhver avgift som pålegges av Stripe i forbindelse med betalingsløsningen. Redningsselskapet påtar seg ingen forpliktelser eller ansvar i tilknytning til betalingsformidlingen.
            <br><br>
            Utbetaling til instruktører skjer normalt 5-12 virkedager etter avholdt kurs. 
            <br><br>
            Instruktører vil motta en årlig økonomisk oversikt som viser deres aktivitet og utbetaling via RS Sjøliv. 
            <br><br>
            Tilbakebetaling til kunde som følge av kurs som kanselleres etter dato for avholdelse av kurset, skal håndteres direkte mellom instruktør og kunde. Som følge av dette vil det kunne forekomme avvik i den økonomiske oversikten fra RS Sjøliv.
        </div>
    </li>
    <li>
        <h4 class="sub-title">
            9. PERSONVERN
         </h4>
         <div class="paragraph">
            Personvern og beskyttelsen av personopplysninger er veldig viktig for Redningsselskapet. I forbindelse med bruk av plattformen, trenger Redningsselskapet å behandle visse personopplysninger om kunder og tjenestetilbydere.
            <br><br><br>
            Redningsselskapet behandler brukere sine personopplysninger på RS Sjøliv i henhold til <a target="_blank" href="https://rs.no/personvern/">Redningsselskapets personvernerklæring.</a>
            <br><br>
            Tjenestetilbydere og juridiske enheter har ansvar for å behandle all informasjon de får tilgang til via RS Sjøliv i tråd med gjeldende regler om personvern. Tjenestetilbyder og Redningsselskapet er felles behandlingsansvarlig for de personopplysningene som Redningsselskapet/brukere tilgjengeliggjør til tjenestetilbyder via plattformen. Redningsselskapet og tjenestetilbyderens felles behandlingsansvar er regulert av tillegget om felles behandlingsansvar. 
        </div>
    </li>
    <li>
        <h4 class="sub-title">
            10. ANSVARSBEGRENSNING
         </h4>
         <div class="paragraph">
            Din bruk av plattformen, dens innhold og eventuelle varer eller tjenester er på eget ansvar og egen risiko. Plattformen, dens innhold og eventuelle varer eller tjenester tilbudt og/eller mottatt gjennom plattformen leveres i tråd med disse vilkår og avtalen mellom kunde og tjenestetilbyder «som den er» og «som tilgjengelig», uten noen garanti fra RS Sjøliv eller Redningsselskapet, uttrykkelig eller implisitt.
            <br><br><br>
            Tjenestetilbyder er ansvarlig for innholdet, kvaliteten og servicenivået som tilbys på RS Sjøliv. Redningsselskapet påtar seg ikke noe ansvar med hensyn til oppføringer, levering og kommunikasjon mellom brukere og tjenestetilbydere. Redningsselskapet kan ikke holdes ansvarlig for noen form for tap, skade eller kostnad som tjenestetilbyderen måtte bli påført som følge av sin bruk av plattformen, inkludert som følge av oppdrag formidlet gjennom plattformen, feil ved plattformen eller at plattformen helt eller delvis er utilgjengelig, permanent eller midlertidig utestengelse fra plattformen. 
            <br><br>
            Dersom Redningsselskapet likevel skulle bli holdt ansvarlig for tap, skade eller kostnader som tjenestetilbyderen har lidt, er ansvaret begrenset til direkte og dokumentert økonomisk tap, og oppad til vederlaget brukeren har betalt Redningsselskapet for bruk av plattformen den siste måneden. Ansvarsbegrensningen er kumulativ per en måneders periode, og ikke per skadetilfelle. 
            <br><br>
            Tjenestetilbyder bekrefter ved registrering at vedkommende innehar den nødvendige representasjonsrett til å tilby varer eller tjenester på vegne av den juridiske enheten. Redningsselskapet påtar seg ikke noe ansvar for å bekrefte at tjenestetilbyder innehar den nødvendige representasjonsrett. 
            <br><br>
            Redningsselskapet og dets datterselskaper, tilknyttede selskaper, ledere, ansatte, agenter og partnere holdes skadesløse for eventuelle krav, tap, forpliktelser og utgifter, inkludert advokatsalær, gjort av tredjepart på grunn av eller som følge av brudd på disse vilkårene, eller brudd på lov eller tredjeparts rettigheter. Ingenting i disse vilkårene skal anses å utelukke eller begrense ditt ansvar som bruker med hensyn til forpliktende erstatning i henhold til disse vilkårene.
        </div>
    </li>
    <li>
        <h4 class="sub-title">
            11. ENDRINGER
         </h4>
         <div class="paragraph">
            Redningsselskapet kan endre vilkårene uten å varsle brukere. Ved vesentlige endringer vil Redningsselskapet imidlertid gi en måned varsel før endringene trer i kraft. De til enhver tid oppdaterte vilkårene ligger tilgjengelig for brukere på «min side» på RS Sjøliv, under «vilkår og betingelser». 
            <br><br><br>
            Redningsselskapet forbeholder seg også retten til å endre funksjonalitet, utseende, innhold og inndelingen på plattformen uten varsel. 
        </div>
    </li>
    <li>
        <h4 class="sub-title">
            12. ANNET
         </h4>
         <div class="paragraph">
            Redningsselskapet kan overdra avtalen med tjenestetilbyderen uten å innhente tjenestetilbyderens forutgående samtykke. Tjenestetilbyderen kan ikke overdra avtalen med Redningsselskapet uten Redningsselskapets forutgående skriftlige samtykke. 
            <br><br>
            Vilkårene er underlagt norsk rett. Eventuelle tvister som ikke løses i minnelighet, skal avgjøres ved de alminnelige domstolene i Norge.  
            <br><br>
            <strong>Tillegg - avtale om felles behandlingsansvar</strong>
            <br><br>
            Denne avtalen om felles behandlingsansvar utgjør et tillegg til vilkårene for bruk av plattformen RS Sjøliv for tjenestetilbydere. Definisjoner som er brukt i vilkårene for bruk av plattformen RS Sjøliv for tjenestetilbydere gjelder tilsvarende for dette tillegget. Ved motstrid mellom vilkårene og dette tillegget, skal dette tillegget gis forrang. 
            <br><br>
            Redningsselskapet og tjenestetilbyderen er enig om:
            <ul class="terms-conditions-list">
                <li>
                    Redningsselskapet og tjenestetilbyderen er felles behandlingsansvarlig etter GDPR artikkel 26 for den felles behandlingen som er spesifisert i vilkårene. Det felles behandlingsansvaret og dette tillegget gjelder for innsamlingen av personopplysningene til brukere gjennom plattformen, og overføringen av disse til/fra Redningsselskapet. Redningsselskapets og tjenestetilbyderens etterfølgende behandling av disse personopplysningene er ikke omfattet av den felles behandlingen. 
                </li>
                <li>
                    Redningsselskapet og tjenestetilbyderens felles behandling reguleres av dette tillegget som fastsetter Redningsselskapet og tjenestetilbyderens ansvar for overholdelse av forpliktelsene i GDPR når det gjelder den felles behandlingen. Tillegget gjelder for alle aktiviteter som partene, partenes ansatte og partenes databehandlere er involvert i når det kommer til den felles behandlingen. 
                </li>
                <li>
                    Ansvaret for overholdelsen av forpliktelsene i GDPR når det gjelder Redningsselskapet og tjenestetilbyders felles behandling, er fastsatt som følger: 
                </li>
                <div class="container mt-4">
                    <table class="table table-cell">
                        <thead>
                            <tr>
                                <th scope="col" class="border width-10 text-center align-top">Nr.</th>
                                <th scope="col" class="border width-22 text-center align-top">Forpliktelser etter GDPR</th>
                                <th scope="col" class="border width-27 text-center align-top">Redningsselskapet</th>
                                <th scope="col" class="border width-41 text-center align-top">Tjenestetilbyder</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td class="border align-top">1</td>
                                <td class="border align-top">GDPR artikkel 6.<br><br>Krav om behandlingsgrunnlag for den felles behandlingen</td>
                                <td class="border align-top"><p class="text-center">X</p><br>(angående Redningsselskapets behandling av personopplysninger)</td>
                                <td class="border align-top"><p class="text-center">X</p><br>(angående tjenestetilbyders behandling av personopplysninger)</td>
                            </tr>
                            <tr>
                                <td class="border align-top">2</td>
                                <td class="border align-top">GDPR artikkel 13 og 14.<br><br>Informere om felles behandling av personopplysninger.</td>
                                <td class="border align-top"></td>
                                <td class="border align-top"><p class="text-center">X</p><br>Tjenestetilbyderen skal som et minimum gi følgende informasjon i tillegg til det som fremgår av tjenestetilbyders personvernerklæring eller liknende dokument:
                                <br><br>
                                <ul class="terms-conditions-list">
                                    <li>
                                        At Redningsselskapet er felles behandlingsansvarlig i forbindelse med den felles behandlingen, og at informasjonen som kreves etter GDPR artikkel 13 nr. 1 (a) og (b) finnes i 
                                        <a  target="_blank" href="https://rs.no/personvern/">Redningsselskapets personvernerklæring.</a>
                                    </li>
                                     <li>
                                        Informasjon om at tjenestetilbyderen bruker plattformen, formålene ved innsamlingen og overføringen av personopplysninger som utgjør det felles behandlingsansvaret.
                                    </li>
                                    <li>
                                        At ytterligere informasjon om hvordan Redningsselskapet behandler personopplysninger, behandlingsgrunnlaget Redningsselskapet baserer seg på og hvordan de registrerte kan utøve sine rettigheter mot Redningsselskapet finnes i 
                                        <a  target="_blank" href="https://rs.no/personvern/">Redningsselskapets personvernerklæring.</a>
                                    </li>
                                  </ul>
                                </td>
                            </tr>
                            <tr>
                                <td class="border px-2 py-2 align-top">3</td>
                                <td class="border py-2 align-top">GDPR artikkel 26 nr. 2.<br><br>Gjøre det vesentlige innholdet i ordningen om det felles behandlingsansvaret etter denne avtalen tilgjengelig.</td>
                                <td class="border py-2 align-top"></td>
                                <td class="border py-2 align-top"><p class="text-center">X</p><br>Tjenestetilbyderen skal som et minimum gi følgende informasjon:
                                    <br><br>
                                    <ul class="terms-conditions-list">
                                        <li>
                                            At tjenestetilbyderen og Redningsselskapet har inngått dette tillegget for å fastsette ansvaret for overholdelsen av forpliktelsene i GDPR når det gjelder den felles behandlingen.
                                        </li>
                                         <li>
                                            At tjenestetilbyderen og Redningsselskapet har avtalt at tjenestetilbyderen som et minimum er ansvarlig for å gi de registrerte den informasjonen som er angitt under pkt. 2.
                                        </li>
                                        <li>
                                            At tjenestetilbyderen og Redningsselskapet har avtalt at Redningsselskapet er ansvarlig for å sette den registrerte i stand til å utøve sine rettigheter etter GDPR artikkel 15-20 når det gjelder personopplysningene som er lagret av Redningsselskapet etter den felles behandlingen.
                                        </li>
                                      </ul>
                                </td>
                            </tr>
                            <tr>
                                <td class="border px-2 py-2 align-top">4</td>
                                <td class="border py-2 align-top">GDPR artikkel 15-20.<br><br>Rettighetene til den registrerte når det gjelder personopplysninger som lagres av Redningsselskapet etter den felles behandlingen.</td>
                                <td class="border py-2 align-top"><p class="text-center">X</p><br></td>
                                <td class="border py-2 align-top"></td>
                            </tr>
                            <tr>
                                <td class="border px-2 py-2 align-top">5</td>
                                <td class="border py-2 align-top">GDPR artikkel 21.<br><br>Rett til å protestere når felles behandling er basert på GDPR artikkel 6 nr. 1(e)</td>
                                <td class="border py-2 align-top"><p class="text-center">X</p><br>(angående Redningsselskapets behandling av personopplysninger)</td>
                                <td class="border py-2 align-top"><p class="text-center">X</p><br>(angående tjenestetilbyderens behandling av personopplysninger)</td>
                            </tr>
                            <tr>
                                <td class="border px-2 py-2 align-top">6</td>
                                <td class="border py-2 align-top">GDPR artikkel 32.<br><br>Sikkerhet ved felles behandlingsansvar</td>
                                <td class="border py-2 align-top"><p class="text-center">X</p><br>(angående sikkerhet ved plattformen)<br><br>Alle ansatte i Redningsselskapet som er involvert i den felles behandlingen er underlagt konfidensialitetsforpliktelser med hensyn til behandlingen av personopplysninger.</td>
                                <td class="border py-2 align-top"><p class="text-center">X</p><br>(angående bruken av plattformen og sikkerhet på kommunikasjonskanaler)</td>
                            </tr>
                            <tr>
                                <td class="border px-2 py-2 align-top">7</td>
                                <td class="border py-2 align-top">GDPR artikkel 33 og 34.<br><br>Brudd på person-opplysnings-sikkerheten ved felles behandlings-ansvar</td>
                                <td class="border py-2 align-top"><p class="text-center">X</p><br>(i den utstrekning brudd på personopplysningssikkerheten gjelder Redningsselskapets forpliktelser etter dette tillegget)</td>
                                <td class="border py-2 align-top"><p class="text-center">X</p><br>(i den utstrekning brudd på personopplysningssikkerheten gjelder tjenestetilbyders forpliktelser etter dette tillegget)</td>
                            </tr>
                            <tr>
                                <td class="border px-2 py-2 align-top">8</td>
                                <td class="border py-2 align-top">Øvrige forpliktelser etter GDPR i forbindelse med den felles behandlingen</td>
                                <td class="border py-2 align-top"><p class="text-center">X</p><br></td>
                                <td class="border py-2 align-top"><p class="text-center">X</p><br></td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <br>
                <li>
                    De registrerte kan utøve sine rettigheter direkte overfor Redningsselskapet når det gjelder personopplysningene som behandles av Redningsselskapet. Tjenestetilbyderen skal straks, og senest innen syv dager, videresende all relevant informasjon knyttet til forespørsler fra registrerte knyttet til utøvelse av rettigheter eller fra tilsynsmyndigheter til Redningsselskapet gjennom kontaktinformasjonen nedenfor. Tjenestetilbyderen skal samarbeide med Redningsselskapet om å besvare slike forespørsler rettidig. Tjenestetilbyderen har ikke rett til å svare på vegne av Redningsselskapet. 
                </li>
                <li>
                    Tillegget er underlagt norsk rett, og tvister som ikke løses i minnelighet, skal avgjøres ved de alminnelige domstoler i Norge. 
                </li>
                <li>
                    Redningsselskapet kan oppdatere tillegget fra tid til annen. Redningsselskapet vil informere deg om slik endringer. Ved å bruke plattformen godtar du å være bundet av endringene. Dersom du ikke godtar endringene må du avslutte bruken av plattformen. Eventuelle endringer eller unntak fra dette tillegget skal skriftlig godkjennes av Redningsselskapet.  
                </li>
                <li>
                    Dersom deler av dette tillegget er ugyldig eller ikke kan håndheves, skal ikke dette få betydning for gyldigheten av de øvrige delene av tillegget. 
                </li>
            </ul>
        </div>
    </li>
</ul>

<br>

<div class="conditions-item">
  <strong>
    Redningsselskapets kontaktinformasjon:
  </strong>
  <br><br>
  <p class="mb-2">Redningsselskapets kundesenter:</p>
<ul class="terms-conditions-list">
    <li class="mb-0"> E-post:
        <a target="_blank" href="mailto:post@rs.no">
            post@rs.no
        </a>
    </li>
    <li> Tel: 987 06 757</li>
</ul>
</div>

</div>
</div>