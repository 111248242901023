import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { Route, RouterModule } from '@angular/router';
import { AboutComponent } from './components/about/about.component';
import { InstructorTermsComponent } from './components/instructor-terms/instructor-terms.component';
import { ModalComponent } from './components/modal/modal.component';
import { PrivacyPolicyComponent } from './components/privacy-policy/privacy-policy.component';
import { ReferralTermsAndConditionComponent } from './components/referral-terms-and-condition/referral-terms-and-condition.component';
import { PrivacyModalService } from './components/services/modal.service';
import { TermsAndConditionComponent } from './components/terms-and-condition/terms-and-condition.component';
import { MaterialModule } from '@shared/material/material';
import { FlexModule } from "@angular/flex-layout";
import { TranslocoModule } from '@ngneat/transloco';

const routes: Route[] = [
  {
    path: 'privacy-policy',
    component: PrivacyPolicyComponent,
  },
  {
    path: 'terms-and-condition',
    component: TermsAndConditionComponent,
  },
  {
    path: 'about',
    component: AboutComponent,
  }
];

@NgModule({
  declarations: [
    PrivacyPolicyComponent,
    TermsAndConditionComponent,
    InstructorTermsComponent,
    ReferralTermsAndConditionComponent,
    ModalComponent,
    AboutComponent  
  ],
  exports: [
    PrivacyPolicyComponent,
    TermsAndConditionComponent,
    // RouterModule
  ],
    imports: [
        // RouterModule.forChild(routes),
        MaterialModule,
        CommonModule,
        FlexModule,
        RouterModule,
        TranslocoModule,
    ],
  providers: [
    PrivacyModalService,
  ],
  entryComponents: [ModalComponent],
})
export class PrivacyPolicyModule {}
