import { Component, OnInit, OnDestroy, OnChanges, ChangeDetectorRef, ChangeDetectionStrategy } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { Router } from '@angular/router';
import { UserProfile } from '@modules/identity/models/user';
import { IdentityStoreService } from '@modules/identity/store/services/identity-store.service';

@Component({
  selector: 'bottom-menu',
  templateUrl: './bottom-menu.component.html',
  styleUrls: ['./bottom-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BottomMenuComponent implements OnInit, OnDestroy, OnChanges {
  userProfile$: Observable<UserProfile>;
  private destroy$: Subject<any>;

  constructor(private router: Router, private cdr: ChangeDetectorRef, private identityStore: IdentityStoreService) {
    this.destroy$ = new Subject<any>();
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };
  }

  ngOnInit() {
    this.userProfile$ = this.identityStore.getUserProfile();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  ngOnChanges(): void {
    this.cdr.reattach();

    setTimeout(() => {
      this.cdr.detach();
    });
  }
}
