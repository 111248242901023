import { Component, OnInit, ViewEncapsulation, Input, Output, EventEmitter } from '@angular/core';
import { addMonths, format, subMonths } from 'date-fns';

@Component({
  selector: 'app-date-filter',
  templateUrl: './date-filter.component.html',
  styleUrls: ['./date-filter.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DateFilterComponent implements OnInit {
  firstCalendarInitial: any = null;
  secondCalendarInitial: any = null;

  @Input() public fromDate: any = null;
  @Input() public toDate: any = null;

  @Output() public save: EventEmitter<any> = new EventEmitter();
  @Output() public clear: EventEmitter<any> = new EventEmitter<any>();

  constructor() {
    this.firstCalendarInitial = format(new Date(), 'yyyy-MM-dd');
    this.secondCalendarInitial = format(addMonths(new Date(), 1), 'yyyy-MM-dd');
  }

  ngOnInit() {}

  public changeFromMonth(date: any): void {
    this.secondCalendarInitial = format(addMonths(date, 1), 'yyyy-MM-dd');
  }

  public changeToMonth(date: any): void {
    this.firstCalendarInitial = format(subMonths(date, 1), 'yyyy-MM-dd');
  }

  public saveDate(): void {
    this.save.emit({
      from: this.fromDate,
      to: this.toDate,
    });
  }

  public clearAll(): void {
    this.clear.emit({
      from: this.fromDate,
      to: this.toDate,
    });
  }
}
