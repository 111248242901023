import { createFeatureSelector, createSelector } from '@ngrx/store';
import { AppState } from '@core/store/app.state';

export const appFeatureKey = 'app';

export const getShareState = createFeatureSelector<AppState>(appFeatureKey);

export const getError = createSelector(getShareState, (state) => state.error);

export const getDropdownResult = createSelector(getShareState, (state) => state.isDropdownShow);

export const getColorChangeResult = createSelector(getShareState, (state) => state.isColorNotChange);

export const getRedirectPermission = createSelector(getShareState, (state) => state.stopDropdownRedirect);

export const getHomePageFlag = createSelector(getShareState, (state) => state.isHomePage);

export const getShareGeolocation = createSelector(getShareState, (state) => state.shareGeolocation);

export const getCurrentLocation = createSelector(getShareState, (state) => state.currentLocation);

export const getInfoPopupShown = createSelector(getShareState, (state) => state.infoPopupShown);

export const getSelectMessagePost = createSelector(getShareState, (state) => state.selectMessagePost);
