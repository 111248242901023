import { NgxUiLoaderConfig, PB_DIRECTION, POSITION, SPINNER } from 'ngx-ui-loader';

export const ngxUiLoaderConfig: NgxUiLoaderConfig = {
  bgsColor: 'red',
  bgsOpacity: 0.5,
  bgsPosition: POSITION.bottomCenter,
  bgsSize: 60,
  delay: 0,
  bgsType: SPINNER.rectangleBounce, // background spinner type
  fgsType: SPINNER.rectangleBounce, // foreground spinner type
  fgsPosition: POSITION.centerCenter,
  fgsSize: 100,
  fgsColor: '#475294',
  gap: 24,
  blur: 15,
  pbDirection: PB_DIRECTION.leftToRight, // progress bar direction
  masterLoaderId: 'master',
  overlayBorderRadius: '0',
  overlayColor: 'rgba(72,77,75,0.17)',
  pbColor: 'red',
  pbThickness: 3,
  hasProgressBar: true,
  maxTime: -1,
  minTime: 500,
};
