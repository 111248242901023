import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewEncapsulation
} from '@angular/core';
import { CourseCardSize } from '@modules/core/models/course-card-size';
import { ConfigService } from '@modules/core/services/config.service';
import { ClassroomCourse } from '@modules/course/classroom/models';
import { DigitalCourse } from '@modules/course/digitalcourse/models';
import { CourseCardType } from '@modules/shared/enums/course-card-type';
import { Multimedia } from '@modules/shared/models/multimedia';
import { DEFAULT_CLASSROOM_MEDIA_URL, DEFAULT_DIGITAL_MEDIA_URL } from '@shared/constants/default-media';
import { CategoryRef } from '@shared/models/category-ref';
import { getFormattedPrice } from '@core/utils/string.utils';

@Component({
  selector: 'app-course-preview-card',
  templateUrl: './course-preview-card.component.html',
  styleUrls: ['./course-preview-card.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CoursePreviewCardComponent implements OnInit, OnChanges {
  @Input() size: CourseCardSize = 'medium';
  @Input() courseCardType: CourseCardType = CourseCardType.CLASSROOM_COURSE;
  @Input() course: ClassroomCourse | DigitalCourse = null;
  courseImageUrl: string = '';
  courseLocation: string = '';
  providerImageUrl: string = '';
  providerName: string = '';
  baseRoute: string = '/kurs';
  courseSlug: string = '';
  memberPrice: number = 0;
  nonMemberPrice: number = 0;
  courseCategoryName: string = '';
  isPriceHidden: boolean = false;
  isCourseFree: boolean = false;
  readonly courseCardTypes = CourseCardType;
  private readonly baseUrl: string;


  constructor(private config: ConfigService) {
    this.baseUrl = this.getBaseCdnUrl();
  }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.course?.currentValue && this.courseCardType == CourseCardType.CLASSROOM_COURSE) {
      const {
        media = [],
        pricePerMember,
        pricePerStudent,
        regions,
        categories = [],
        isWebinar,
        slug,
        instructors: [{name: instructorName, imageUrl: instructorImageUrl}]
      } = changes.course.currentValue as ClassroomCourse;
      this.baseRoute = '/kurs';
      this.courseImageUrl = this.getDefaultImage(media);
      this.providerName = instructorName;
      this.providerImageUrl = `${this.baseUrl}${instructorImageUrl}`;
      this.courseLocation = isWebinar ? 'Webinar' : regions?.slice(0, 1)?.pop()?.name || '';
      this.nonMemberPrice = pricePerStudent;
      this.memberPrice = pricePerMember;
      this.courseSlug = slug;
      this.courseCategoryName = this.getCategoriesName(categories);
    } else if (changes?.course?.currentValue && this.courseCardType == CourseCardType.DIGITAL_COURSE) {
      const {
        medias = [],
        price: {pricePerPerson, memberPrice, isPriceHidden},
        categories = [],
        arranger = 'Redningsselskapet',
        arrangerMedia = [],
        courseSlug
      } = changes.course.currentValue as DigitalCourse;
      this.baseRoute = '/digitalkurs';
      this.courseImageUrl = this.getDefaultImage(medias);
      this.providerName = arranger || 'Redningsselskapet';
      this.providerImageUrl = this.getDefaultImage(arrangerMedia);
      this.courseLocation = '';
      this.courseSlug = courseSlug;
      this.nonMemberPrice = pricePerPerson;
      this.memberPrice = memberPrice;
      this.isPriceHidden = isPriceHidden;
      this.isCourseFree = Boolean(pricePerPerson === 0 || pricePerPerson === undefined || pricePerPerson === null);
      this.courseCategoryName = this.getCategoriesName(categories);
    }
  }

  private getDefaultImage(mediaList: Multimedia[]): string {
    const placeHolderImage = this.courseCardType === this.courseCardTypes.CLASSROOM_COURSE ? DEFAULT_CLASSROOM_MEDIA_URL : DEFAULT_DIGITAL_MEDIA_URL
    if (mediaList.length > 0) {
      return mediaList.find((media) => (media.default === true))?.url || placeHolderImage;
    }
    return placeHolderImage;
  }

  private getBaseCdnUrl(): string {
    const env = this.config.getCurrent().env;
    switch (env) {
      case 'production':
        return 'https://rssjoliv-prod.imgix.net/';

      case 'staging':
        return 'https://rssjoliv-staging.imgix.net/';

      default:
        return 'https://rssjoliv-dev.imgix.net/';
    }
  }

  private getCategoriesName(categories: CategoryRef[]): string {
    if (categories?.length > 0) {
      const categoryName = categories.slice(0, 1)?.pop().title;
      return ((categories.length === 1) ? categoryName : `${categoryName} +${categories.length - 1}`);
    } else if (this.courseCardType === this.courseCardTypes.CLASSROOM_COURSE) {
      return 'Kurs';
    } else if (this.courseCardType === this.courseCardTypes.DIGITAL_COURSE) {
      return 'Nettkurs';
    }
    return '';
  }

  readonly getFormattedPrice = getFormattedPrice;

  isClassroomCourse(courseType: CourseCardType) {
    return courseType === CourseCardType.CLASSROOM_COURSE;
  }
}

