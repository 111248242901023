<div class="map-card my-5">
  <div class="google-map">
    <div class="map match-parent" id="classroomMap" ></div>
  </div>
  <div class="travel-direction">
  <div class="row no-gutters align-items-center">
    <div class="col-sm-12 col-md-5  mb-2 mb-md-0">
      <div class="travel-mode text-left p-1">
        <button [class.active]="routingProfile === 'walking'" (click)="setTravelMode(RoutingProfile.Walking)" mat-mini-fab>
          <mat-icon>directions_walk</mat-icon>
        </button>
        <button [class.active]="routingProfile === 'cycling'" (click)="setTravelMode(RoutingProfile.Cycling)" mat-mini-fab>
          <mat-icon>directions_bike</mat-icon>
        </button>
        <button [class.active]="routingProfile === 'driving'" (click)="setTravelMode(RoutingProfile.Driving)" mat-mini-fab>
          <mat-icon>directions_car</mat-icon>
        </button>
      </div>
    </div>
    <div class="col-sm-12 col-md-7">
      <div class="travel-content text-right p-1">
        <span class="distance" *ngIf="distance || duration">{{routingProfile | uppercase}} - {{distance}} meter ({{duration}} min)</span>
      </div>
    </div>
  </div>
  </div>
</div>
