import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DialogComponent } from './prompt/dialog/dialog.component';
import { PromptDialogService } from './prompt/prompt.service';
import { FlexLayoutModule } from '@angular/flex-layout';

import { MaterialModule } from '@shared/material/material';
import { TranslocoModule } from '@ngneat/transloco';

@NgModule({
  imports: [CommonModule, MaterialModule, FlexLayoutModule, TranslocoModule],
  declarations: [DialogComponent],
  providers: [PromptDialogService],
  exports: [],
  entryComponents: [DialogComponent],
})
export class ModalModule {}
