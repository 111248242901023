import { CommonModule } from '@angular/common';
import { Injectable, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgSelectModule } from '@ng-select/ng-select';
import { TranslocoModule } from '@ngneat/transloco';
import { MaterialModule } from '@shared/material/material';
import { ngfModule } from 'angular-file';
import { AngularIbanModule } from 'angular-iban';
import { FlatpickrModule } from 'angularx-flatpickr';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { SimplebarAngularModule } from 'simplebar-angular';
import { SHARED_COMPONENTS } from './components';
import { SHARED_DIRECTIVES } from './directives';
import { SHARED_GUARDS } from './guards';
import { CalendarModule } from './library/calendar/calendar.module';
import { ImageUplaoderModule } from './library/image-preview/image.preview.module';
import { MultipleDayPickerModule } from './library/multiple-day-picker/multiple-day-picker.module';
import { NgxRatingModule } from './library/star-rank/ngx-rating.module';
import { ModalModule } from './modals/modal.module';
import { SHARED_PIPES } from './pipes';
import { SHARED_RESOLVERS } from './resolvers';
import { SHARED_SERVICES } from './services';
import { SharedStoreModule } from './store/shared-store.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ResizeObserverModule } from '@ng-web-apis/resize-observer';
import { SwiperModule } from 'swiper/angular';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import * as Hammer from '@squadette/hammerjs';
import { HammerModule, HammerGestureConfig, HAMMER_GESTURE_CONFIG} from '@angular/platform-browser';

@Injectable()
export class HammerConfig extends HammerGestureConfig {
  overrides = <any> {
    swipe: { direction: Hammer.DIRECTION_ALL },
  };
}

const SHARED_MODULES = [
  CommonModule,
  RouterModule,
  FormsModule,
  ReactiveFormsModule,
  SharedStoreModule,
  TranslocoModule,
  SlickCarouselModule,
  AngularIbanModule,
  CalendarModule,
  MultipleDayPickerModule,
  ImageUplaoderModule,
  NgxRatingModule,
  ModalModule,
  MaterialModule,
  FlexLayoutModule,
  SimplebarAngularModule,
  ngfModule,
  FlatpickrModule,
  NgSelectModule,
  ResizeObserverModule,
  SwiperModule,
  NgxMaterialTimepickerModule,
  MatTooltipModule,
  MatBottomSheetModule,
  HammerModule
];

@NgModule({
  declarations: [...SHARED_COMPONENTS, ...SHARED_DIRECTIVES, ...SHARED_PIPES],
  imports: [...SHARED_MODULES],
  providers: [
    ...SHARED_SERVICES,
    ...SHARED_RESOLVERS,
    ...SHARED_GUARDS,
    {
      provide: HAMMER_GESTURE_CONFIG,
      useClass: HammerConfig,
    },
  ],
  exports: [...SHARED_MODULES, ...SHARED_COMPONENTS, ...SHARED_DIRECTIVES, ...SHARED_PIPES],
})
export class SharedModule {}
