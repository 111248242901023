<div class="dialog-content-wrapper">
  <mat-toolbar matDialogTitle class="theme-color m-0">
    <mat-toolbar-row class="d-flex justify-content-between">
      <span class="title dialog-title">{{ _data.title }}</span>
      <button
        (click)="matDialogRef.close(false)"
        mat-icon-button
        aria-label="Close dialog"
      >
        <i class="fa fa-times" aria-hidden="true"></i>
      </button>
    </mat-toolbar-row>
    <!-- <mat-toolbar-row class="toolbar-bottom py-16 d-flex justify-content-center">
            <div class="search-wrapper search-margin">
                <div class="search" fxFlex fxLayout="row" fxLayoutAlign="start center">
                    <input #search placeholder="Search">
                </div>
            </div>
        </mat-toolbar-row> -->
  </mat-toolbar>

  <div mat-dialog-content class="pb-0 m-0">
    <ng-container *ngIf="_data.type === 'privacy'">
      <app-privacy-policy></app-privacy-policy>
    </ng-container>
    <ng-container *ngIf="_data.type === 'terms'">
      <app-terms-and-condition></app-terms-and-condition>
    </ng-container>
    <ng-container *ngIf="_data.type === 'instructor-terms'">
      <app-instructor-terms></app-instructor-terms>
    </ng-container>
  </div>

  <div
    mat-dialog-actions
    style="padding-right: 10px;"
    class="m-0 p-16 d-flex flex-row-reverse"
  >
    <button
      (click)="matDialogRef.close(true)"
      mat-button
      class="save-button"
      aria-label="SAVE"
    >
      {{ _data.confirm ? _data.confirm : "Ok" }}
    </button>
  </div>
</div>
