import { state } from '@angular/animations';
import { createSelector, createFeatureSelector } from '@ngrx/store';
import { adapter } from './users-location.entity';
import { UserLocationState } from './users-location.state';
import { UserLocation } from '@shared/models/user-location';

export const { selectAll, selectEntities, selectIds, selectTotal } = adapter.getSelectors();

export const getUserLocationState = createFeatureSelector<UserLocationState>('userLocations');

export const getAllUserLocations = createSelector(getUserLocationState, selectAll);

export const getUserLocationById = (id: string) => createSelector(getUserLocationState, (state) => state.entities[id]);

export const getLocationByAuthorId = (authorId: string) =>
  createSelector(getAllUserLocations, (allLocations) => {
    return allLocations.find((location: UserLocation) => location.author.id === authorId);
  });

// tslint:disable-next-line: max-line-length

export const getError = createSelector(getUserLocationState, (state) => state.error);

export const getSelectedUserLocation = createSelector(getUserLocationState, (state) => state.selected);

export const getTotalCount = createSelector(getUserLocationState, (state) => state.totalCount);
