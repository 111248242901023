import { createAction, props } from '@ngrx/store';

import { Location as ILocation } from '../models/location';

export enum LocationAction {
  FetchLocations = '[Api] FETCH LOCATIONS',
  GetLocation = '[Api] GET LOCATION',
  AddLocations = 'ADD LOCATIONS',
  AddLocation = 'ADD LOCATION',
  AddSelectedLocation = 'ADD SELECTED LOCATION',
  RemoveSelectedLocation = 'REMOVE SELECTED LOCATION',
  AddError = 'ADD LOCATION ERROR',
  AddTotalCount = 'ADD LOCATION TOTAL COUNT',
}

// Effect actions

export const FetchLocations = createAction(
  LocationAction.FetchLocations,
  props<{
    search: string;
    pageIndex: number;
    pageSize: number;
    excludeEmpty: boolean;
    position: string;
    country: string;
  }>()
);

export const GetLocation = createAction(LocationAction.GetLocation, props<{ id: string }>());

export const AddLocations = createAction(LocationAction.AddLocations, props<{ locations: ILocation[] }>());

export const AddLocation = createAction(LocationAction.AddLocation, props<{ location: ILocation }>());

export const AddSelectedLocation = createAction(LocationAction.AddSelectedLocation, props<{ location: ILocation }>());

export const RemoveSelectedLocation = createAction(LocationAction.RemoveSelectedLocation);

export const AddTotalCount = createAction(LocationAction.AddTotalCount, props<{ totalCount: number }>());

export const AddError = createAction(LocationAction.AddError, props<{ error: any }>());
