
export const ROUTES_WITH_BACKGROUND_BANNER: string [] = [
  'kurs',
  'kursoversikt',
  'digitalkurs',
  'uthavn',
  'gjestehavn',
  'partner'
];

export const ROUTES_WITHOUT_BACKGROUND_BANNER: string [] = [
  'request',
  'participants'
];
