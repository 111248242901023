import { HttpClient } from '@angular/common/http';
import { Injectable, isDevMode, NgModule } from '@angular/core';
import { ConfigService } from '@core/services/config.service';
import {
  translocoConfig,
  TranslocoLoader,
  TranslocoModule,
  TRANSLOCO_CONFIG,
  TRANSLOCO_LOADER,
} from '@ngneat/transloco';

@Injectable({ providedIn: 'root' })
export class TranslocoHttpLoader implements TranslocoLoader {
  constructor(private http: HttpClient, private config: ConfigService) {}

  getTranslation(lang: string) {
    if(!isDevMode()) {
      const currentBaseUrl = this.config.getCurrentBaseUrl();
      return this.http.get(`${currentBaseUrl}/assets/i18n/${lang}.json`);
    } else {
      return this.http.get(`./assets/i18n/${lang}.json`);
    }
  }
}

export function translocoConfigFactory() {
  return translocoConfig({
    availableLangs: ['en', 'nb'],
    fallbackLang: 'nb',
    defaultLang: !isDevMode() ? 'nb' : 'en',
    // Remove this option if your application doesn't support changing language in runtime.
    reRenderOnLangChange: false,
    prodMode: !isDevMode(),
  });
}

@NgModule({
  exports: [TranslocoModule],
  providers: [
    {
      provide: TRANSLOCO_CONFIG,
      useFactory: translocoConfigFactory,
      deps: [ConfigService],
    },
    { provide: TRANSLOCO_LOADER, useClass: TranslocoHttpLoader, deps: [HttpClient, ConfigService] },
  ],
})
export class TranslocoRootModule {}
