import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslocoModule } from '@ngneat/transloco';
import { IdentityStoreModule } from '@modules/identity/store/identity-store.module';
import { LocationStoreModule } from '@modules/location/store/location-store.module';
import { ModalModule } from '@shared/modals/modal.module';
import { SimplebarAngularModule } from 'simplebar-angular';
import { CustomerFeedbackComponent } from './components/customer-feedback/customer-feedback.component';
import { BottomMenuComponent } from './components/bottom-menu/bottom-menu.component';
import { ContentComponent } from './components/content/content.component';
import { FooterComponent } from './components/footer/footer.component';
import { ToolbarComponent } from './components/toolbar/toolbar.component';
import { DefaultLayoutComponent } from './default/default.component';
import { BootstrapDropdownDirective } from './directives/dropdown.directive';
import { ErrorPageLayoutComponent } from './error-layout/error-layout.component';
import { ForbiddenPageComponent } from './pages/forbidden-page/forbidden-page.component';
import { NotFoundPageComponent } from './pages/not-found/not-found.component';
import { ServerErrorPageComponent } from './pages/server-error/server-error.component';
import { UnauthorizePageComponent } from './pages/unauthorize-page/unauthorize-page.component';
import { DowntimeBannerComponent } from './components/downtime-banner/downtime-banner.component';
import { MatRadioModule } from '@angular/material/radio';

import { MaintenanceComponent } from './pages/maintenance/maintenance.component';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatDividerModule } from '@angular/material/divider';
import { LayoutModule as CdkLayoutModule } from '@angular/cdk/layout';
import { CdkAccordionModule } from '@angular/cdk/accordion';
import { MatMenuModule } from '@angular/material/menu';


const LAYOUT_MODULES = [
  CommonModule,
  RouterModule,
  FormsModule,
  ReactiveFormsModule,
  TranslocoModule,
  IdentityStoreModule,
  LocationStoreModule,
  ModalModule,
  SimplebarAngularModule,
  MatRadioModule,
  MatButtonModule,
  MatIconModule,
  MatDividerModule,
  CdkLayoutModule
];

@NgModule({
  declarations: [
    ForbiddenPageComponent,
    NotFoundPageComponent,
    ServerErrorPageComponent,
    UnauthorizePageComponent,
    DefaultLayoutComponent,
    ErrorPageLayoutComponent,
    ContentComponent,
    ToolbarComponent,
    FooterComponent,
    BottomMenuComponent,
    CustomerFeedbackComponent,
    BootstrapDropdownDirective,
    DowntimeBannerComponent,
    MaintenanceComponent
  ],
  imports: [...LAYOUT_MODULES, CdkAccordionModule, MatMenuModule],
  exports: [
    ForbiddenPageComponent,
    NotFoundPageComponent,
    ServerErrorPageComponent,
    UnauthorizePageComponent,
    DefaultLayoutComponent,
    ErrorPageLayoutComponent,
    BootstrapDropdownDirective
  ],
  providers: []
})
export class LayoutModule {
}
