import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { tap, first } from 'rxjs/operators';

import { format, subHours } from 'date-fns';
import { UserLocationStoreService } from '@shared/store/user-location-store/services/users-location.store.service';
import { UserLocation } from '@shared/models/user-location';

@Injectable()
export class UserLocationListResolver implements Resolve<any> {
  constructor(private userLocationStoreService: UserLocationStoreService) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<UserLocation[]> {
    return this.userLocationStoreService.getAllUsersLocation().pipe(
      tap((userLocations) => {
        if (userLocations.length === 0) {
          this.userLocationStoreService.fetchAllUsersLocation(
            '',
            '',
            '',
            0,
            1000,
            '',
            24
          );
        }
      }),
      first()
    );
  }
}
