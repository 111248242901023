import { Injectable } from '@angular/core';
import { AuthenticatedResult, OidcSecurityService } from "angular-auth-oidc-client";
import { StorageService } from '@core/services/storage.service';
import { BackNavigationService } from "@core/services/back-navigation.service";
import { UserProfile, UserRoles } from '@modules/identity/models/user';
import { EMPTY, Observable } from "rxjs";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { catchError } from "rxjs/operators";
import { AuthRedirectService } from "@core/services/auth-redirect.service";


export const REDIRECT_URL_KEY = 'return_url';

@Injectable({
  providedIn: "root"
})
export class AuthService {

  constructor(
    private httpClient: HttpClient,
    private oidcSecurityService: OidcSecurityService,
    private backNavigation: BackNavigationService,
    private authRedirectService: AuthRedirectService,
    private storage: StorageService
  ) {
  }

  get isAuthenticated$(): Observable<AuthenticatedResult> {
    return this.oidcSecurityService.isAuthenticated$;
  }

  isAuthenticated(): boolean {
    return this.oidcSecurityService.isAuthenticated(this.getCurrentConfigId());
  }

  login(redirectUrl?: string): void {
    this.authRedirectService.saveRedirectRoute(this.getCurrentConfigId(),redirectUrl || this.backNavigation.routeHistory[0]);
    this.oidcSecurityService.authorize();
  }

  logout(): void {
    this.storage.removeItem(REDIRECT_URL_KEY);
    this.oidcSecurityService.logoffAndRevokeTokens().pipe(
      catchError((error) => EMPTY)
    ).subscribe();

  }

  getCurrentConfigId(): string {
     return this.oidcSecurityService.getConfiguration().configId;
  }

  setRedirectUrl(redirectUrl: string, params?: any): void {
    const uri = decodeURI(redirectUrl);
    this.storage.setItem(REDIRECT_URL_KEY, JSON.stringify({ redirectUrl: uri, params }));
  }

  getAccessToken(): string {
    return this.oidcSecurityService.getAccessToken();
  }

  getRefreshToken(): string {
    return this.oidcSecurityService.getRefreshToken();
  }

  getUserData(): any {
    return this.oidcSecurityService.getUserData();
  }


  isServiceProvider(user: UserProfile): boolean {
    return this.isInRole(user, UserRoles.SERVICE_PROVIDER);
  }

  isInstructor(user: UserProfile): boolean {
    return this.isInRole(user, UserRoles.INSTRUCTOR);
  }

  isInRole(user: UserProfile, role: string): boolean {
    return user.roles.some((x) => x === role);
  }

  revokeRefreshToken(): Observable<any> {
     let body = new HttpParams().append('client_id',this.oidcSecurityService.getConfiguration().clientId).append('token',this.oidcSecurityService.getRefreshToken()).append('token_type_hint','refresh_token').toString();
     let headers = new HttpHeaders().set('Content-Type','application/x-www-form-urlencoded').set('Accept', 'application/x-www-form-urlencoded');
    return this.httpClient.post(this.oidcSecurityService.getConfiguration().authWellknownEndpoints.revocationEndpoint,body,{headers});
  }
}
