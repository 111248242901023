import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { StorageService } from "@core/services/storage.service";
import { REDIRECT_URL_KEY } from "@core/services/auth.service";


@Injectable({
  providedIn: 'root'
})
export class AuthRedirectService {
  constructor(private readonly router: Router, private storageService: StorageService) {
  }

  checkSavedRedirectRouteAndNavigate(): void {
    const savedRouteForRedirect = this.getStoredRedirectRoute();
    if (savedRouteForRedirect) {
      this.router.navigateByUrl(savedRouteForRedirect).then(() => {
        this.deleteStoredRedirectRoute();
      });
    }
  }

  /**
   * Saves the redirect URL to storage.
   *
   * @param configId config id that will set the redirection.
   * @param url The redirect URL to save.
   */
  saveRedirectRoute(configId: string, url: string): void {
    this.storageService.setItem(REDIRECT_URL_KEY, url);
  }

  /**
   * Gets the stored redirect URL from storage.
   */
  private getStoredRedirectRoute(): string {
    return this.storageService.getItem(REDIRECT_URL_KEY);
  }

  /**
   * Removes the redirect URL from storage.
   */
  private deleteStoredRedirectRoute(configId?: string): void {
    this.storageService.removeItem(REDIRECT_URL_KEY);
  }
}
