import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ConfigService } from "@core/services/config.service";
import { HttpClient } from "@angular/common/http";
import { EmergencyContactRequest } from "@modules/privacy-policy/models/EmergencyContactRequest";

@Injectable({ providedIn: 'root' })
export class EmergencyContactRequestService {
  constructor(
    private configService: ConfigService,
    private http: HttpClient,
  ) {
    const config = this.configService.getCurrent();
    this.baseUrl = `${config.services.booking}/api/cognito/emergencyContact`;
  }

  baseUrl : string;

  public getEmergencyContactRequestStatus(token: string): Observable<any> {
    const url = `${this.baseUrl}/status?token=${token}`;
    return this.http.get(url);
  }

  public updateEmergencyContactRequest(body: EmergencyContactRequest): Observable<string> {
    return this.http.put<string>(this.baseUrl, body);
  }
}
