import { Injectable } from '@angular/core';
import { StarIcon } from '../models/start-icon';
import { INgxRatingOptions } from '../utility/ngx-rating.option';

@Injectable()
export class RatingService {
    constructor() {}

    public getIconsData(max: number, value: number, color?: string, options?: INgxRatingOptions): Array<StarIcon> {
        let loopNumber: number = 0;
        let isFraction: boolean = false;

        if(value)
        {
          if (Number.isInteger(value)) {
            loopNumber = value;
            isFraction = false;
          } else {
            loopNumber = Math.floor(value) + 1;
            isFraction = true;
          }
        }

        const lstOfIcon: Array<StarIcon> = [];

        for (let i = 1; i <= max; i++) {
            if (i <= loopNumber) {
                if (i === loopNumber && isFraction) {
                        lstOfIcon.push(new StarIcon(false, true, color ? color : options.FullStarColor));
                } else {
                    lstOfIcon.push(new StarIcon(false, false, color ? color : options.FullStarColor));
                }
            } else {
                lstOfIcon.push(new StarIcon(true, false, color ? color : options.EmptyStarColor));
            }
        }

        return lstOfIcon;
    }
}
