<div [ngClass]="{'prevent-scroll': isPreventScroll}" id="main">
  <div id="container-1" class="content">
    <div id="container-2" class="content">
      <div id="container-3" class="content">
        <!-- TOOLBAR: Below static -->
        <ng-container *ngIf="platform.isBrowser">
          <ng-container>
            <ng-container *ngTemplateOutlet="toolbar"></ng-container>
          </ng-container>
        </ng-container>
        <!-- / TOOLBAR: Below static -->

        <!-- CONTENT -->
        <content class="main-content"></content>
        <!-- / CONTENT -->

        <!-- / Customer Feedback -->
        <ng-container *ngIf="showCustomerFeedback()">
          <ng-container *ngTemplateOutlet="customerFeedback"></ng-container>
        </ng-container>
        <!-- CUSTOMER FEEDBACK -->

        <ng-container *ngIf="(handheldDeviceBreakpointState$ | async) as handheldDeviceBreakpointState">
          <!-- / BOTTOM MENU -->
          <ng-container
            *ngIf=" handheldDeviceBreakpointState.matches && !isLoginPage() && !(selectMessagePost$ | async)">
            <div style="height: 83px;"></div>
            <ng-container *ngTemplateOutlet="bottomMenu"></ng-container>
          </ng-container>
          <!-- / BOTTOM MENU -->

          <!-- FOOTER: Below static -->
          <ng-container *ngIf="!handheldDeviceBreakpointState.matches && !isLoginPage() && !isMapPage()">
            <ng-container *ngTemplateOutlet="footer"></ng-container>
          </ng-container>
        </ng-container>
        <!-- / FOOTER: Below static -->
      </div>
    </div>
  </div>
</div>

<!-- TOOLBAR -->
<ng-template #toolbar>
  <toolbar [isMobile]="(handheldDeviceBreakpointState$ | async)?.matches"
           [isAuthenticated]="isAuthenticated$ | async"
           [user]="userProfile$ | async"
           [scrolled]="scrolled$ | async"
           [currentUrl]="currentUrl$ | async"
           (preventScrolling)="toggleScroll($event)"
           (signOut)="signOutUser()">
  </toolbar>
</ng-template>
<!-- / TOOLBAR -->

<!-- CUSTOMER FEEDBACK -->
<ng-template #customerFeedback>
  <customer-feedback></customer-feedback>
</ng-template>
<!-- / CUSTOMER FEEDBACK -->

<!-- BOTTOM MENU -->
<ng-template #bottomMenu>
  <bottom-menu></bottom-menu>
</ng-template>
<!-- / BOTTOM MENU -->

<!-- FOOTER -->
<ng-template #footer>
  <live-footer></live-footer>
</ng-template>
<!-- / FOOTER -->
