import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ConfigService } from '@core/services/config.service';
import { Observable } from 'rxjs';
import { RsUserProfile, UserProfile } from '../../models/user';
import { StorageService } from '@core/services/storage.service';

@Injectable()
export class IdentityApiService {
  constructor(
    private http: HttpClient,
    private configService: ConfigService,
    private storage: StorageService
    ) {}

  private get baseUrl(): string {
    const config = this.configService.getCurrent();
    return `${config.services.identity}/api/user`;
  }

  private get memberBaseUrl(): string {
    const config = this.configService.getCurrent();
    return `${config.services.identity}/api/member`;
  }

  public fetchUser(): Observable<UserProfile> {
    return this.http.get<UserProfile>(`${this.baseUrl}/me`);
  }

  public getUserAsPromise(): Promise<UserProfile> {
    return this.http.get<UserProfile>(`${this.baseUrl}/me`).toPromise();
  }

  public register(user: UserProfile): Observable<UserProfile> {
    const config = this.configService.getCurrent();
    const data = {
      clientId: config.authentication.clientId,
      profile: user,
    };

    return this.http.post<UserProfile>(`${this.baseUrl}?refer=${user.referCode}`, data);
  }

  public createProfile(user: RsUserProfile): Observable<UserProfile> {
    return this.http.post<UserProfile>(`${this.baseUrl}/create`, user);
  }

  public registerAsPromise(email: string): Promise<UserProfile> {
    const config = this.configService.getCurrent();
    const data = {
      clientId: config.authentication.clientId,
      profile: {
        email: email,
        roles: ['TRAVELER'],
      },
    };

    const referCode = this.storage.getItem('referCode');
    if (referCode != null && referCode.length > 0) {
      this.storage.removeItem('referCode');
      this.storage.setItem('showReferPopup', 'true');
    }
    return this.http.post<UserProfile>(`${this.baseUrl}?refer=${referCode}`, data).toPromise();
  }

  public update(id: string, user: UserProfile): Observable<UserProfile> {
    return this.http.put<UserProfile>(`${this.baseUrl}/${id}`, user);
  }

  public verifyEmail(userId: string, token: string): Observable<any> {
    return this.http.put(`${this.baseUrl}/${userId}/email-verification?token=${encodeURIComponent(token)}`, null);
  }

  // This API is used for retrieve RS member discount
  public sendVerificationOtp(campaignId: string, phoneNo: string): Observable<any> {
    return this.http.post(`${this.memberBaseUrl}/sendotp`, { campaignId, phoneNo });
  }

  public redeemOTP(otp: string, bookingId: string,courseId: string, userId: string): Observable<boolean> {
    const params = new HttpParams().appendAll({bookingId,courseId,userId});
    return this.http.get<boolean>(`${this.memberBaseUrl}/redeemb/${otp}`, {params});
  }

  public createMoodleUser(userId: string, bookingId: string): Observable<any> {
    return  this.http.post(`${this.baseUrl}/CreateMoodleUser`,{userId,bookingId});
  }

  public lastLoggedIn(): Observable<any> {
    return this.http.post(`${this.baseUrl}/lastLoggedIn`, '');
  }
}
