<ng-container *ngIf="_data.isPreview; else previewer">
  <div class="image-viewer d-flex justify-content-center align-items-center">
    <div class="image-close-button">
      <button (click)="dialog.close()" color="warn" mat-mini-fab>
          <i class="fas fa-times"></i>
      </button>
    </div>
    <img class="image-preview" *ngIf="!_data.isVideo" [src]="_data.source" alt="">
    <video *ngIf="_data.isVideo" controls #videoPlayer>
      <source [src]="_data.source" [type]="_data.contentType">
      Your browser does not support HTML5 video.
    </video>
  </div>
</ng-container>
<ng-template #previewer>
  <ngx-image-viewer [config]="previewerConfig"  [src]="_data.sources" [(index)]="_data.index"></ngx-image-viewer>
</ng-template>
