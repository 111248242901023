import { Component, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'content',
  template: `
    <div style="width: 100%">
      <router-outlet *ngIf="true"></router-outlet>
    </div>
  `,
  styleUrls: ['./content.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ContentComponent {
  constructor() {}
}
