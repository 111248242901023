import { EntityState } from '@ngrx/entity';
import { Location as ILocation } from '../models/location';

export const locationFeatureKey = 'locations';

export interface LocationState extends EntityState<ILocation> {
  error: any;
  totalCount: number;
  selected: ILocation;
}
