import { HttpBackend, HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Inject, Injectable, isDevMode, Optional } from '@angular/core';
import { Config, devConfig } from '@core/models/config';
import { BehaviorSubject } from 'rxjs';
import { BASE_URL, CONFIG, SERVER_SIDE } from 'src/provider.token';
import { LogLevel, OpenIdConfiguration } from "angular-auth-oidc-client";
import { DefaultSecurityStorageService } from "@core/services/default-security-storage.service";

@Injectable({
  providedIn: 'root',
})
export class ConfigService {
  config: Config;
  private http: HttpClient;
  private baseUrl: String = '.';
  private currentBaseUrl: String = '.';
  private configObservable: BehaviorSubject<Config>;

  constructor(
    handler: HttpBackend,
    @Optional() @Inject(BASE_URL) baseUrl: String,
    @Optional() @Inject(SERVER_SIDE) serverSide: boolean,
    @Optional() @Inject(CONFIG) private c: Config

  ) {
    if (baseUrl) this.baseUrl = baseUrl;
    this.config = this.c;
    this.configObservable =  new BehaviorSubject<Config>(this.config);
    this.http = new HttpClient(handler);
  }

  getAuthModuleConfig(): OpenIdConfiguration {
    const { authentication: { domain , baseUrl, redirectUrl, clientId , scopes}, services }= this.config;
    return {
      authority: domain,
      redirectUrl: redirectUrl,
      postLogoutRedirectUri: baseUrl,
      clientId: clientId,
      scope: scopes[0],
      responseType: 'code',
      eagerLoadAuthWellKnownEndpoints: false,
      silentRenew: true,
      useRefreshToken: true,
      disableRefreshIdTokenAuthTimeValidation: true,
      enableIdTokenExpiredValidationInRenew: false,
      ignoreNonceAfterRefresh: true,
      autoUserInfo: false,
      renewTimeBeforeTokenExpiresInSeconds: 60,
      logLevel: LogLevel.None,
      storage: new DefaultSecurityStorageService(),
      secureRoutes: [services.booking.toString()]
    }
  }
  /**
   * Load application configuration
   */

  loadConfig(): Promise<Config> {
    if (this.config) {
      return new Promise((r) => r(this.config));
    } else {
      if (isDevMode()) {
        this.configObservable.next(devConfig)
        return new Promise((r) => r(devConfig));
      } else {
        const promise = this.http
          .get<Config>(`${this.baseUrl}/config`)
          .toPromise()
          .then((res) => {
            this.config = res;
            this.configObservable.next(this.config)
            return res;
          })
          .catch((error: HttpErrorResponse) => {
            // Default configuration for local development
            if (error.status === 404 || isDevMode()) {
              console.log('ConfigService: Using development mode.');
            } else {
              console.log('ConfigService: Failed to load application configuration.');
            }
            this.config = devConfig;
            this.configObservable.next(this.config)
            return devConfig;
          });
        return promise;
      }
    }
  }

  /**
   * Get current configuration
   */
  getCurrent(): Config {
    return this.config;
  }

  getCurrentBaseUrl(): String {
    this.currentBaseUrl = window?.location?.protocol + '//' + window?.location?.hostname + (window?.location?.port ? ':' + window?.location?.port : '');
    return this.currentBaseUrl;
  }
}
