import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { mergeMapHubToAction, ofHub, SIGNALR_HUB_UNSTARTED, startSignalRHub } from 'ngrx-signalr-core';
import { merge, Observable, of } from 'rxjs';
import { map, mergeMap, switchMap } from 'rxjs/operators';
import { UserLocationApiService } from './services/users-location.api.service';
import { UserLocationStoreService } from './services/users-location.store.service';
import * as fromAction from './users-location.action';
import { AppStoreService } from "@core/store/app-store.service";
import { EffectHelperService } from '@modules/core/services/effect-helper.service';
import { userLocationFeatureKey } from './users-location.state';

@Injectable()
export class UserLocationStoreEffects {
  constructor(
    private userLocationApiService: UserLocationApiService,
    private actions$: Actions,
    private userLocationStoreService: UserLocationStoreService,
    private shareStoreService: AppStoreService,
    private effectHelperService: EffectHelperService
    ) {}

  initPostsHub$ = createEffect(() =>
    this.actions$.pipe(
      ofType(SIGNALR_HUB_UNSTARTED),
      ofHub(this.userLocationStoreService.hubDefinitions.usersLocation),
      mergeMapHubToAction(({ hub }) => {
        const commentNotification$ = hub.on('userLocationCreatedNotification').pipe(
          map((feed: any) =>
            fromAction.AddUserLocation({
              userLocation: feed,
            })
          )
        );

        return merge(commentNotification$, of(startSignalRHub(hub)));
      })
    )
  );

  fetchUserLocationsEffect$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(fromAction.FetchUserLocations),
      switchMap((payload) =>
        this.userLocationApiService
          .getAllUserLocations(
            payload.from,
            '',
            payload.userId,
            payload.search,
            payload.position,
            0,
            payload.pageSize,
            '',
            payload.lastUpdatedFilter
          )
          .pipe(
            map((res) => {
              this.userLocationStoreService.addTotalCount(res.total);
              return res.items;
            })
          )
          .pipe(map((items) => fromAction.AddUserLocations({ userLocations: items })))
      )
    )
  );

  fetchUserLocation$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(fromAction.FetchUserLocation),
      mergeMap((payload) =>
        this.userLocationApiService
          .fetchLocationByUserId(payload.userId)
          .pipe(
            switchMap((userLocation) => {
              this.shareStoreService.setShareGeolocation(userLocation.isLocationEnabled);
              return of(fromAction.AddUserLocation({ userLocation }));
            })
          )
      )
    )
  );


  toggleUserLocationPreference$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(fromAction.EnableUserLocation, fromAction.DisableUserLocation),
      mergeMap((payload) =>
        this.userLocationApiService
          .toggleUserLocationPreference(payload.userId, payload.allowLocation)
          .pipe(
            switchMap((userLocation) => {
              this.shareStoreService.setShareGeolocation(userLocation.isLocationEnabled);
              return of(fromAction.AddUserLocation({userLocation}));
            }),
            this.effectHelperService.handleError(fromAction.AddError, true, userLocationFeatureKey)
          )
      )
    )
  );
}
