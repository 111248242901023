import { Inject, Injectable, Optional } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, CanLoad, Route, RouterStateSnapshot, UrlSegment } from '@angular/router';
import { OidcSecurityService } from "angular-auth-oidc-client";
import { AuthService } from "@core/services/auth.service";
import { AuthRedirectService } from "@core/services/auth-redirect.service";
import { PlatformService } from "@core/services/platform.service";

@Injectable({ providedIn: 'root' })
export class AutoLoginPartialRoutesServerSideGuard implements CanActivate, CanActivateChild, CanLoad {
  constructor(
   private  platformService: PlatformService,
    private oidcSecurityService: OidcSecurityService,
    private authorizationService: AuthService,
    private autoLoginService: AuthRedirectService,
  ) {}

  canLoad(route: Route, segments: UrlSegment[]): boolean {
    const routeToRedirect = segments.join('/');
    return this.checkAuth(routeToRedirect);
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    return this.checkAuth(state.url);
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    return this.checkAuth(state.url);
  }

  private checkAuth(url: string): boolean | null {
    if(this.platformService.isBrowser) {
      const configId = this.authorizationService.getCurrentConfigId();
      const isAuthenticated = this.oidcSecurityService.isAuthenticated(configId);
      if (isAuthenticated) {
        this.autoLoginService.checkSavedRedirectRouteAndNavigate();
      }

      if (!isAuthenticated) {
        this.autoLoginService.saveRedirectRoute(configId, url);
        this.oidcSecurityService.authorize();
      }

      return isAuthenticated;
    }
      return true;
  }

}
