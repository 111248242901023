import {
  Component,
  Input,
  OnChanges,
  OnInit,
  ViewEncapsulation
} from '@angular/core';
import { StarIcon } from '../../models/start-icon';
import { RatingService } from '../../services/rating.service';
import { INgxRatingOptions } from '../../utility/ngx-rating.option';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'ngx-show-rating',
  templateUrl: './show-rating.component.html',
  styleUrls: ['./show-rating.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ShowRatingComponent implements OnInit, OnChanges {
  @Input() rate: number;

  @Input()
  public max: number;

  @Input()
  public color: string;

  @Input()
  public CssClass = '';

  @Input()
  public options: INgxRatingOptions = {
    EmptyStarColor: 'black',
    FullStarColor: 'black',
    Padding: '1px',
  };

  public lstOfIcon: Array<StarIcon> = [];

  constructor(
    private ratingService: RatingService
  ) {}

  ngOnInit() {
  }

  ngOnChanges(): void {
    this.lstOfIcon = this.ratingService.getIconsData(
      this.max,
      this.rate,
      this.color,
      this.options
    );
  }
}
