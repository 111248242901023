import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CoreModule } from '@core/core.module';
import { ngxUiLoaderConfig } from '@core/utils/loader-config';
import { PrivacyPolicyModule } from '@modules/privacy-policy/privacy.module';
import { NgxUiLoaderHttpModule, NgxUiLoaderModule } from 'ngx-ui-loader';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LayoutModule } from './layout/layout.module';
import { TranslocoRootModule } from './transloco-root.module';
import { TryggbatModule } from '@modules/tryggbat/tryggbat.module';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule.withServerTransition({appId: 'serverApp'}),
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    CoreModule.forRoot(),
    LayoutModule,
    PrivacyPolicyModule,
    TryggbatModule,
    TranslocoRootModule,
    NgxUiLoaderModule.forRoot(ngxUiLoaderConfig),
    NgxUiLoaderHttpModule.forRoot({showForeground: true, excludeRegexp: ['.*/api/account/[\\w-]+/document']})
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule {
}
