import { isPlatformBrowser } from '@angular/common';
import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { Router, UrlTree } from '@angular/router';

@Component({
  selector: 'live-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  constructor(
    @Inject(PLATFORM_ID) private platformId,
    private router: Router
  ) { }

  ngOnInit() {
  }

  openInstructorRequestPageOnNewTab() {
    const instructorPageUrlTree: UrlTree = this.router.createUrlTree([`/kursoversikt-for-tilbydere`]);
    const serializedUrl = this.router.serializeUrl(instructorPageUrlTree);
    if(isPlatformBrowser(this.platformId)) {
      window.open(serializedUrl,'_blank');
    }

  }

  get currentYear(): number {
    return new Date().getFullYear();
  }
}
