<footer class="footer">
  <div class="container px-md-0">
    <div class="footer-logo-menu">
      <div class="row">
        <div class="col-12 col-sm-12 col-md-6">
          <img class="logo" [routerLink]="'/'" src="assets/icons/logo_negative@2x_1.png" alt="RS Sjøliv">
          <ng-container>
            <span class="icon-logo-title"></span>
          </ng-container>
        </div>
        <div class="col-12 col-sm-12 col-md-3 d-none d-sm-block">
          <div class="footer-column">
            <h4 class="text-uppercase">{{'layout.labels.company' | transloco }}</h4>
            <ul class="footer-list-wrapper">
              <li class="list"><a
                href="https://redningsselskapet.no/om-oss/">{{'layout.nav_links.about_us' | transloco }}</a></li>
              <li class="list"><a
                href="https://redningsselskapet.no/kundeservice/">{{'layout.nav_links.contact' | transloco }}</a></li>
              <li class="list"><a
                (click)="openInstructorRequestPageOnNewTab()">{{'layout.nav_links.become_an_instructor' | transloco }}</a>
              </li>
              <li class="list"><a id="CookieDeclarationChangeConsentChange" href="javascript: Cookiebot.show()">{{'layout.nav_links.cookiebot_consent' | transloco }}
              </a></li>
            </ul>
          </div>
        </div>
        <div class="col-12 col-sm-12 col-md-3 d-none d-sm-block">
          <div class="footer-column">
            <h4 class="text-uppercase">{{'layout.labels.follow_us' | transloco}}</h4>
            <ul class="footer-list-wrapper">
              <li class="list"><a target="_blank" href="https://www.facebook.com/Redningsselskapet/">Facebook</a></li>
              <li class="list"><a target="_blank" href="https://www.instagram.com/redningsselskapet">Instagram</a></li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="copyright-section">
          <div class="row d-flex align-items-center">
            <div class="col d-none d-sm-inline-block  ">
            </div>
            <div class="col col-lg-3">
              <p class="text m-0 text-center text-sm-right">&copy; {{currentYear}} Redningsselskapet</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</footer>

<script id="CookieDeclaration" src="https://consent.cookiebot.com/fbd8b471-ee0b-4522-8704-14dc24c2f8e4/cd.js" type="text/javascript" async></script>
