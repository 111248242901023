import { createAction, props } from '@ngrx/store';

export enum AppAction {
  HomePage = 'IS PAGE IS HOME PAGE',
  ShowDropdown = 'SHOW TOOLBAR DROPDOWN',
  DropdownColorNotChange = 'DROPDOWN COLOR NOT CHANGE',
  StopRedirectForDropDown = 'STOP REDIRECT FOR DROPDOWN',
  ShareGeolocation = 'IS SHARING GEOLOCATION',
  CurrentLocation = 'SET CURRENT LOCATION',
  InfoPopupShown = 'HAS INFO POPUP BEEN SHOWED',
  SelectMessagePost = 'SELECT MESSAGE POST',
  LoadFailure = 'LOAD SHARE ERROR',
}

export const HomePage = createAction(AppAction.HomePage, props<{ change: boolean }>());
export const ShowDropdown = createAction(AppAction.ShowDropdown, props<{ view: boolean }>());
export const DropdownColorNotChange = createAction(AppAction.DropdownColorNotChange, props<{ change: boolean }>());
export const StopRedirectForDropDown = createAction(AppAction.StopRedirectForDropDown, props<{ change: boolean }>());
export const ShareGeolocation = createAction(AppAction.ShareGeolocation, props<{ change: boolean }>());
export const CurrentLocation = createAction(
  AppAction.CurrentLocation,
  props<{
    change: {
      coords: { lon: number; lat: number };
      address: any;
    };
  }>()
);
export const InfoPopupShown = createAction(AppAction.InfoPopupShown, props<{ change: boolean }>());
export const SelectMessagePost = createAction(AppAction.SelectMessagePost, props<{ change: boolean }>());
export const LoadFailure = createAction(AppAction.LoadFailure, props<{ error: any }>());
