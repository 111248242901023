import { Component, OnInit, Input, ChangeDetectorRef, OnChanges } from '@angular/core';

import * as mapboxgl from 'mapbox-gl';
import { IMarker } from '@shared/models/marker';
import { ConfigService } from '@core/services/config.service';
import { MapboxService } from '@shared/services/mapbox.service';
import { TRANSLOCO_SCOPE } from '@ngneat/transloco';

enum MapboxRoutingProfile {
  Walking = 'walking',
  Cycling = 'cycling',
  Driving = 'driving',
}

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'mapbox-map-direction',
  templateUrl: './mapbox-map-direction.component.html',
  styleUrls: ['./mapbox-map-direction.component.scss']
})
export class MapboxMapDirectionComponent implements OnInit, OnChanges {
  map: mapboxgl.Map;
  style = 'mapbox://styles/lineandorsen/ck1gdso4o12eb1cpklnyodqa6/draft';

  public geolocate: {
    control: mapboxgl.GeolocateControl;
    isTriggered: boolean;
  } = { control: null, isTriggered: false };

  lat = 59.91273;
  lon = 10.74609;

  distance: any = null;
  duration: any = null;

  routingProfile: MapboxRoutingProfile;

  @Input()
  public source: IMarker;

  @Input()
  public destination: {
    lat: number;
    lon: number;
  };

  @Input()
  public loadCurrentLocation = false;

  markerOptions = {
    destination: {
      icon: 'assets/icons/placeholder.png',
    },
    origin: {
      icon: 'https://maps.gstatic.com/mapfiles/api-3/images/spotlight-poi.png',
    },
    waypoints: {},
  };

  renderOptions = {
    suppressMarkers: true,
  };

  isShowMarker = false;

  constructor(
    private configService: ConfigService,
    private mapboxService: MapboxService,
    private cdr: ChangeDetectorRef
  ) {
    this.mapboxService.setCurrentPosition();
  }

  ngOnInit() {
    (mapboxgl as any).accessToken = this.configService.getCurrent().mapBox.accessToken;
    this.map = new mapboxgl.Map({
      container: 'classroomMap',
      style: this.style,
      zoom: 15,
      center: [this.destination.lon, this.destination.lat],
    });
    // Trigger geolocation
    this.map.on('load', () => {
      // Create a marker and add it to the map.
      new mapboxgl.Marker().setLngLat({ lon: this.destination.lon, lat: this.destination.lat }).addTo(this.map);
    });
  }

  get RoutingProfile(): typeof MapboxRoutingProfile {
    return MapboxRoutingProfile;
  }

  ngOnChanges(): void {
    this.cdr.detectChanges();
  }

  public setTravelMode(profile: MapboxRoutingProfile): void {
    this.routingProfile = profile;
    if (navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        if (position && position.coords) {
          const destination = `${this.destination.lon},${this.destination.lat}`;
          const currentPosition = `${position.coords.longitude},${position.coords.latitude}`;

          this.mapboxService.mapboxDirection(profile, `${destination};${currentPosition}`).subscribe((data) => {
            if (data.distance && data.duration) {
              this.distance = data.distance;
              this.duration = Math.floor(data.duration / 60);
            } else {
              this.distance = null;
              this.duration = null;
            }
          });
        }
      });
    }
  }
}
