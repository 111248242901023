import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ImageViewerComponent } from '../components/image-viewer/image-viewer.component';
import { ImageViewer } from '../models/image.viewer';

const PANEL_CLASS = 'image-viewer-dialog';
const BACKDROP_CLASS = 'image-backdrop';

@Injectable()
export class ImagePreviewService {
    constructor(
        private matDialog: MatDialog
    ) {}

    public previewImageWithSrc(src: string): void {
        const previewerData: ImageViewer = {
            source: src,
            isVideo: false,
            isPreview: true,
            contentType: ''
        };
        const dialogRef = this.matDialog.open(ImageViewerComponent, {
            panelClass: PANEL_CLASS,
            backdropClass: BACKDROP_CLASS,
            data: previewerData
        });
    }

    public previewVideoWithSrc(videoSource: string, contentType: string): void {
        const previewerData: ImageViewer = {
            source: videoSource,
            isVideo: true,
            contentType: contentType
        };
        const dialogRef = this.matDialog.open(ImageViewerComponent, {
            panelClass: PANEL_CLASS,
            backdropClass: BACKDROP_CLASS,
            data: previewerData
        });
    }

    public openImagePreviewer(images: string[], selectedIndex: number = 0): void {
        const previewerData: ImageViewer = {
            source: '',
            isVideo: false,
            contentType: '',
            sources: images,
            index: selectedIndex
        };
        const dialogRef = this.matDialog.open(ImageViewerComponent, {
            panelClass: PANEL_CLASS,
            backdropClass: BACKDROP_CLASS,
            data: previewerData
        });
    }
}
