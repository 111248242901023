import { createAction, props } from '@ngrx/store';
import { UserLocation } from '@shared/models/user-location';

export enum UserLocationActions {
  FetchUserLocations = '[Api] FETCH USER LOCATIONS',
  SaveUserLocation = '[Api] SAVE USER LOCATION',
  FetchUserLocation = '[Api] FETCH USER LOCATION',
  DeleteUserLocation = '[Api] DELETE USER LOCATION',
  RemoveUserLocation = 'REMOVE USER LOCATION',
  UpdateUserLocation = 'UPDATE USER LOCATION',
  EnableUserLocation = '[Api] ENABLE LOCATION PREFERENCE',
  DisableUserLocation = '[Api] DISABLE LOCATION PREFERENCE',

  AddUserLocations = 'ADD USER LOCATIONS',
  AddUserLocation = 'ADD USER LOCATION',
  AddSelectedUserLocation = 'ADD SELECTED USER LOCATION',
  RemoveSelectedUserLocation = 'REMOVE SELECTED USER LOCATION',
  AddError = 'ADD POST ERROR',
  AddTotalCount  = 'ADD USER LOCATION TOTAL COUNT',
}

// Effect actions
// export const SaveUserLocation = createAction(UserLocationActions.SaveUserLocation, props<{ userLocation: UserLocation }>());

export const FetchUserLocations = createAction(
  UserLocationActions.FetchUserLocations,
  props<{
    from: string;
    userId: string;
    search: string;
    pageIndex: number;
    pageSize: number;
    position: string;
    lastUpdatedFilter: number | string;
  }>()
);

export const FetchUserLocation = createAction(UserLocationActions.FetchUserLocation, props<{ userId: string }>());
export const DeleteUserLocation = createAction(UserLocationActions.DeleteUserLocation, props<{ id: string }>());
export const RemoveUserLocation = createAction(UserLocationActions.RemoveUserLocation, props<{ id: string }>());
export const UpdateUserLocation = createAction(UserLocationActions.UpdateUserLocation, props<{ userLocation: UserLocation }>());
export const EnableUserLocation = createAction(UserLocationActions.EnableUserLocation, props<{ userId: string; allowLocation: boolean }>());
export const DisableUserLocation = createAction(UserLocationActions.DisableUserLocation, props<{ userId: string; allowLocation: boolean }>());


// Store actions
export const AddUserLocations = createAction(UserLocationActions.AddUserLocations, props<{ userLocations: UserLocation[] }>());
export const AddUserLocation = createAction(UserLocationActions.AddUserLocation, props<{ userLocation: UserLocation }>());
export const AddSelectedUserLocation = createAction(UserLocationActions.AddSelectedUserLocation,props<{ userLocation: UserLocation }>());
export const RemoveSelectedUserLocation = createAction(UserLocationActions.RemoveSelectedUserLocation);
export const AddError = createAction(UserLocationActions.AddError, props<{ error: any }>());
export const AddTotalCount = createAction(UserLocationActions.AddTotalCount, props<{ totalCount: number }>());
