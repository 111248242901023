import { NgModule } from '@angular/core';

import { StoreModule } from '@ngrx/store';
import { userLocationStoreReducer } from './users-location.reducer';
import { EffectsModule } from '@ngrx/effects';
import { UserLocationStoreEffects } from './users-location.effects';
import { UserLocationApiService } from './services/users-location.api.service';
import { UserLocationStoreService } from './services/users-location.store.service';
import { USER_LOCATION_RESOLVERS } from './resolvers';
import { userLocationFeatureKey } from './users-location.state';


@NgModule({
  imports: [
    StoreModule.forFeature(userLocationFeatureKey, userLocationStoreReducer),
    EffectsModule.forFeature([UserLocationStoreEffects]),
  ],
  providers: [UserLocationApiService, UserLocationStoreService, ...USER_LOCATION_RESOLVERS],
})
export class UserLocationStoreModule {}
