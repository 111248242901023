import { UserLocationState } from './users-location.state';
import * as fromAction from './users-location.action';
import { adapter } from './users-location.entity';
import { Action, createReducer, on } from '@ngrx/store';

export const initialState: UserLocationState = adapter.getInitialState({
  error: null,
  totalCount: 0,
  selected: null
});

const reducer = createReducer(
  initialState,
  on(fromAction.AddUserLocation, (state, { userLocation }) => {
    return adapter.upsertOne(userLocation, state);
  }),

  on(fromAction.RemoveUserLocation, (state, { id }) => {
    return adapter.removeOne(id, state);
  }),

  on(fromAction.AddUserLocations, (state, { userLocations }) => {
    return adapter.setAll(userLocations, state);
  }),

  on(fromAction.AddTotalCount, (state, { totalCount }) => {
    return adapter.addMany([], { ...state, totalCount: totalCount });
  }),

  on(fromAction.AddError, (state, { error }) => {
    return adapter.addMany([], { ...state, error: error });
  }),

  on(fromAction.AddSelectedUserLocation, (state, { userLocation }) => {
    return adapter.addMany([], { ...state, selected: userLocation });
  })
);

export function userLocationStoreReducer(state: UserLocationState = initialState, action: Action): UserLocationState {
  return reducer(state, action);
}
