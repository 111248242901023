import { Component } from '@angular/core';
import { ConfigService } from '@modules/core/services/config.service';
import { TRANSLATION_SCOPE } from '@modules/template/student/i18n/scope';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent {

  banners = {
    dev: {
      jetski: 'https://rssjoliv-dev.imgix.net/about-sjoliv/about-sjoliv-1.jpg',
      group: 'https://rssjoliv-dev.imgix.net/about-sjoliv/about-sjoliv-2.jpg'
    },
    staging: {
      jetski: 'https://rssjoliv-staging.imgix.net/about-sjoliv/about-sjoliv-1.jpg',
      group: 'https://rssjoliv-staging.imgix.net/about-sjoliv/about-sjoliv-2.jpg'
    },
    prod: {
      jetski: 'https://rssjoliv-prod.imgix.net/about-sjoliv/about-sjoliv-1.jpg',
      group: 'https://rssjoliv-prod.imgix.net/about-sjoliv/about-sjoliv-2.jpg'
    }
  }

  constructor(
    private configService: ConfigService
  ) { }

  get banner() {
    const env = this.configService.getCurrent().env;

    switch (env) {
      case 'production':
        return this.banners.prod;
      case 'staging':
        return this.banners.staging
      default:
        return this.banners.dev
    }

  }
}
