import { Injectable } from '@angular/core';
import { BaseApiService } from '@core/http/base.api.service';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ConfigService } from '@core/services/config.service';
import { Observable } from 'rxjs';
import { PageList } from '@core/models/page-list';
import { Location } from '@modules/location/models/location';

@Injectable()
export class LocationApiService extends BaseApiService {
  constructor(public http: HttpClient, private configService: ConfigService) {
    super(http);
  }

  private get baseUrl(): string {
    const config = this.configService.getCurrent();
    return `${config.services.experience}/api/location`;
  }

  getAllLocation(
    search: string,
    excludeEmpty: boolean = true,
    pageOffset: number = 0,
    pageSize: number = 1000,
    position?: string,
    country?: string
  ): Observable<PageList<Location>> {
    const params = new HttpParams()
      .append('search', search)
      //.append('excludeEmpty',  `${excludeEmpty}`)
      .append('pageOffset', pageOffset.toString())
      .append('pageSize', pageSize.toString())
      .append('country', country ? country : '')
      .append('position', position ? position : '');

    return super.getAllWithPagination<Location>(this.baseUrl, params);
  }

  getByLocationId(id: string): Observable<Location> {
    return super.get<Location>(`${this.baseUrl}`, id);
  }
}
