import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import SwiperCore, {Navigation,FreeMode} from 'swiper';
SwiperCore.use([Navigation]);


@Component({
  selector: 'app-multimedia-slick-carousel',
  templateUrl: './multimedia-slick-carousel.component.html',
  styleUrls: ['./multimedia-slick-carousel.component.scss'],
  encapsulation: ViewEncapsulation.None,
  host: {
    class: 'd-block position-relative'
  }
})
export class MultimediaSlickCarouselComponent implements OnInit {
  @Input() multimedias: any[] = [];
  @Input() multimediaType: 'file' | 'multimedia' = 'multimedia';
  @Input() disableMultimediaDeletion:boolean = false;

  @Output() remove = new EventEmitter<number | string>();
  constructor() { }

  ngOnInit(): void {
  }

  removeMultimedia(multimediaName:string, multimediaIndex: number): void {
    if(this.multimediaType === 'multimedia') {
      this.removeMultimediaEvent(multimediaName);
    }
    else {
      this.removeFileEvent(multimediaIndex);
    }
  }

  removeFileEvent(multimediaIndex: number): void {
    this.remove.emit(multimediaIndex);
  }

  removeMultimediaEvent(multimediaName:string): void {
    this.remove.emit(multimediaName);
  }
}
