<header>
  <div class="fixed-top"
       [ngClass]="{ 'mat-elevation-z10 bg-white': isShowBackground}"
       [@showBackground]="isShowBackground? 'show': 'hide'">
    <div class="container px-md-0">
      <ng-container *ngTemplateOutlet="topBarTemplate"></ng-container>
    </div>
    <nav *ngIf="isActiveMenu"
         class="position-absolute pb-2"
         [@toggleMenu]="isActiveMenu">
      <div class="container px-md-0">
        <ng-container *ngTemplateOutlet="topBarTemplate"></ng-container>
      </div>
      <mat-divider></mat-divider>
      <div class="container px-md-0" [ngClass]="{'full-height': isMobile && isActiveMenu}">
        <div cdkAccordion [multi]="true" class="row mx-0 py-2 justify-content-end">
<!--        <div cdkAccordion [multi]="true" class="row mx-0 py-2" [ngClass]="{'justify-content-end': isMobile}">-->
<!--          <cdk-accordion-item #accordionItemKart="cdkAccordionItem" class="col-md-2 col-12 text-md-left text-right">-->
<!--            <ng-contianer [ngTemplateOutlet]="isMobile ? navSectionAccordionTitle : navSectionTitle"-->
<!--                          [ngTemplateOutletContext]="{$implicit: accordionItemKart, route: '/kart', title: 'layout.nav_links.map', hasChildren: false}">-->

<!--            </ng-contianer>-->
<!--          </cdk-accordion-item>-->
          <cdk-accordion-item [expanded]="!isMobile" #accordionItemCourse="cdkAccordionItem"
                              class="col-md-2 col-12 text-md-left text-right">
            <ng-contianer [ngTemplateOutlet]="isMobile ? navSectionAccordionTitle : navSectionTitle"
                          [ngTemplateOutletContext]="{$implicit: accordionItemCourse ,route: '/alle-kurs',title: 'layout.nav_links.courses',hasChildren: true}">

            </ng-contianer>
            <!--Do not use *ngIf directive here for accordion toggle. Use ngClass or animation trigger-->
            <ul class="nav" [ngClass]="{'pr-2': isMobile}" [@bodyExpansion]="!accordionItemCourse.expanded ? 'collapsed' : 'expanded'">
              <ng-container [ngTemplateOutlet]="courseLinks"></ng-container>
            </ul>
          </cdk-accordion-item>
          <!--Do not use *ngIf directive here for accordion toggle. Use ngClass or animation trigger-->
<!--          <cdk-accordion-item #accordionItemHarbour="cdkAccordionItem"-->
<!--                              [expanded]="!isMobile"-->
<!--                              class="col-md-2 col-12  text-md-left text-right">-->
<!--            <ng-contianer [ngTemplateOutlet]="isMobile ? navSectionAccordionTitle : navSectionTitle"-->
<!--                          [ngTemplateOutletContext]="{$implicit: accordionItemHarbour, route:'/havn/alle',title: 'layout.nav_links.harbours', hasChildren: true}">-->

<!--            </ng-contianer>-->
<!--            <ul class="nav" [ngClass]="{'pr-2': isMobile}" [@bodyExpansion]="!accordionItemHarbour.expanded ? 'collapsed' : 'expanded'">-->
<!--              <ng-container [ngTemplateOutlet]="harbourLinks"></ng-container>-->
<!--            </ul>-->
<!--          </cdk-accordion-item>-->
          <!--Do not use *ngIf directive here for accordion toggle. Use ngClass or animation trigger-->
<!--          <cdk-accordion-item #accordionItemSjovenn="cdkAccordionItem" class="col-md-2 col-12 text-md-left text-right">-->
<!--            <ng-contianer [ngTemplateOutlet]="isMobile ? navSectionAccordionTitle : navSectionTitle"-->
<!--                          [ngTemplateOutletContext]="{$implicit:accordionItemSjovenn,route:'/meldinger',title: 'layout.nav_links.sjovenn', hasChildren: false}">-->

<!--            </ng-contianer>-->
<!--          </cdk-accordion-item>-->
          <!--Do not use *ngIf directive here for accordion toggle. Use ngClass or animation trigger-->
          <cdk-accordion-item [expanded]="!isMobile" #accordionItemAbout="cdkAccordionItem"
                              class="col-md-2 col-12 text-md-left text-right">
            <ng-contianer [ngTemplateOutlet]="isMobile ? navSectionAccordionTitle : navSectionTitle"
                          [ngTemplateOutletContext]="{$implicit: accordionItemAbout ,route:'/om-sjoliv', title: isMobile ? 'layout.nav_links.sjoliv' : 'layout.nav_links.about_sjoliv', hasChildren: true}">

            </ng-contianer>
            <ul class="nav pr-2" *ngIf="isMobile" [@bodyExpansion]="!accordionItemAbout.expanded ? 'collapsed' : 'expanded'">
              <li class="nav-item">
                <a class="nav-link" routerLink="/om-sjoliv">{{'layout.nav_links.about' | transloco}}</a>
              </li>
            </ul>

            <ul class="nav" [ngClass]="{'pr-2': isMobile}" [@bodyExpansion]="!accordionItemAbout.expanded ? 'collapsed' : 'expanded'">
              <li class="nav-item">
                <a class="nav-link" href="https://rs.no/kundeservice/"
                   target="_blank">{{'layout.nav_links.contact' | transloco}}</a>
              </li>
            </ul>
          </cdk-accordion-item>

          <ng-container *ngIf="user">
            <cdk-accordion-item [expanded]="!isMobile" #accordionItemUser="cdkAccordionItem"
                                class="col-md-2 col-12 text-md-left text-right">
              <ng-contianer [ngTemplateOutlet]="isMobile ? navSectionAccordionTitle : navSectionTitle"
                            [ngTemplateOutletContext]="{$implicit: accordionItemUser ,route:'/bruker/min-side', title: user.name, hasChildren: true , withoutTranslation: true }">

              </ng-contianer>
              <ul class="nav" [ngClass]="{'pr-2': isMobile}" [@bodyExpansion]="!accordionItemUser.expanded ? 'collapsed' : 'expanded'">
                <ng-container [ngTemplateOutlet]="authenticatedLinks"></ng-container>
              </ul>
            </cdk-accordion-item>
          </ng-container>
        </div>
      </div>
    </nav>
  </div>
</header>

<ng-template #topBarTemplate>
  <div class="row navbar bg-transparent align-items-center menu-bar">
    <div class="col">
      <a class="d-block" [routerLink]="'/'">
        <img src="assets/icons/logo_2.png" class="rs-logo"/>
      </a>
    </div>
    <div class="col">
      <div class="d-flex align-items-center justify-content-end">
        <ng-container
          [ngTemplateOutlet]="(user && !isMobile && isAuthenticated) ? userProfileTemplate : signInPromptTemplate">
        </ng-container>
        <button type="button"
                [class]="'btn btn-menu'+' '+ toolbarTextColor"
                [ngClass]="{'active': isActiveMenu}"
                aria-label="Toggle navigation"
                (click)="toggleMenu()">
          <ng-container *ngIf="!isActiveMenu">
            <mat-icon>menu</mat-icon>
            {{'action_buttons.menu' | transloco}}</ng-container>
          <ng-container *ngIf="isActiveMenu">
            <mat-icon>close</mat-icon>
            {{'action_buttons.close' | transloco}}</ng-container>
        </button>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #navSectionTitle let-route="route" let-sectionTitle="title" let-hasChildren="hasChildren"
             let-withoutTranslation="withoutTranslation">
  <ul class="nav">
    <li class="nav-item">
      <a class="nav-link title"
         [routerLink]="[route]"> {{ withoutTranslation ? sectionTitle : (sectionTitle | transloco)}} </a>
    </li>
  </ul>
</ng-template>

<ng-template #navSectionAccordionTitle let-accordionItem let-route="route" let-sectionTitle="title"
             let-hasChildren="hasChildren" let-withoutTranslation="withoutTranslation">
  <ul (click)="accordionItem.toggle()" class="nav">
    <li class="nav-item">
      <a
        class="nav-link title d-flex align-items-center justify-content-end"> {{ withoutTranslation ? sectionTitle : (sectionTitle | transloco)}}
        <mat-icon *ngIf="hasChildren" class="text-dark">arrow_drop_down</mat-icon>
        <mat-icon *ngIf="!hasChildren"></mat-icon>
      </a>
    </li>
  </ul>
</ng-template>

<ng-template #signInPromptTemplate>
  <ng-container *ngIf="(!isAuthenticated && !user)">
    <button
      *ngIf="!isMobile"
      type="button"
      class="btn btn-menu d-flex align-items-center"
      [ngClass]="toolbarTextColor"
      [routerLink]="['/auth/login']">
      <mat-icon>person_outline</mat-icon>
      {{'layout.nav_links.log_in' | transloco}}
    </button>
  </ng-container>
</ng-template>

<ng-template #userProfileTemplate>
  <button [matMenuTriggerFor]="userMenu" [disabled]="isActiveMenu" type="button"
          class="btn btn-menu d-flex align-items-center text-capitalize"
          [ngClass]="toolbarTextColor">
    <img class="user-profile-pic mr-2" [src]="user?.picture || 'assets/images/user.png' ">
    {{user.name}}
    <mat-icon [class]="toolbarTextColor">arrow_drop_down</mat-icon>
  </button>
</ng-template>

<ng-template #courseLinks>
  <li class="nav-item">
    <a class="nav-link"
       [routerLink]="['/alle-kurs']" [queryParams]="{ kurstype: 'fysiske' }">{{'layout.nav_links.classroom' | transloco}}
    </a>
  </li>
  <li class="nav-item">
    <a class="nav-link"
       [routerLink]="['/alle-kurs']" [queryParams]="{ kurstype: 'digitale' }">{{'layout.nav_links.digital' | transloco}}
    </a>
  </li>
  <li class="nav-item">
    <a class="nav-link"
       [routerLink]="['/instruktor/alle']">{{'layout.nav_links.instructors' | transloco}}
    </a>
  </li>
  <li class="nav-item">
    <a class="nav-link"
       [routerLink]="['/kursoversikt']">{{'layout.nav_links.about_course_types' | transloco}}
    </a>
  </li>
  <li class="nav-item">
    <a class="nav-link"
       [routerLink]="['/kursoversikt-for-tilbydere']">{{'layout.nav_links.become_an_instructor' | transloco}}</a>
  </li>
</ng-template>

<ng-template #harbourLinks>
  <li class="nav-item">
    <a class="nav-link" [routerLink]="['/havn/alle']">{{'layout.nav_links.natural_harbours' | transloco}}</a>
  </li>
  <li class="nav-item">
    <a class="nav-link" [routerLink]="['/havn/alle']">{{'layout.nav_links.guest_harbours' | transloco}}</a>
  </li>
</ng-template>

<ng-template #aboutSjolivLinks>

</ng-template>
<ng-template #authenticatedLinks>
  <li class="nav-item">
    <a class="nav-link" [routerLink]="['/bruker/min-side']">{{'layout.nav_links.profile' | transloco}}</a>
  </li>
  <li class="nav-item">
    <a class="nav-link"
       [routerLink]="['/booking/mine-bookinger/kurs/aktiv']">{{'layout.nav_links.my_courses' | transloco}}</a>
  </li>
  <li class="nav-item">
    <a class="nav-link" (click)="logout()">{{'layout.nav_links.log_out' | transloco}}</a>
  </li>
</ng-template>
<mat-menu #userMenu="matMenu" [class]="'bg-white'">
  <ul class="nav d-block">
    <ng-container [ngTemplateOutlet]="authenticatedLinks"></ng-container>
  </ul>
</mat-menu>
