import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from "@angular/forms";
import { Router } from "@angular/router";
import { CustomerFeedbackApiService } from "@shared/services/customer-feedback.api.service";
import { CustomerFeedback } from "@shared/models/CustomerFeedback";
import { IdentityStoreService } from "@modules/identity/store/services/identity-store.service";

@Component({
  selector: 'customer-feedback',
  templateUrl: './customer-feedback.component.html',
  styleUrls: ['./customer-feedback.component.scss']
})
export class CustomerFeedbackComponent implements OnInit {
  isFeedbackSent: boolean = false;
  customerFeedbackForm: FormGroup;

  constructor(private formBuilder: FormBuilder,
              private identityStoreService: IdentityStoreService,
              private router: Router,
              private customerFeedbackApiService: CustomerFeedbackApiService,
  ) { }

  ngOnInit(): void {
    this.createCustomerFeedbackForm();

    this.identityStoreService.getUserProfile().pipe(
    ).subscribe(
      (userProfile) => {
        this.customerFeedbackForm.patchValue({email: userProfile?.email});
      }
    );
  }

  private createCustomerFeedbackForm() {
    this.customerFeedbackForm = this.formBuilder.group({
        isFound: [ null ],
        description: [ '' ],
        email: [ null ]
      }
    );
  }

  public submitCustomerFeedbackForm() {
    const pageLink = this.router.url;
    const feedback: CustomerFeedback = {...this.customerFeedbackForm.getRawValue(), pageLink};
    this.customerFeedbackApiService.addCustomerFeedback(feedback).subscribe(
      (response) => {
        this.isFeedbackSent = true;
      }
    );
  }

  clearFeedback() {
    this.customerFeedbackForm.get('description').setValue('');
  }
}
