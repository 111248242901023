import { Injectable, Predicate } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { Culture } from '../../../models/culture';
import * as selector from '../culture.selector';
import { AddError, AddTotalCount, FetchCultures, GetCulture } from '../culture.action';
import { CultureState } from '../../culture-store/culture.state';

@Injectable()
export class CultureStoreService {
  constructor(private store: Store<CultureState>) {}

  public fetchAllCulture(
    search: string = '',
    pageIndex: number = 0,
    pageSize: number = 4,
    onlyNeutral: boolean = true,
    locations: string[] = []
  ): void {
    this.store.dispatch(
      FetchCultures({
        search: search,
        pageIndex: pageIndex,
        pageSize: pageSize,
        onlyNeutral: onlyNeutral,
        locations: locations,
      })
    );
  }

  public getAllCultures(): Observable<Culture[]> {
    return this.store.pipe(select(selector.getAllCultures));
  }

  public getCulturesBySearch(predicate: Predicate<Culture>): Observable<Culture[]> {
    return this.store.pipe(select(selector.getCulturesBySearch(predicate)));
  }

  public getCultureByCode(twoLetterCode: string): Observable<Culture> {
    return this.store.pipe(select(selector.getCultureBySearch((x) => x.twoLetterISOLanguageCode === twoLetterCode)));
  }

  public getCultureById(id: string): Observable<Culture> {
    return this.store.select(selector.getCultureById(id));
  }

  public addTotalCount(data: number): void {
    this.store.dispatch(AddTotalCount({ totalCount: data }));
  }

  public getTotalCount(): Observable<number> {
    return this.store.select(selector.selectTotalCount);
  }

  public loadError(error: any): void {
    this.store.dispatch(AddError(error));
  }

  public fetchCultureById(id: string): void {
    this.store.dispatch(GetCulture({ id: id }));
  }
}
