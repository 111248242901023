export enum UserRoles {
  INSTRUCTOR = 'INSTRUCTOR',
  SYSTEM_ADMIN = 'SYSTEM ADMIN',
  SERVICE_PROVIDER = 'SERVICE PROVIDER',
  GUIDE = 'GUIDE',
}

export interface UserProfile {
  id?: string;
  sub?: string;
  email: string;
  password?: string;
  email_verified?: boolean;
  secondary_email?: string;
  phone_number?: string;
  phone_number_verified?: boolean;
  gender?: string;
  name?: string;
  family_name: string;
  middle_name?: string;
  given_name: string;
  picture?: string;
  website?: string;
  birthdate?: Date;
  locale?: string;
  roles?: string[];
  referCode?: string;

  // Not Map
  status?: string;
}

export interface RsUserProfile {
  name: string;
  email: string;
}
