export interface EmergencyContactRequest{
  token : string,
  status : RequestStatus
}

export enum RequestStatus {
  REQUESTED = 'Requested',
  APPROVED = 'Approved',
  REJECTED = 'Rejected'
}
