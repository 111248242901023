import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { Route, RouterModule } from '@angular/router';
import { MaterialModule } from '@shared/material/material';
import { EmergencyContactRequestComponent } from "@modules/tryggbat/components/emergency-contact-request/emergency-contact-request.component";
import { FlexModule } from "@angular/flex-layout";
import { TranslocoModule } from '@ngneat/transloco';
import { SecurboatAppLinkComponent } from './components/securboat-app-link/securboat-app-link.component';

const routes: Route[] = [
  {
    path: 'emergency-contact-request',
    component: EmergencyContactRequestComponent,
  },
  {
    path: "securboat-invitation",
    component: SecurboatAppLinkComponent,
  }
];

@NgModule({
  declarations: [
    EmergencyContactRequestComponent,
    SecurboatAppLinkComponent
  ],
  exports: [
  ],
    imports: [
        MaterialModule,
        CommonModule,
        FlexModule,
        RouterModule,
        TranslocoModule,
    ],
  providers: [
  ],
})
export class TryggbatModule {}
