<a [routerLink]="[baseRoute,courseSlug]" class="rounded-0 border-0"
   [ngClass]="{'medium-card': (size === 'medium'),'small-card': (size === 'small')}">
  <div class="position-relative">
    <div class="thumbnail-img" ngImagePixel #MediaumImageHolder="imagePixel">
      <img class="card-image-top img-fluid rounded-0" [alt]="course?.title" src="{{courseImageUrl
      }}?{{MediaumImageHolder.getImagePixel()}}&fit=crop&auto=compress,enhance" alt="Card image cap"/>
    </div>
    <div
      *ngIf="!isClassroomCourse(courseCardType)"
      class="company-header-avatar position-absolute"
    ><img [src]="providerImageUrl" alt="avatar">
    </div>

    <div
      *ngIf="isClassroomCourse(courseCardType)"
      class="avatar position-absolute"
      style="background-image: url({{providerImageUrl+'?w=240'}})"
    ></div>
  </div>
  <div class="course-details my-3">
    <div class="row no-gutters">
      <h5 class="course-title col-12">{{ course?.title }}</h5>
      <div class="col-12 px-0">
        <div class="course-info col px-0">
          <div class="row mx-0 mb-1">
            <p
              class="course-provider text-truncate"
              [ngClass]="{'text-truncate': courseCardType !== courseCardTypes.DIGITAL_COURSE}"
              [matTooltip]="providerName"
              [matTooltipDisabled]="courseCardType === courseCardTypes.DIGITAL_COURSE"
            >{{ providerName }}</p>
            <div *ngIf="courseLocation" class="d-flex align-items-center pl-4">
              <mat-icon class="spot-icon">lense</mat-icon>
              <p class="m-0 pl-2">{{ courseLocation }}</p>
            </div>
          </div>

          <div class="row mx-0">
            <div class="course-price">
              <ng-container [ngTemplateOutlet]="isPriceHidden ? priceHiddenTemplate : nonMemberPriceTemplate">
              </ng-container>
            </div>
            <div class="course-price">
              <ng-container [ngTemplateOutlet]=" isPriceHidden ? priceHiddenTemplate: memberPriceTemplate"></ng-container>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <span class="badge badge-primary">{{ courseCategoryName }}</span>
</a>

<ng-template #freeCourseTemplate>
  <span class="course-price-non-member">{{'shared.free' | transloco}}</span>
</ng-template>

<ng-template #nonMemberPriceTemplate>
  <ng-container *ngIf="!isCourseFree;else freeCourseTemplate">
    <span class="course-price-non-member">{{ getFormattedPrice(nonMemberPrice) }}</span> <span class="pg-small"> NOK</span>
  </ng-container>
</ng-template>

<ng-template #memberPriceTemplate>
  <ng-container *ngIf="(!isCourseFree && (memberPrice > 0));else priceHiddenTemplate">
    <div class="d-flex flex-wrap align-items-baseline">
      <div class="pg-small">{{'shared.rs_member' | transloco}}: &nbsp;</div>
      <div><span class="course-price-member">{{ getFormattedPrice(memberPrice) }}</span> <span class="pg-small"> NOK</span></div>
    </div>
  </ng-container>
</ng-template>

<ng-template #priceHiddenTemplate>
  <div class="course-price-empty-height"></div>
</ng-template>
