import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AboutComponent } from '@modules/privacy-policy/components/about/about.component';
import { PrivacyPolicyComponent } from '@modules/privacy-policy/components/privacy-policy/privacy-policy.component';
import { TermsAndConditionComponent } from '@modules/privacy-policy/components/terms-and-condition/terms-and-condition.component';
import { EmergencyContactRequestComponent } from '@modules/tryggbat/components/emergency-contact-request/emergency-contact-request.component';
import { AboutMetaResolver } from '@modules/privacy-policy/resolvers/about-meta.resolver';
import { DefaultLayoutComponent } from './layout/default/default.component';
import { ErrorPageLayoutComponent } from './layout/error-layout/error-layout.component';
import { ForbiddenPageComponent } from './layout/pages/forbidden-page/forbidden-page.component';
import { NotFoundPageComponent } from './layout/pages/not-found/not-found.component';
import { ServerErrorPageComponent } from './layout/pages/server-error/server-error.component';
import { UnauthorizePageComponent } from './layout/pages/unauthorize-page/unauthorize-page.component';
import { MaintenanceComponent } from "./layout/pages/maintenance/maintenance.component";
import { OidcRedirectComponent } from "@core/components/oidc-redirect/oidc-redirect.component";
import { AutoLoginPartialRoutesServerSideGuard } from "@core/guards/auto-login-partial-routes-server-side.guard";
import { SecurboatAppLinkComponent } from '@modules/tryggbat/components/securboat-app-link/securboat-app-link.component';

const routes: Routes = [
  {
    path: '',
    component: DefaultLayoutComponent,
    children: [
      {
        path: '',
        pathMatch: 'full',
        loadChildren: () => import('./modules/home/home.module').then((m) => m.HomeModule),
      },
      {
        path: 'auth',
        loadChildren: () => import('./modules/identity/identity.module').then((m) => m.IdentityModule),
      },
      {
        path: 'instruktor',
        loadChildren: () => import('./modules/instructor/instructor.module').then((m) => m.InstructorModule),
      },
      {
        path: 'kurs',
        loadChildren: () => import('./modules/course/classroom/classroom.module').then((m) => m.ClassroomModule),
      },
      {
        path: 'digitalkurs',
        loadChildren: () => import('./modules/course/digitalcourse/digital-course.module').then((m) => m.DigitalCourseModule),
      },
      {
        path: 'kursoversikt',
        loadChildren: () => import('./modules/template/student/template.module').then((m) => m.TemplateModule),
      },
      {
        path: 'kursoversikt-for-tilbydere',
        loadChildren: () => import('./modules/template/instructor/instructor-template.module').then((m) => m.InstructorTemplateModule),
      },
      {
        path: 'alle-kurs',
        loadChildren: () => import('./modules/course/all-course/all-course.module').then((m) => m.AllCourseModule)
      },
      {
        path: 'havn',
        redirectTo: ''
        //loadChildren: () => import('./modules/harbour/harbour.module').then((m) => m.HarbourModule),
      },
      {
        path: 'harbour',
        redirectTo: 'havn'
      },
      {
        path: 'partner',
        redirectTo: ''
        //loadChildren: () => import('./modules/partner/partner.module').then((m) => m.PartnerModule),
      },
      {
        path: 'kart',
        redirectTo: ''
        //loadChildren: () => import('./modules/map/map.module').then((m) => m.MapModule),
      },
      {
        path: 'meldinger',
        redirectTo: ''
        //loadChildren: () => import('./modules/message/message.module').then((m) => m.MessageModule),
      },
      {
        path: 'bruker',
        canActivate:[AutoLoginPartialRoutesServerSideGuard],
        canActivateChild:[AutoLoginPartialRoutesServerSideGuard],
        loadChildren: () => import('./modules/user/user.module').then((m) => m.UserModule),
      },
      {
        path: 'payment',
        canActivate:[AutoLoginPartialRoutesServerSideGuard],
        canActivateChild:[AutoLoginPartialRoutesServerSideGuard],
        loadChildren: () => import('./modules/payment/payment.module').then((m) => m.PaymentModule)
      },
      {
        path: 'booking',
        canActivate:[AutoLoginPartialRoutesServerSideGuard],
        canActivateChild:[AutoLoginPartialRoutesServerSideGuard],
        loadChildren: () => import('./modules/booking/booking.module').then((m) => m.BookingModule)
      },
      {
        path: 'om-sjoliv',
        component: AboutComponent,
        resolve:[AboutMetaResolver],
      },
      {
        path: 'signin-oidc',
        component: OidcRedirectComponent
      }
    ],
  },
  {
    path: "privacy-policy",
    component: PrivacyPolicyComponent,
  },
  {
    path: "terms-and-condition",
    component: TermsAndConditionComponent,
  },
  {
    path: "emergency-contact-request",
    component: EmergencyContactRequestComponent,
  },
  {
    path: "securboat-invitation",
    component: SecurboatAppLinkComponent,
  },
  {
    path: "maintenance",
    component: MaintenanceComponent,
  },
  {
    path: 'error',
    component: ErrorPageLayoutComponent,
    children: [
      {
        path: 'unauthorize',
        component: UnauthorizePageComponent,
      },
      {
        path: 'forbidden',
        component: ForbiddenPageComponent,
      },
      {
        path: 'not-found',
        component: NotFoundPageComponent,
      },
      {
        path: 'server-error',
        component: ServerErrorPageComponent,
      },
    ],
  },
  {path: '**', redirectTo: 'error/not-found', pathMatch: 'full'}
  // { path: 'cb', redirectTo: '', pathMatch: 'full' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'enabled',
      initialNavigation: 'enabledBlocking',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
