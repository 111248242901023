import { Action, ActionReducerMap, createReducer, MetaReducer, on } from '@ngrx/store';
import { routerReducer } from '@ngrx/router-store';
import { storeFreeze } from 'ngrx-store-freeze';

import { AppState } from './app.state';
import * as fromAction from './app.action';
import { environment } from '@env/environment';

const initialState: AppState = {
  isDropdownShow: false,
  isColorNotChange: false,
  stopDropdownRedirect: false,
  isHomePage: false,
  shareGeolocation: null,
  currentLocation: {
    coords: { lon: 1.0, lat: 1.0 },
    address: null,
  },
  selectMessagePost: false,
  infoPopupShown: false,
  error: null,
};

const _appReducer = createReducer(
  initialState,
  on(fromAction.LoadFailure, (state, { error }) => {
    return { ...state, error: error };
  }),

  on(fromAction.ShowDropdown, (state, { view }) => {
    return { ...state, isDropdownShow: view };
  }),

  on(fromAction.DropdownColorNotChange, (state, { change }) => {
    return { ...state, isColorNotChange: change };
  }),

  on(fromAction.StopRedirectForDropDown, (state, { change }) => {
    return { ...state, stopDropdownRedirect: change };
  }),

  on(fromAction.HomePage, (state, { change }) => {
    return { ...state, isHomePage: change };
  }),

  on(fromAction.ShareGeolocation, (state, { change }) => {
    return { ...state, shareGeolocation: change };
  }),

  on(fromAction.CurrentLocation, (state, { change }) => {
    return { ...state, currentLocation: change };
  }),

  on(fromAction.InfoPopupShown, (state, { change }) => {
    return { ...state, infoPopupShown: change };
  }),
  on(fromAction.SelectMessagePost, (state, { change }) => {
    return { ...state, selectMessagePost: change };
  })
);

export function appReducer(state: AppState = initialState, action: Action): AppState {
  return _appReducer(state, action);
}

export interface AppStore {}

export const reducers: ActionReducerMap<AppStore> = {
  router: routerReducer,
  app: appReducer,
  // signalr: signalrReducer,
};

export const metaReducers: MetaReducer<AppStore>[] = !environment.production
  ? [storeFreeze]
  : [];
