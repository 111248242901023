import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { RsUserProfile, UserProfile } from '../../models/user';
import { IdentityState } from '../identity.state';
import * as fromAction from '../identity.action';
import * as fromSelector from '../identity.selector';

@Injectable()
export class IdentityStoreService {
  constructor(private store: Store<IdentityState>) {}

  public addTotalCount(data: number): void {
    this.store.dispatch(fromAction.AddTotalCount({ totalCount: data }));
  }

  public loadError(error: any): void {
    this.store.dispatch(fromAction.AddError(error));
  }

  public setUserProfile(user: UserProfile): void {
    this.store.dispatch(fromAction.AddUserProfile({ user: user }));
  }

  public getUserProfile(): Observable<UserProfile | null> {
    return this.store.select(fromSelector.getUserProfile);
  }

  public updateUserProfile(id: string, user: UserProfile): void {
    this.store.dispatch(fromAction.UpdateUserProfile({ id: id, user: user }));
  }

  public fetchUserProfile(): void {
    this.store.dispatch(fromAction.FetchUserProfile());
  }

  public createUserProfile(user: RsUserProfile): void {
    this.store.dispatch(fromAction.CreateUserProfile({ user }));
  }

  public sendVerificationOtp(campaignId: string, phoneNo: string): void {
    this.store.dispatch(fromAction.SendVerificationOtp({ campaignId, phoneNo }));
  }

  public removeVerificationCode(): void {
    return this.store.dispatch(fromAction.RemoveVerificationOtp());
  }

  public redeemOTP(otp: string, bookingId: string, courseId: string, userId: string): void {
    this.store.dispatch(fromAction.RedeemCode({ otp, bookingId, courseId, userId }));
  }

  public getIsSendOtp(): Observable<boolean> {
    return this.store.select(fromSelector.getSendOtp);
  }

  public updateRedeemOtpStatus(isValidOtp: boolean): void {
    this.store.dispatch(fromAction.UpdateRedeemVerifyStatus({ isValidOtp }));
  }

  public getRedeemOtpStatus(): Observable<boolean> {
    return this.store.select(fromSelector.getRedeemOtpStatus);
  }

  public lastLoggedIn(): void {
    this.store.dispatch(fromAction.LastLoggedIn());
  }
}
