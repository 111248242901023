import { Component, Inject, OnInit, Optional, ViewEncapsulation } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router, RouterEvent } from '@angular/router';
import { DeviceDetectorService } from 'ngx-device-detector';
import { fromEvent, Observable } from 'rxjs';
import { AppStoreService } from '@core/store/app-store.service';
import { StorageService } from '@core/services/storage.service';
import { PlatformService } from '@core/services/platform.service';
import { FeedbackRestrictedPageList } from '@shared/constants/feedback-restricted-pages';
import { SERVER_SIDE } from '../../../provider.token';
import { BreakpointObserver, BreakpointState } from '@angular/cdk/layout';
import { filter, map, startWith } from 'rxjs/operators';
import { HANDHELD_DEVICE_BREAKPOINTS } from '../contants/handheld-device-breakpoints';
import { AuthService } from '@core/services/auth.service';
import { UserProfile } from '@modules/identity/models/user';
import { IdentityStoreService } from '@modules/identity/store/services/identity-store.service';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'default-layout',
  templateUrl: './default.component.html',
  styleUrls: ['./default.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class DefaultLayoutComponent implements OnInit {
  userProfile$: Observable<UserProfile | null>;
  selectMessagePost$: Observable<boolean>;
  currentPath: string;
  handheldDeviceBreakpointState$: Observable<BreakpointState>;
  scrolled$: Observable<boolean>;
  currentUrl$: Observable<string>;
  isAuthenticated$: Observable<boolean>;
  isPreventScroll: boolean = false;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private deviceDetectService: DeviceDetectorService,
    private breakpointObserver: BreakpointObserver,
    private appStore: AppStoreService,
    private storage: StorageService,
    public platform: PlatformService,
    private authService: AuthService,
    private identityStoreService: IdentityStoreService,
    @Inject(DOCUMENT) private document: Document,
    @Optional() @Inject(SERVER_SIDE) private serverSide: boolean
  ) {
  }

  ngOnInit() {
    this.currentUrl$ = this.router.events.pipe(
      startWith('initialRoute'), // this is needed as first router events got missed due to component rendering after first route
      filter((routeEvent: RouterEvent | string) => Boolean(routeEvent instanceof NavigationEnd) || (routeEvent === 'initialRoute')),
      map(() => this.router.url.toString())
    );
    this.isAuthenticated$ = this.authService.isAuthenticated$.pipe(map(({isAuthenticated}) => isAuthenticated));
    this.handheldDeviceBreakpointState$ = this.breakpointObserver.observe(HANDHELD_DEVICE_BREAKPOINTS).pipe(map((breakPointState: BreakpointState) => breakPointState));
    this.scrolled$ = fromEvent(this.document, 'scroll').pipe(map((event) => Boolean(this.document.scrollingElement.scrollTop > 50)));
    this.selectMessagePost$ = this.appStore.getSelectMessagePost();
    this.userProfile$ = this.identityStoreService.getUserProfile();
    this.currentPath = this.activatedRoute.firstChild.snapshot.routeConfig.path;
  }

  /**
   * @Depracted Replaced by handheldDeviceBreakPointState$. Replacing device based conditions with viewport sizes.
   * @returns {boolean}
   */
  public isShowBottomMenu(): boolean {
    const isMobile = this.deviceDetectService.isMobile();
    const isTablet = this.deviceDetectService.isTablet();
    return isMobile || isTablet;
  }

  public isLoginPage(): boolean {
    return this.router.url === '/auth/login';
  }

  public isMapPage(): boolean {
    return this.currentPath === 'kart';

  }

  public showCustomerFeedback(): boolean {
    return !FeedbackRestrictedPageList.includes(this.currentPath);
  }

  public signOutUser(): void {
    this.authService.logout();
  }

  public toggleScroll(isPreventScroll: boolean): void {
    this.isPreventScroll = isPreventScroll;
  }

  /**
   * @todo: investigate and remove it
   */
  public isShowToolBar(): boolean {
    const value = this.storage.getItem('apps');
    if (value && value === 'app-guide-onboarding') {
      return false;
    } else {
      return true;
    }
  }
}
