import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { MetaTagService } from '@modules/core/services/meta-tag.service';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AboutMetaResolver implements Resolve<boolean> {
  
  constructor(private metaService: MetaTagService) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot,): Observable<boolean> {
    this.metaService.aboutPageOGMetaTag();
    return of(true);
  }
}
