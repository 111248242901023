import { Component, ElementRef, Inject, NgZone, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IMarker } from '@modules/shared/models/marker';
import * as mapboxgl from 'mapbox-gl';
import * as MapboxGeocoder from '@mapbox/mapbox-gl-geocoder';
import { MapConfig } from '@modules/shared/models/mapbox';
import { ConfigService } from '@modules/core/services/config.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { TRANSLOCO_SCOPE } from '@ngneat/transloco';

@Component({
  selector: 'app-mapbox-dialog',
  templateUrl: './mapbox-dialog.component.html',
  styleUrls: ['./mapbox-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class MapboxDialogComponent implements OnInit {
  @ViewChild('mapDialog', { static: true }) container;
  public zoom = 15;

  // initial center position for the map
  public lat = 59.91273;
  public lng = 10.74609;

  public geocoder: MapboxGeocoder;
  map: mapboxgl.Map;
  marker: mapboxgl.Marker;
  mapConfig: MapConfig;
  public markerList: IMarker[] = [];
  @ViewChild('search') public searchElementRef: ElementRef;
  searchText: string;
  constructor(
    public matDialogRef: MatDialogRef<MapboxDialogComponent>,
    public snackBar: MatSnackBar,
    @Inject(MAT_DIALOG_DATA) private _data: MapConfig,
    private configService: ConfigService) { }

  ngOnInit(): void {
    this.mapConfig = this._data;
    this.createMap();
  }
  createMap() {
    this.map = new mapboxgl.Map({
      accessToken: this.configService.getCurrent().mapBox.accessToken,
      container: 'mapDialog',
      style: 'mapbox://styles/mapbox/streets-v11',
      center: [0, 0],
      zoom: 15,
    }).addControl(new mapboxgl.NavigationControl(),'bottom-right');

    this.marker = new mapboxgl.Marker();
    const { clientWidth, clientHeight } = this.container.nativeElement;

    this.map.on('click', (res) => {
      this.marker.setOffset([clientWidth/10, clientHeight/10])
      this.markerList[0] = {
        lat: res.lngLat.lat,
        lng: res.lngLat.lng,
      };
      this.addMarker(res.lngLat.lat, res.lngLat.lng);
    });
    if (this.mapConfig.markers.length > 0) {
      const marker = this.mapConfig.markers[0];
      this.markerList[0] = marker;
      this.addMarker(marker.lat, marker.lng);
    }
    this.setCurrentLocation();
    this.createSearchBar();
  }

  createSearchBar() {
    const geocoder = new MapboxGeocoder({
      accessToken: this.configService.getCurrent().mapBox.accessToken,
      mapboxgl: this.map,
      marker: false,
      zoom: 15,
    });
    document
      .getElementById('search-input')
      .appendChild(geocoder.onAdd(this.map));
    geocoder.on('result', (res) => {
      const { result } = res;
      this.markerList[0] = {
        lat: result.center[1],
        lng: result.center[0],
      };
      this.addMarker(result.center[1], result.center[0]);
    });
  }

  public setPosition(): void {
    if (this.markerList.length > 0) {
      const marker = {
        lat: parseFloat(this.markerList[0].lat.toFixed(5)),
        lng: parseFloat(this.markerList[0].lng.toFixed(5)),
      };
      this.matDialogRef.close(this.markerList[0]);
    } else {
      this.snackBar.open('No position is selected', '', {
        duration: 2000,
      });
    }
  }

  public addMarker(lat: number, lng: number) {
    this.marker.setLngLat([lng, lat]);
    this.marker.addTo(this.map);
  }

  private setCurrentLocation(): void {
    if (this.markerList.length > 0) {
      this.map.setCenter([this.markerList[0].lng, this.markerList[0].lat]);
      return;
    }
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.lat = position.coords.latitude;
        this.lng = position.coords.longitude;
        this.map.setCenter([this.lng, this.lat]);
      });
    }
  }
}
