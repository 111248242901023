// import { environment } from './environments/environment';
// import { enableProdMode, StaticProvider } from '@angular/core';
// import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
// import { SERVER_SIDE } from '@mintplayer/ng-server-side';
// import { CONFIG } from "./provider.token";
// import { Config, devConfig } from "@core/models/config";
// import { AppModule } from './app/app.module';
//
// let appServerConfig: Config = {...devConfig};
//
// const setConfig = (data: Config): void => {
//   appServerConfig = {...appServerConfig, ...data};
// };
//
// export const getConfig = (): Config => Object.freeze({...appServerConfig});
//
// const bootstrap = () => {
//   const providers: StaticProvider[] = [
//     { provide: SERVER_SIDE, useValue: false },
//     { provide: CONFIG, useValue: getConfig() }
//   ];
//   platformBrowserDynamic(providers).bootstrapModule(AppModule)
//     .catch(err => console.error(err));
// };
// const initializeApp = (): void => {
//   if (environment.production) {
//     enableProdMode();
//   }
//   if (document.readyState === 'complete') {
//     bootstrap();
//   } else {
//     document.addEventListener('DOMContentLoaded', bootstrap);
//   }
// };
//
// const getApiConfigs: any = async () => {
//   if (environment.production) {
//     try {
//       fetch('./config')
//         .then((response) => {
//           if (response.ok) {
//             return response.json();
//           }
//           throw new Error('Not Found');
//         })
//         .then((configObj) => {
//           setConfig(configObj);
//         })
//         .catch(err => console.log('error from config api call', err))
//         .finally(initializeApp);
//     } catch (error) {
//       console.log(error);
//     }
//   } else {
//     setConfig(devConfig);
//     initializeApp();
//   }
// };
//
// getApiConfigs();
import { enableProdMode, StaticProvider } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { SERVER_SIDE } from '@mintplayer/ng-server-side';

import { AppModule } from './app/app.module';
import { environment } from '@env/environment';
import { CONFIG } from "./provider.token";
import { devConfig } from "@core/models/config";

if (environment.production) {
  enableProdMode();
}

async function bootstrap() {
  const config = await fetch('./config').then((response) => {
    if (response.ok) {
      return response.json();
    }
    return devConfig;
  }).catch(err => console.log('error from config api call', err));
  const providers: StaticProvider[] = [
    { provide: SERVER_SIDE, useValue: false },
    { provide: CONFIG, useValue: config}
  ];

  platformBrowserDynamic(providers).bootstrapModule(AppModule)
    .catch(err => console.error(err));
}

if (document.readyState === 'complete') {
  bootstrap();
} else {
  document.addEventListener('DOMContentLoaded', bootstrap);
}
