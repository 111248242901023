import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ConfigService } from '@core/services/config.service';
import { BaseApiService } from '@core/http/base.api.service';
import { PageList } from '@core/models/page-list';
import { UserLocation } from '@shared/models/user-location';

@Injectable()
export class UserLocationApiService extends BaseApiService {
  constructor(public http: HttpClient, private configService: ConfigService) {
    super(http);
  }

  private get baseUrl(): string {
    const config = this.configService.getCurrent();
    return `${config.services.experience}/api/userlocation`;
  }

  deleteUserLocation(id: string): Observable<any> {
    return this.http.delete<any>(`${this.baseUrl}/${id}`);
  }

  getAllUserLocations(
    from: string,
    to: string,
    userId: string,
    search: string,
    positions: string,
    pageOffset: number = 0,
    pageSize: number = 100,
    sort?: string,
    lastUpdatedFilter: number | string = ''
  ): Observable<PageList<UserLocation>> {
    const params = new HttpParams()
      .append('from', from)
      .append('to', to)
      .append('userId', userId)
      .append('search', search)
      .append('positions', positions)
      .append('pageOffset', pageOffset.toString())
      .append('pageSize', pageSize.toString())
      .append('sort', sort)
      .append('lastUpdatedFilter',24);

    return super.getAllWithPagination<UserLocation>(this.baseUrl, params);
  }

  fetchLocationByUserId(userId: string): Observable<UserLocation> {
    return this.http.get<UserLocation>(`${this.baseUrl}/user/${userId}`);
  }

  toggleUserLocationPreference(id: string, allowLocation: boolean): Observable<UserLocation> {
    return this.http.put<UserLocation>(`${this.baseUrl}/enable/${id}/${allowLocation}`, {});
  }
}
