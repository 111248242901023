<section class="d-flex flex-column flex-md-row container pr-md-4">
  <div class="d-flex flex-column col-md-6 py-0 pl-0 pr-md-4">
    <h1 class="mb-lg-4 mb-md-2">Hva er RS Sjøliv</h1>
    <p class="paragraph">
      På RS Sjøliv kan du som instruktør legge ut dine kurs og du som fritidsbåtbruker kan tilegne deg kunnskap og ta
      nødvendige sertifikater.
    </p>
    <p class="paragraph mb-3">
      Her finner du som ønsker å delta på kurs forskjellige digitale teorikurs og fysiske kurs over hele landet. Vi
      ønsker å gjøre det enkelt for deg å øke din kompetanse på sjøen, og via RS Sjøliv får du derfor tilgang på kurs
      og kursinstruktører som er kvalitetsstemplet fra Redningsselskapet. Redningsselskapet fungerer som en
      tilrettelegger ved å tilby en kursplattform med kvalitetssikret kursinnhold og godkjenning av instruktører.
      Dermed kan eksterne kursleverandører opprette, selge og administrere kurs som er godkjente av RS på forhånd.
    </p>
    <img [src]="banner.group" class="img-fluid rounded mt-auto" alt="Rescue group">
  </div>

  <div class="d-flex flex-column col-md-6 p-0 ml-auto pl-md-4">
    <img [src]="banner.jetski" class="img-fluid rounded d-none d-md-inline mb-4 pb-4" alt="Jet ski">
    <h1 class="mb-lg-4 mb-md-2 mt-4 mt-md-0">Vil du bli kurstilbyder?</h1>
    <p class="paragraph">
      Tilbyr du maritime kurs? Her får du som instruktør tilgang på en bookingplattform hvor du kan holde oversikt
      over dine kurs, deltakerlister, registrere oppmøte med mer.
    </p>
    <p class="paragraph">
      Redningsselskapet har utviklet en <a class="reference-link" [routerLink]="'/alle-kurs'">rekke ulike kurs</a> som
      du kan tilby via RS Sjøliv. Under de ulike kursene finner du det trenger av kursinnhold som er kvalitetssikret
      og i henhold til Sjøfartsdirektoratets norm. Redningsselskapet vil også markedsføre dine kurs til mer enn 120
      000 mottakere via vårt ukentlige nyhetsbrev og nesten 200 000 følgere på relevante sosiale plattformer.
    </p>
    <p class="paragraph mb-0">
      Vårt mål er å inspirere flere til å ta flere kurs for å øke sin kompetanse på sjøen – og vi håper at du vil være
      med å bidra! <a class="reference-link" [routerLink]="'/kursoversikt-for-tilbydere'">Les mer om hvordan du blir
      instruktør og send inn din søknad her.</a>
    </p>
    <img [src]="banner.jetski" class="img-fluid rounded d-md-none" alt="Jet ski">
  </div>
</section>
