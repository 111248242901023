import { Injectable } from '@angular/core';
import { HttpClient } from "@angular/common/http";
import { ConfigService } from "@core/services/config.service";
import { Observable } from "rxjs";
import { CustomerFeedback } from "@shared/models/CustomerFeedback";

@Injectable({
  providedIn: 'root'
})
export class CustomerFeedbackApiService {

  constructor(public http: HttpClient, private config: ConfigService) {
  }

  get baseUrl(): string {
    return `${this.config.getCurrent().services.experience}/api/customer`;
  }

  public addCustomerFeedback(customerFeedback: CustomerFeedback): Observable<CustomerFeedback> {
    return this.http.post<CustomerFeedback>(`${this.baseUrl}/feedback`, customerFeedback);
  }
}
