import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { locationStoreReducer } from './location.reducer';
import { LocationEffects } from './location.effects';
import { LocationStoreService } from './services/location-store.service';
import { LocationApiService } from './services/location-api.service';
import { LocationListResolver } from './services/location.list.resolver';
import { locationFeatureKey } from './location.state';

@NgModule({
  imports: [
    StoreModule.forFeature(locationFeatureKey, locationStoreReducer),
    EffectsModule.forFeature([LocationEffects]),
  ],
  providers: [LocationApiService, LocationStoreService, LocationListResolver],
})
export class LocationStoreModule {}
