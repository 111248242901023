import { ConfigService } from '@core/services/config.service';

export function getGoogleTagManagerId(config: ConfigService) {
  const c = config.getCurrent();
  return c.googleTagManager.gtm;
}

export function getGoogleTagManagerAuth(config: ConfigService) {
  const c = config.getCurrent();
  return c.googleTagManager.gtmAuth;
}

export function getGoogleTagManagerPreview(config: ConfigService) {
  const c = config.getCurrent();
  return c.googleTagManager.gtmPreview;
}
