export enum AlertMessageType {
    SENT = 'sent',
    SUCCESS = 'success',
    SUCCESSFUL = 'successful',
    SAVED = 'saved',
    INFO = 'info',
    WARNING = 'warning',
    ERROR = 'error',
    BADREQUEST = 'bad_request',
    NOTFOUND = 'not_found'
  }
  