import { createSelector, createFeatureSelector } from '@ngrx/store';

import { LocationState, locationFeatureKey } from './location.state';
import { locationEntityAdapter } from './location.entity';

export const { selectAll, selectEntities, selectIds, selectTotal } = locationEntityAdapter.getSelectors();

export const getLocationState = createFeatureSelector<LocationState>(locationFeatureKey);

export const getAllLocations = createSelector(getLocationState, selectAll);

export const getLocationById = (id: string) => createSelector(getLocationState, (state) => state.entities[id]);

export const getLocationsBySearch = (search: string) =>
  createSelector(getAllLocations, (state) =>
    state.filter((x) => x.name.toLowerCase().indexOf(search.toLowerCase()) > -1)
  );

export const getError = createSelector(getLocationState, (state) => state.error);

export const getSelectedLocation = createSelector(getLocationState, (state) => state.selected);

export const getTotalCount = createSelector(getLocationState, (state) => state.totalCount);
