import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { AuthService } from '@core/services/auth.service';
import { IdentityStoreService } from '@modules/identity/store/services/identity-store.service';
import { NotificationService } from '@core/services/notification.service';

@Injectable()
export class RestrictedGuideGuard implements CanActivate {
  constructor(
    private auth: AuthService,
    private router: Router,
    private identityStoreService: IdentityStoreService,
    private notificationService: NotificationService
  ) {}

  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.identityStoreService.getUserProfile().pipe(
      tap((user) => {
        if (!user) {
          this.identityStoreService.fetchUserProfile();
        }
      }),
      map((user) => {
        if (user) {
          if (this.auth.isServiceProvider(user)) {
            /*this.translate
              .get("NO-ACCESS-GUIDE")
              .subscribe((res: string) =>
                this.notificationService.warning(res)
              );*/
            this.router.navigate(['/']);
            return false;
          }

          return true;
        }

        return false;
      })
    );
  }
}
