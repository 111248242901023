import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StarIconComponent } from './components/star-icon/star-icon.component';
import { StarRatingComponent } from './components/star-rating/star-rating.component';
import { RatingService } from './services/rating.service';
import { ShowRatingComponent } from './components/show-rating/show-rating.component';

@NgModule({
  declarations: [
    StarIconComponent,
    StarRatingComponent,
    ShowRatingComponent
  ],
  imports: [
    CommonModule
  ],
  exports: [
    StarRatingComponent,
    StarIconComponent,
    ShowRatingComponent
  ],
  providers: [
    RatingService
  ]
})
export class NgxRatingModule { }
