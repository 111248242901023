<div class="dialog-content-wrapper">
  <mat-toolbar matDialogTitle class="mat-primary m-0 px-2">
    <mat-toolbar-row class="d-flex justify-content-between align-content-center">
      <span class="title dialog-title">{{ 'shared.titles.enter_position' | transloco }}</span>
      <button (click)="matDialogRef.close()" mat-icon-button aria-label="Close dialog">
        <mat-icon>close</mat-icon>
      </button>
    </mat-toolbar-row>
  </mat-toolbar>
  <div class="search-bar mat-primary">
    <div id="search-input"></div>
  </div>
  <div mat-dialog-content class="pb-0 m-0">
    <div id="mapDialog" #mapDialog></div>
  </div>

  <div mat-dialog-actions class="m-0 py-3 px-4" fxLayout="row wrap" fxLayoutAlign="end center">
    <button
      *ngIf="markerList?.length > 0 && mapConfig.type === 'position'"
      (click)="setPosition()"
      mat-flat-button
      class="save-button text-uppercase"
      aria-label="SAVE"
    >
      {{ 'shared.action_buttons.enter_position' | transloco }}
    </button>
  </div>
</div>
