import { NgModule } from '@angular/core';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { RouterStateSerializer, StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreModule } from '@ngrx/store';

import { environment } from '@env/environment';
import { metaReducers, reducers } from './app.reducer';
import { CustomRouteSerializer } from './app.serializer';
import { EffectsModule } from '@ngrx/effects';
import { SignalREffects } from 'ngrx-signalr-core';

@NgModule({
  imports: [
    StoreModule.forRoot(reducers,{ metaReducers }),
    EffectsModule.forRoot([SignalREffects]),
    !environment.production ? StoreDevtoolsModule.instrument() : [],
    /* StoreRouterConnectingModule.forRoot({
      stateKey: 'router',
      serializer: CustomRouteSerializer,
    }), */
  ],
  providers: [{ provide: RouterStateSerializer, useClass: CustomRouteSerializer }],
})
export class AppStoreModule {}
