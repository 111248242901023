<div class="emergency-contact-container">
  <div class="section-header" *ngIf="apiResponse">
    <img src="/assets/icons/securemark.svg" class="img-fluid top-right pt-4 pb-3" alt="securemark-logo">
    <img src="/assets/images/emergency-contact-request-page.png" class="bg-rounded image" alt="emergency-contact-image">

    <h1 class="emergency-title py-3">Forespørsel om å være nødkontakt</h1>

    <div *ngIf="isApproved()" class="shadow-none card" [ngStyle]="{'background-color': '#E5F6D3'}">
      <span>Forespørsel godtatt</span>
    </div>

    <div *ngIf="isRejected()" class="shadow-none card" [ngStyle]="{'background-color' : '#FFB5B5'}">
      <span>Forespøresel avslått</span>
    </div>

    <div *ngIf="!isRejected() && !isApproved()">
      <p class="subtitle font-weight-bold pb-3">
        {{requestStatus.inviterName}} ønsker å legge deg til som sin nødkontakt dersom en nødsituasjon oppstår på sjøen.
      </p>
      <p class="subtitle pb-3">Ved en nødsituasjon vil du bli kontaktet av vår alarmsentral på dette telefonnummeret.</p>
    </div>

  </div>

  <div *ngIf="!isRejected() && !isApproved()" class="mb-4" fxLayout="row" fxLayout.lt-sm="column"
       fxLayoutAlign="space-around center" fxLayoutGap="12px">
    <button
      class="btn btn-primary btn-block badge-pill"
      (click)="acceptEmergencyContactRequest()"
    >Godta forespørsel
    </button>

    <button
      class="btn btn-primary btn-block outline badge-pill"
      (click)="rejectEmergencyContactRequest()"
    >Avslå forespørsel
    </button>
  </div>

  <div class="mb-4" [ngClass]="windowRef.innerWidth > 600 ? 'card my-4' : ''">
    <p class="mb-2 emergency-title font-weight-bold">Ofte stilte spørsmål</p>

    <div class="pl-2" fxLayout="row" fxLayoutAlign="center start">
      <div fxFlex="5">
        <mat-icon class="arrow" [ngStyle]="{'color':'#012169'}" (click)="toggleFirstFAQ()">
          {{faq1Expanded ? "expand_less" : "expand_more"}}
        </mat-icon>
      </div>
      <div fxFlex="auto">
        <p class="text">Hva slags informasjon blir lagret om meg?</p>
        <div class="expandable-text" [hidden]="!faq1Expanded">
            Hvis du godtar forespørselen om være nødkontakt, vil navnet og telefonnummeret ditt lagres i
            Redningsselskapets systemer. Denne informasjonen vil kun brukes til å kontakte deg ved
            nødsituasjoner.
        </div>
      </div>
    </div>

    <div class="pl-2" fxLayout="row" fxLayoutAlign="start start">
      <div fxFlex="5">
        <mat-icon class="arrow" [ngStyle]="{'color':'#012169'}" (click)="toggleSecondFAQ()">
          {{faq2Expanded ? "expand_less" : "expand_more"}}
        </mat-icon>
      </div>
      <div fxFlex="95">
        <p class="text">Hvordan kan jeg slettes som nødkontakt?</p>
        <div class="expandable-text" [hidden]="!faq2Expanded">
          Hvis du ikke lenger ønsker å være nødkontakt, kontakt vedkommende og be dem slette deg fra listen.
          Dine data vil slettes fra Redningsselskapets systemer.
        </div>
      </div>
    </div>
  </div>
</div>




