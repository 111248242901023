import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { tap, first } from 'rxjs/operators';
import { CountryStoreService } from '../store/country-store/services/country.store.service';
import { Country } from '@shared/models/country';

@Injectable()
export class CountryResolver implements Resolve<Country[]> {
  constructor(private countryStoreService: CountryStoreService) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Country[]> {
    return this.countryStoreService.getAllCountries().pipe(
      tap((country) => {
        if (country.length === 0) {
          this.countryStoreService.fetchAllCountry('', 0, 1000);
        }
      }),
      first()
    );
  }
}
