import { Directive, ElementRef, OnChanges, NgZone, HostListener } from '@angular/core';

@Directive({
  // tslint:disable-next-line: directive-selector
  selector: '[ngImagePixel]',
  exportAs: 'imagePixel',
})
export class ImagePixelDirective {
  private el: HTMLElement;

  public constructor(el: ElementRef, private ngZone: NgZone) {
    this.el = el.nativeElement;

    setTimeout(() => {
      this.imagePixel = this.getImagePixel();
    }, 10);
  }

  public imagePixel: string;

  public aspectRation: string;

  public getImagePixel(): string {
    const width = this.el.clientWidth;
    const height = this.el.clientHeight;

    this.aspectRation = `ar=${this.calculateAspectRatio(width, height)}`;

    return `w=${width}&h=${height}&${this.aspectRation}`;
  }

  private calculateAspectRatio(width: number, height: number): string {
    const divisor = this.gcd(width, height);
    return `${width / divisor}:${height / divisor}`;
  }

  private gcd(a: number, b: number): number {
    return b ? this.gcd(b, a % b) : a;
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.ngZone.run(() => {
      this.imagePixel = this.getImagePixel();
    });
  }
}
