import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable, of } from 'rxjs';
import { tap, first } from 'rxjs/operators';
import { UserLocation } from '@shared/models/user-location';
import { UserLocationStoreService } from '@shared/store/user-location-store/services/users-location.store.service';

@Injectable()
export class UserLocationResolver implements Resolve<UserLocation> {
  constructor(private userLocationStoreService: UserLocationStoreService) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<UserLocation> {
    const postId = route.queryParams['id'];

    if (postId) {
      return this.userLocationStoreService.getUserLocationById(postId).pipe(
        tap((location) => {
          if (!location) {
            this.userLocationStoreService.fetchAllUsersLocation(postId);
          }
        }),
        first()
      );
    } else {
      return of(null);
    }
  }
}
