import { createSelector, createFeatureSelector } from '@ngrx/store';
import { countryAdapter } from './country.entity';
import { CountryState, countryFeatureKey } from './country.state';
import { Predicate } from '@angular/core';
import { Country } from '@shared/models/country';

export const { selectAll, selectEntities, selectIds, selectTotal } = countryAdapter.getSelectors();

export const getCountryState = createFeatureSelector<CountryState>(countryFeatureKey);

export const getAllCountries = createSelector(getCountryState, selectAll);

export const getCountryById = (id: string) => createSelector(getCountryState, (state) => state.entities[id]);

export const getCountriesById = (ids:string[]) => createSelector(getCountryState,(state) => {
  if(state.ids.length)
    return ids.map((id) => ({...state.entities[id],phonePrefix: state.entities[id]?.phonePrefix?.includes('+') ? state.entities[id]?.phonePrefix : `+${state.entities[id]?.phonePrefix}`  }));
  else
    return undefined;
});

export const getCountriesBySearch = (predicate: Predicate<Country>) =>
  createSelector(getAllCountries, (state) => state.filter(predicate));

export const getCountryBySearch = (predicate: Predicate<Country>) =>
  createSelector(getAllCountries, (state) => state.find(predicate));

export const getError = createSelector(getCountryState, (state) => state.error);

export const getTotalCount = createSelector(getCountryState, (state) => state.totalCount);
