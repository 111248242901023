<main>
  <section class="privacy-policy">
      <div class="header-section">
              <p class="paragraph" i18n>
                Last updated 31.03.20
              </p>
              <p class="paragraph" i18n>
                Your privacy is important to Hjelpetorget.no.
              </p>
              <p class="paragraph" i18n>
                This privacy policy describes how, and to what extent, Hjelpetorget.no collects 
                and processes your personal data when you use Hjelpetorget.no’s website, currently 
                www.Hjelpetorget.no (the Site) and the HJELPETORGET.NO application (the Application), 
                and how Hjelpetorget.no complies with relevant data privacy laws and regulations.
              </p>
              <p class="paragraph" i18n>
                Processing of personal data by Hjelpetorget.no is subject to European data 
                protection law, such as Regulation (EU) 2016/679, as transposed in the Member 
                States of the EU and EEA.
              </p>
              <p class="paragraph" i18n>
                In this Privacy Policy, “you” or “your” refers to any User of the Site or 
                Application. “Data processing” means any processing of personal data, including 
                but not limited to collection, storage, use, analysis and transfer of personal data.
              </p>
              <p class="paragraph" i18n>
                In addition to processing personal data, Hjelpetorget.no may process non-identifiable 
                information, i.e. data that does not reveal your personal identity. Hjelpetorget.no’s 
                processing of non-identifiable information is not subject to this Privacy Policy.
              </p>
      </div>
      <div class="header-section">
          <h2 class="sub-heading" i18n>
              <span class="topic-number">1.</span> DATA CONTROLLER
          </h2>
          <p class="paragraph" i18n>
            Gture AS (organisation number 999 165 745) is the “Data Controller” (as defined in the GDPR) 
            in respect of the processing of your personal data under this Privacy Policy. Our address is 
            Rådhusgata 17, 0158 Oslo, and you can reach us by by sending an email to hello@gture.com
          </p>
      </div>
      <div class="header-section">
          <h2 class="sub-heading">
              <span class="topic-number" i18n>2.</span> PURPOSES FOR WHICH HJELPETORGET PROCESSES PERSONAL DATA
          </h2>
          <p class="paragraph" i18n>
            Hjelpetorget.no will only process your personal data for the purposes described in this privacy policy, which includes the following purposes:
          </p>
          <ul>
              <li i18n>Administering the customer relationship between you and Hjelpetorget.no, including by facilitating bookings, payments and performance of services, and providing support to you;</li>
              <li i18n>Making technical improvements, and maintaining the operability of the Site and Application;</li>
              <li i18n>Ensuring the safety and security of our users, e.g. by verifying your identity, and documenting messages that are sent between users through the Site or Application;</li>
              <li i18n>Investigating any incidents that may occur in connection with the services provided by Hjelpetorget.no or our Service Providers;</li>
              <li i18n>Providing you with relevant information, including confirmations and receipts of bookings and payments;</li>
              <li i18n>Documentation of agreements entered into using the Site or Application;</li>
              <li i18n>Pseudonomization of your personal data for the purpose of transferring anonymous data to third parties;</li>
              <li i18n>Hjelpetorget.no may anonymize and aggregate your personal data for the purpose of developing statistics which Hjelpetorget.no may use in order to improve our service </li>
              <li i18n>Providing you with information relating to our services, promotions and</li>
          </ul>
          <p class="paragraph" i18n>
            Hjelpetorget.no will only use your contact information for marketing purposes if you have consented to receiving marketing material from 
            Hjelpetorget.no, and only in accordance with applicable law, including consumer protection laws and regulations.
          </p>
      </div>
      <div class="header-section">
          <h2 class="sub-heading">
              <span class="topic-number" i18n>3.</span> PERSONAL DATA PROCESSED BY HJELPETORGET
          </h2>
          <p class="paragraph" i18n>
            Hjelpetorget.no needs to process certain personal data in order to provide 
            safe and user-friendly services. Provision of the personal data described in 
            this Privacy Policy is a necessary requirement in order to use certain parts 
            of the Site and Application.
          </p>
          <p class="paragraph" i18n>
            If you have not registered an account with Hjelpetorget.no, we collect and process 
            the following personal data when you use the Site or Application:
          </p>
          <ul>
              <li i18n><span>Device data,</span> such as MAC address and IMEI number;</li>
              <li><span i18n>IP address;</span></li>
              <li i18n><span>Metadata,</span> such as the time of visit, duration of your session or type of Internet browser. </li>
          </ul>
          <p class="paragraph" i18n>
            In addition to the above, if you have registered an account, we process the following personal data as uploaded by you to the Site or Application:
          </p>
          <ul>
              <li i18n>Contact information, such as name, e-mail address, and telephone number;</li>
              <li i18n>
                Other personal information, such as your age, gender, country of residence and language;
              </li>
              <li i18n>
                Pictures uploaded to the Site or Application through your account including your profile picture;
              </li>
              <li i18n>
                Location data: information of your whereabouts while using the Site or Application;
              </li>
              <li i18n>
                Communication data, such as the time and duration of communication between you users and the content of chat conversations.
              </li>
              <!-- <li>
                  Personal identity information such as passport expiry date, passport issuing country, national ID card or driver’s license;

              </li>
              <li>
                  Communication data, such as the time and duration of communication between you users and the content of chat conversations.

              </li> -->
          </ul>
      </div>
      <div class="header-section">
          <h2 class="sub-heading" i18n>
              <span class="topic-number">4.</span>THE LEGAL BASIS FOR PROCESSING YOUR PERSONAL DATA
          </h2>
          <p class="paragraph" i18n>
            In most instances described in this Privacy Policy, it is necessary for us to process your 
            personal data in order to carry out the services. In such instances, the legal basis for 
            processing under European privacy law is found in Article 6 (1) (b) GDPR. This applies 
            to the processing of your contact information, other personal information, picture, 
            location data, personal identity information, payment information and communication data.
          </p>
          <p class="paragraph" i18n>
            In some instances, personal data is collected and processed on the basis of your consent. 
            By accepting this Privacy Policy, you consent to processing of personal data as described 
            herein. You may choose to withdraw your consent at any time, which may affect your experience 
            of our services.
          </p>
          <p class="paragraph" i18n>
            Device data, IP address and metadata are processed based on Hjelpetorget.no’s legitimate 
            interest in improving your experience when visiting the Site or using the Application, 
            pursuant to Article 6 (1) (f) GDPR.
          </p>
          <p class="paragraph" i18n>
            Under certain circumstances, Hjelpetorget.no may be obliged by law to process your 
            personal data for specified purposes. Such processing is necessary for compliance with a 
            legal obligation to which Hjelpetorget.no is subject, pursuant to Article 6 (1) (c) GDPR.
          </p>
      </div>
      <div class="header-section">
          <h2 class="sub-heading" i18n>
              <span class="topic-number">5.</span>HOW WE PROTECT YOUR PRIVACY
          </h2>
          <p class="paragraph" i18n>
            Hjelpetorget.no has established internal routines for protection of your privacy, in 
            accordance with privacy laws and regulations applicable to Hjelpetorget.no, including 
            the GDPR. We commit to ensuring that our data processors, sub-contractors and employees 
            comply with this Privacy Policy and our obligations under the applicable data privacy 
            laws and regulations. Personal data is only accessible to employees of Hjelpetorget.no 
            to the extent it is necessary to provide you with our services. Those employees are bound 
            by a duty of confidentiality with respect to your personal data.
          </p>
          <p class="paragraph" i18n>
            All personal data processed by Hjelpetorget.no are encrypted, and Hjelpetorget.no has 
            implemented security mechanisms in order to prevent data from being disclosed to 
            unauthorized users.
          </p>
      </div>
      <div class="header-section">
          <h2 class="sub-heading" i18n>
              <span class="topic-number">6.</span>COOKIES
          </h2>
          <p class="paragraph" i18n>
            Cookies are small text-files placed on your device when you use our Site and Application. 
            Cookies are primarily used to recognize your preferences and keep track of your user session on 
            the Site. Hjelpetorget.no also uses cookies to improve the performance of our Site and services.
          </p>
          <p class="paragraph" i18n>
            If you do not wish cookies to be downloaded to your device when you visit the Site, you must 
            change your web browser’s or device’s settings accordingly. Please note that disabling or 
            restricting the use of cookies may render you unable to use the Site or Application, or 
            result in the Site or Application not working properly.
          </p>
      </div>
      <div class="header-section">
          <h2 class="sub-heading" i18n>
              <span class="topic-number">7.</span>YOUR RIGHTS
          </h2>
          <h3 class="list-sub-heading" i18n>
              <span class="topic-number">7.1</span>Right to access of information
          </h3>
          <p class="paragraph" i18n>
            You have the right to obtain a confirmation as to whether we are processing your personal 
            data, for what purposes, what categories of personal data we are processing and with whom 
            your personal data is shared, as well as the right to access your personal data that is being 
            processed by Hjelpetorget.no. You have the right to request a copy of your personal data, which 
            we are obligated to provide to you in a commonly used electronic form.
          </p>
      </div>
      <div class="header-section">
          <h3 class="list-sub-heading" i18n>
              <span class="topic-number">7.2</span>Right to rectification, erasure and objection
          </h3>
          <p class="paragraph" i18n>
            You have the right to require that incorrect, incomplete or inaccurate personal data is 
            rectified or erased. The right to erasure also applies if the personal data is no longer 
            necessary to achieve the purposes as defined in this policy, or if your personal data have 
            been unlawfully processed by Hjelpetorget.no.
          </p>
          <p class="paragraph" i18n>
            You have the right to object at any time to the processing of personal data that is being 
            processed on the basis of pursuing a legitimate interest of Hjelpetorget.no or a third party, 
            unless Hjelpetorget.no or a third party has compelling legitimate grounds for the processing 
            which override your interests, rights and freedoms or for the establishment, exercise or 
            defence of legal claims.
          </p>
          <p class="paragraph" i18n>
            If personal data are processed for direct marketing purposes, you have the right to object to 
            the processing of your personal data for such marketing purposes.
          </p>
      </div>
      <div class="header-section">
          <h3 class="list-sub-heading" i18n>
              <span class="topic-number">7.3</span> Right to data portability
          </h3>
          <p class="paragraph" i18n>
            With respect to Hjelpetorget.no’s processing of your personal data based on your consent or 
            processing which is necessary to provide you the services, you have the right to data portability. 
            The right to data portability means you may obtain the personal data you provide to us in a 
            structured, commonly used and machine-readable format. You may also transmit that data to 
            another entity without hindrance from Hjelpetorget.no. If technically feasible, Hjelpetorget.no 
            will also transmit that data directly to another entity on your request.
          </p>
      </div>
      <div class="header-section">
          <h3 class="list-sub-heading" i18n>
              <span class="topic-number">7.4</span>Right to lodge a complaint
          </h3>
          <p class="paragraph" i18n>
            You have the right to lodge a complaint with the relevant supervisory authority in your jurisdiction, 
            if you are of the opinion that Hjelpetorget.no is processing your personal data in violation of 
            this privacy policy or applicable law.
          </p>
      </div>
      <div class="header-section">
          <h3 class="sub-heading" i18n>
              <span class="topic-number">8.</span>DIRECT MARKETING
          </h3>
          <p class="paragraph" i18n>
            If and to the extent you have consented to such use, Hjelpetorget.no may use your personal 
            data for marketing purposes in accordance with applicable laws and regulations. This means 
            that Hjelpetorget.no may use your personal data for the purposes of providing ads, newsletters 
            and other marketing material regarding our services. We may contact you through the channels 
            that you have agreed to, including by e-mail and phone
          </p>
          <p class="paragraph" i18n>
            If you no longer want us to send you market surveys or otherwise use your personal data for 
            marketing purposes, you can opt out at any time by using the opt-out mechanisms provided on 
            the Site or in the Application, or by telling us in any other way that you would like to opt 
            out. Please note that opting out does not affect the lawfulness of Hjelpetorget.no’s processing 
            based on your consent before its withdrawal.
          </p>
      </div>
      <div class="header-section">
          <h3 class="sub-heading" i18n>
              <span class="topic-number">9.</span>ANONYMOUS PROFILING
          </h3>
          <p class="paragraph" i18n>
            Hjelpetorget.no may use anonymous profiling in our marketing activities. Such profiling may 
            result in anonymous profiles based on information regarding your booking history and the 
            searches you have made on the Site or Application. This is done to improve our service 
            offerings by adjusting them to better suit your preferences and to generally improve our 
            understanding of our users’ preferences. We do not make any decisions relevant to your 
            rights or obligations based on such anonymous profiling.
          </p>
      </div>
      <div class="header-section">
          <h3 class="sub-heading" i18n>
              <span class="topic-number">10.</span>DATA STORAGE AND LOCATION
          </h3>
          <p class="paragraph" i18n>
            Your personal data will be stored by Hjelpetorget.no in the Oslo, and may be processed in 
            accordance with this Privacy Policy by our personnel in Norway. We will not transfer your 
            personal data to companies or entities operating outside the EU/EEA unless all necessary 
            safeguards are carried out in accordance with applicable privacy laws.
          </p>
          <p class="paragraph" i18n>
            Hjelpetorget.no will not store your personal data longer than necessary for pursuing 
            the purposes for which the data has been collected, unless we are required by law to 
            store personal data for a longer period of time.
          </p>
      </div>
      <div class="header-section">
          <h3 class="sub-heading" i18n>
              <span class="topic-number">11.</span>DATA PROCESSORS
          </h3>
          <p class="paragraph" i18n>
            From time to time, Hjelpetorget.no needs to share some of your personal data with our 
            business partners or subcontractors who might process data on our behalf, such as 
            payment services providers, travel agencies, travel guide organizations and partner hotels.
          </p>
          <p class="paragraph" i18n>
            Hjelpetorget.no is responsible for entering into written data processor agreements 
            with anyone who processes your personal data on our behalf, and for ensuring that all 
            processing of your personal data is carried out in compliance with applicable laws and 
            regulations.
          </p>
          <p class="paragraph" i18n>
            Hjelpetorget.no will not transfer your personal data to other third parties without 
            your prior consent, unless a situation occurs in which we are entitled by law to 
            transfer personal data, or we are required by law to transfer your personal data to 
            law enforcement or other public authorities.
          </p>
      </div>
      <div class="header-section">
          <h3 class="sub-heading" i18n>
              <span class="topic-number">12.</span>SOCIAL PLUGINS AND LINKS TO THIRD PARTY SERVICES
          </h3>
          <p class="paragraph" i18n>
            From time to time, Hjelpetorget.no needs to share some of your personal data with our 
            business partners or subcontractors who might process data on our behalf, such as 
            payment services providers, travel agencies, travel guide organizations and partner hotels.
          </p>
          <p class="paragraph" i18n>
            The Site and Application offer social plugins through Facebook and Instagram (Facebook Inc., 1601 S. California Ave, 
            Palo Alto, CA 94304. USA). Plugins normally show Facebook’s trademark.
          </p>
          <p class="paragraph" i18n>
            Hjelpetorget.no also employs plugins from Google+ (Google Inc., Amphitheatre Parkway, Mountain View, CA 94043, USA), 
            Twitter (Twitter, Inc, 1355 Market St., Suite 900, San Francisco, CA 94103).
          </p>
          <p class="paragraph" i18n>
            Further, you may choose to link your HJELPETORGET.NO account with social media accounts such as Facebook or 
            Instagram, if HJELPETORGET.NO makes such options available through the Site or Application.
          </p>
          <p class="paragraph" i18n>
            Social plugins and links to third party web sites and services may cause your personal data to be collected 
            by the relevant third parties. We are not responsible for such third parties’ use of your personal data, and 
            such processing will be subject to the relevant third parties’ own privacy policies. We encourage that you 
            only use social plugins and third party integrations after familiarising yourself with the applicable 
            privacy policies.
          </p>
      </div>
      <div class="header-section">
          <h3 class="sub-heading" i18n>
              <span class="topic-number">13.</span>SUPERVISORY AUTHORITY
          </h3>
          <p class="paragraph" i18n>
            If you believe we are processing your personal data in breach of relevant laws and regulations, you may lodge a complaint with the relevant supervisory authority.
          </p>
      </div>
      <div class="header-section address-section">
          <h3 class="address-sub-heading" i18n>
              Norway:
          </h3>
          <address i18n>
              Datatilsynet<br>
              postkasse@datatilsynet.no<br>
              Phone: 22 39 69 00
          </address>
      </div>
      <div class="header-section address-section">
          <h3 class="address-sub-heading">
              UK:
          </h3>
          <address i18n>
              See https://ico.org.uk/global/contact-us/postal-addresses/
              <br>
              Information Commissioner’s Office
              <br>
              Phone: 0303 123 1113
          </address>
      </div>
      <div class="header-section address-section">
          <h3 class="address-sub-heading" i18n>
              Denmark:
          </h3>
          <address i18n>
              Datatilsynet
              <br>
              Phone: 33 19 32 00
              <br>
              dt@datatilsynet.dk
          </address>
      </div>
      <div class="header-section address-section">
          <h3 class="address-sub-heading" i18n>
              Sweden:
          </h3>
          <address i18n>
              Datainspektionen
              <br>
              Phone: 08-657 61 00
              <br>
              datainspektionen@datainspektionen.se
          </address>
      </div>
      <div class="header-section">
          <h2 class="sub-heading" i18n>
              <span class="topic-number">14.</span>CHANGES AND AMENDMENTS
          </h2>
          <p class="paragraph" i18n>
            Hjelpetorget.no may from time to time make changes and amendments to this privacy policy. 
            All changes and amendments are effective from the time they are published. If you have registered an 
            account with us, we will notify you of any changes or amendments through the e-mail address provided 
            in your account.
          </p>
      </div>
  </section>
</main>