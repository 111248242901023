import { Component, Inject, Optional } from '@angular/core';
import { IdentityStoreService } from '@modules/identity/store/services/identity-store.service';
import { Meta } from '@angular/platform-browser';
import { NavigationEnd, Router } from '@angular/router';
import { ConfigService } from '@core/services/config.service';
import { GoogleTagManagerService } from 'angular-google-tag-manager';
import { BackNavigationService } from '@modules/core/services/back-navigation.service';
import { OidcSecurityService } from "angular-auth-oidc-client";
import { SERVER_SIDE } from "../provider.token";
import { catchError } from "rxjs/operators";
import { EMPTY } from "rxjs";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  constructor(

    @Optional() @Inject(SERVER_SIDE) private serverSide: boolean,
    private metaService: Meta,
    private router: Router,
    private gtmService: GoogleTagManagerService,
    private configService: ConfigService,
    private backNavigationService: BackNavigationService,
    private identityStoreService: IdentityStoreService,
    private oidcSecurityService: OidcSecurityService
  ) {}

  ngOnInit(): void {
    if(!this.serverSide) {
      this.backNavigationService.initRouterEvents();
    }
    if(!this.serverSide) {
      this.oidcSecurityService.checkAuthIncludingServer().pipe(
        catchError(() => EMPTY)
      ).subscribe(({isAuthenticated,accessToken})=> {
        if(isAuthenticated && (accessToken.length > 0)) {
          this.identityStoreService.fetchUserProfile();
        }
      });
    }


    this.router.events.forEach((item) => {
      if (item instanceof NavigationEnd) {
        const gtmTag = {
          event: 'page',
          pageName: item.url,
        };
        if (!this.serverSide) {
          this.gtmService.pushTag(gtmTag);
        }
      }
    });

    this.preventSEOIndex();
  }

  private preventSEOIndex(): void {
    const config = this.configService.getCurrent();
    if (config.env === 'development' || config.env === 'staging') {
      this.metaService.addTag({
        name: 'robots',
        content: 'noindex',
      });
    }
  }
}
