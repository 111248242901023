import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-instructor-terms',
  templateUrl: './instructor-terms.component.html',
  styleUrls: ['./instructor-terms.component.scss']
})
export class InstructorTermsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
