<header>

  <div class="menu-wrapper menu-padding">
    <ng-container *ngIf="userProfile$ | async ">

      <div class="nav-item">
        <a
          class="nav-link text-uppercase"
          [routerLink]="'/'"
          [routerLinkActiveOptions]="{exact:true}"
          [routerLinkActive]="['active']"
        >
          <div class="icon search"></div>
          <div>{{'layout.nav_links.explore' | transloco}}</div>
        </a>
      </div>
<!--      <div class="nav-item">-->
<!--        <a-->
<!--          class="nav-link text-uppercase"-->
<!--          [routerLink]="'/kart'"-->
<!--          [routerLinkActive]="['active']"-->
<!--          ><div class="icon pin"></div>-->
<!--          <div>{{'layout.nav_links.map' | transloco}}</div></a-->
<!--        >-->
<!--      </div>-->

<!--      <div class="nav-item">-->
<!--        <a-->
<!--          class="nav-link text-uppercase"-->
<!--          [routerLink]="'/meldinger'"-->
<!--          [routerLinkActive]="['active']"-->
<!--          ><div class="icon message"></div>-->
<!--          <div>{{'layout.nav_links.messages' | transloco}}</div></a-->
<!--        >-->
<!--      </div>-->

      <div class="nav-item">
        <a
          class="nav-link text-uppercase"
          [routerLink]="'/alle-kurs'"
          [routerLinkActive]="['active']"
        ><div class="icon course"></div>
          <div>{{'layout.nav_links.courses' | transloco}}</div></a
        >
      </div>

      <div class="nav-item">
        <a
          class="nav-link text-uppercase"
          [routerLink]="'/bruker/min-side'"
          [routerLinkActive]="['active']"
          ><div class="icon profile"></div>
          <div>{{'layout.nav_links.my_page' | transloco}}</div></a
        >
      </div>
    </ng-container>

    <ng-container *ngIf="!(userProfile$ | async )">

      <div class="nav-item">
        <a
          class="nav-link text-uppercase"
          [routerLink]="'/'"
          [routerLinkActiveOptions]="{exact:true}"
          [routerLinkActive]="['active']"
        >
          <div class="icon search"></div>
          <div>{{'layout.nav_links.explore' | transloco}}</div>
        </a>
      </div>

<!--      <div class="nav-item">-->
<!--        <a-->
<!--          class="nav-link text-uppercase"-->
<!--          [routerLink]="'/kart'"-->
<!--          [routerLinkActive]="['active']"-->
<!--          ><div class="icon pin"></div>-->
<!--          <div>{{'layout.nav_links.map' | transloco}}</div></a-->
<!--        >-->
<!--      </div>-->

<!--      <div class="nav-item">-->
<!--        <a-->
<!--          class="nav-link text-uppercase"-->
<!--          [routerLink]="'/meldinger'"-->
<!--          [routerLinkActive]="['active']"-->
<!--          ><div class="icon message"></div>-->
<!--          <div>{{'layout.nav_links.messages' | transloco}}</div></a-->
<!--        >-->
<!--      </div>-->

      <div class="nav-item">
        <a
          class="nav-link text-uppercase"
          [routerLink]="'/alle-kurs'"
          [routerLinkActive]="['active']"
        ><div class="icon course"></div>
          <div>{{'layout.nav_links.courses' | transloco}}</div></a
        >
      </div>

      <div class="nav-item">
        <a
          class="nav-link text-uppercase"
          [routerLink]="['/auth/login']"
          [routerLinkActive]="['active']"
          ><div class="icon profile"></div>
          <div>{{'layout.nav_links.log_in' | transloco}}</div></a
        >
      </div>
    </ng-container>
  </div>
</header>
