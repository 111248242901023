import { Injectable } from '@angular/core';
import { BaseApiService } from '@core/http/base.api.service';
import { IBaseApi } from '@core/http/base.api.interface';
import { Country } from '@shared/models/country';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ConfigService } from '@core/services/config.service';
import { Observable } from 'rxjs';
import { PageList } from '@core/models/page-list';

@Injectable()
export class CountryApiService extends BaseApiService implements IBaseApi<Country> {
  constructor(public http: HttpClient, private configService: ConfigService) {
    super(http);
  }

  public get BaseUrl(): string {
    const config = this.configService.getCurrent();
    return `${config.services.experience}/api/country`;
  }

  public getAllData(search: string, pageIndex: number, pageSize: number): Observable<PageList<Country>> {
    const params = new HttpParams()
      .append('search', search)
      .append('pageOffset', pageIndex.toString())
      .append('pageSize', pageSize.toString());

    return super.getAllWithPagination(`${this.BaseUrl}`, params);
  }

  public getById(id: string): Observable<Country> {
    return super.get(`${this.BaseUrl}`, id);
  }

  public create(create: Partial<Country>): Observable<Country> {
    return super.register(this.BaseUrl, create);
  }

  public edit(id: string, change: Partial<Country>): Observable<Country> {
    return super.update(`${this.BaseUrl}/${id}`, change);
  }

  public remove(id: string): Observable<any> {
    return super.delete(`${this.BaseUrl}/${id}`);
  }
}
