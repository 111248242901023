import { Injectable } from '@angular/core';
import { StorageService } from '@core/services/storage.service';

const TOKEN_STORE_KEY = 'access_token';

@Injectable({
  providedIn: 'root',
})
export class TokenService {
  constructor(private storage: StorageService) {}

  public saveToken(token: string): void {
    this.storage.setItem(TOKEN_STORE_KEY, token);
  }

  public removeToken(): void {
    this.storage.removeItem(TOKEN_STORE_KEY);
  }

  public getToken(): string {
    const access_token = this.storage.getItem(TOKEN_STORE_KEY);
    return access_token ? access_token : '';
  }
}
