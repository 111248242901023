<div class="location-permission-modal" id="appendblock">
  <mat-toolbar matDialogTitle class="theme-color m-0">
    <mat-toolbar-row class="d-flex justify-content-between">
      <!-- Before we go on... -->
      <p class="title dialog-title">{{'shared.modals.location_permission.title' | transloco}}</p>
      <!-- Close Message Modal -->
      <button (click)="close()" mat-icon-button aria-label="Close dialog">
        <mat-icon>close</mat-icon>
      </button>

    </mat-toolbar-row>
  </mat-toolbar>

  <div mat-dialog-content class="pb-0 m-0">
    <!-- Your navigator has denied to share its location. Do something!  -->
    <span class="title dialog-title" *ngIf="data.isPermissionDenied">{{ 'shared.modals.location_permission.text_if_denied' | transloco }}</span>
    <!-- Hey we need your location if you wanna have a cool experience! -->
    <span class="title dialog-title" *ngIf="!data.isPermissionDenied">{{ 'shared.modals.location_permission.text_if_not_denied' | transloco }}</span>
  </div>

  <div mat-dialog-actions>
    <div class="modal-action-footer">
      <!--  Share my location -->
      <button *ngIf="!data.isPermissionDenied" type="button" class="btn btn-primary" (click)="redirectToMyPage()">
        {{ 'shared.modals.location_permission.go_to_my_page' | transloco }}
      </button>
      <!-- I understand -->
      <button *ngIf="data.isPermissionDenied" type="button" class="btn btn-primary" (click)="close()">
        {{ 'shared.modals.location_permission.i_understand' | transloco }}
      </button>
    </div>
  </div>

</div>
