import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { identityStoreReducer } from './identity.reducer';
import { IdentityEffects } from './identity.effects';
import { IdentityApiService } from './services/identity-api.service';
import { IdentityStoreService } from './services/identity-store.service';
import { UserExcludeResolver } from './services/user-exclude.resolver';
import { identityFeatureKey } from './identity.state';

@NgModule({
  declarations: [],
  imports: [
    StoreModule.forFeature(identityFeatureKey, identityStoreReducer),
    EffectsModule.forFeature([IdentityApiService, IdentityEffects]),
  ],
  providers: [IdentityStoreService, UserExcludeResolver],
})
export class IdentityStoreModule {}
