import { createAction, props } from '@ngrx/store';
import { Culture } from '../../models/culture';

export const AddCultures = createAction('ADD CULTURES', props<{ cultures: Culture[] }>());

export const AddCulture = createAction('ADD CULTURE', props<{ culture: Culture }>());

export const AddError = createAction('ADD CULTURE ERROR', props<{ error: any }>());

export const AddTotalCount = createAction('ADD CULTURE TOTAL COUNT', props<{ totalCount: number }>());

// Effecs actions

export const FetchCultures = createAction(
  '[Api] FETCH CULTURES',
  props<{
    search: string;
    pageIndex: number;
    pageSize: number;
    onlyNeutral: boolean;
    locations: string[];
  }>()
);

export const GetCulture = createAction('[Api] GET CULTURE', props<{ id: string }>());
