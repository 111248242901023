import { animate, AnimationTriggerMetadata, keyframes, state, style, transition, trigger } from '@angular/animations';

const HEADER_ANIMATION_TIMINGS: string = '300ms ease-out';

export const EXPANSION_PANEL_ANIMATION_TIMING: string = '225ms cubic-bezier(0.4,0.0,0.2,1)';

export const HEADER_ANIMATIONS: AnimationTriggerMetadata[] = [
  trigger('showBackground', [
    state('show', style({
      background: 'white'
    })),
    state('hide', style({
      background: 'transparent'
    })),
    transition('show => hide', [
      animate(HEADER_ANIMATION_TIMINGS)
    ]),
    transition('hide => show', [
      animate(HEADER_ANIMATION_TIMINGS,keyframes([
        style({transform: 'translateY(41px)', zIndex: 0,  background: 'white'}),
        style({transform: 'translateY(30px)',  zIndex: 500,  background: 'white'}),
        style({transform: 'translateY(20px)', zIndex: 1000 , background: 'white'}),
        style({transform: 'translateY(10px)', zIndex: 1000 , background: 'white'}),
        style({transform: 'translateY(0px)', background: 'white'})
      ])),
    ])
  ]),
  trigger('toggleMenu', [
    transition(':enter', [
      animate(HEADER_ANIMATION_TIMINGS, keyframes([
        style({opacity: 0}),
        style({opacity: .5}),
        style({opacity: .75}),
        style({opacity: 1})
      ]))
    ]),
    transition(':leave', [
      animate(HEADER_ANIMATION_TIMINGS, keyframes([
          style({opacity: 1}),
          style({opacity: .75}),
          style({opacity: .5}),
          style({opacity: 0})
        ])
      )
    ]),
  ]),
  trigger('bodyExpansion', [
    state('collapsed, void', style({height: '0px',opacity: 0 , visibility: 'hidden'})),
    state('expanded', style({height: '*', visibility: ''})),
    transition(
      'expanded <=> collapsed',
      animate(EXPANSION_PANEL_ANIMATION_TIMING)
    )
  ])
]
