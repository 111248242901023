import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'ngx-star-icon',
  templateUrl: './star-icon.component.html',
  styleUrls: ['./star-icon.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class StarIconComponent implements OnInit {

  @Input()
  public isFraction = false;

  @Input()
  public color = 'red';

  @Input()
  public isEmpty = false;

  @Input()
  public CssClass = '';

  @Input()
  public padding = '1px';

  constructor() {
  }

  ngOnInit() {
  }

}
