import { createAction, props } from '@ngrx/store';
import { Country } from '@shared/models/country';

export const AddCountries = createAction('ADD COUNTRIES', props<{ countries: Country[] }>());

export const AddCountry = createAction('ADD COUNTRY', props<{ country: Country }>());

export const AddError = createAction('ADD COUNTRY ERROR', props<{ error: any }>());

export const AddTotalCount = createAction('ADD COUNTRY TOTAL COUNT', props<{ totalCount: number }>());

// Effecs actions

export const FetchCountries = createAction(
  '[Api] FETCH COUNTRIES',
  props<{
    search: string;
    pageIndex: number;
    pageSize: number;
  }>()
);

export const GetCountry = createAction('[Api] GET COUNTRY', props<{ id: string }>());
