import { EntityState } from '@ngrx/entity';
import { UserLocation } from '@shared/models/user-location';

export const userLocationFeatureKey = 'userLocations';

export interface UserLocationState extends EntityState<UserLocation> {
  error: any;
  totalCount: number;
  selected: UserLocation;
}
