<div class="section">
  <div class="container">
      <div class="row">
          <div class="col-12 col-md-12 col-lg-12 col-xl-12">
              <div class="instructor-terms">
                  <h1 class="h1">VILKÅR FOR BRUK AV PLATTFORMEN «RS SJØLIV» FOR TJENESTETILBYDERE</h1>
                  <p class="paragraph">
                    Følgende vilkår (heretter “Vilkårene”) gjelder for tjenestetilbydere på RS Sjøliv og styrer tilgang til og bruk av teknologiplattformen «RS Sjøliv». Vilkårene for kunder på RS Sjøliv er tilgjengelig <a href="https://sjoliv.rs.no/bruker/min-side" target="_blank">her.</a>
                  </p>
                  <p class="paragraph">
                    Ved å bruke RS Sjøliv bekrefter du å ha forstått og samtykker til å være bundet og overholde disse vilkårene for bruk, inkludert <a href="https://www.redningsselskapet.no/personvern/personvern-kursdeltakerer/" target="_blank">personvernerklæringen for kursdeltakere og kunder hos Redningsselskapet.</a>
                  </p>
                  <p class="paragraph">
                    RS Sjøliv tilbys og er tilgjengelig for brukere som er 15 år eller eldre. Hvis du er under 15 år, kan du ikke bruke RS Sjøliv.
                  </p>

                  <div class="conditions-item">
                    <h4 class="sub-title text-uppercase">
                      <span class="count">1.</span>
                      Definisjoner
                    </h4>

                    <p class="paragraph">
                      <strong>RS Sjøliv</strong> er en teknologiplattform (heretter «plattformen») bestående av en markedsplass og konseptet «Sjøvenn».
                    </p>

                    <p class="paragraph">
                      <strong>Markedsplass</strong> er en tjeneste der det tilbys og avtales kjøp av ulike varer og tjenester. Markedsplassen gjør det mulig for kunder å liste opp eller søke etter varer og tjenester, og å lære om, ordne og planlegge slike tjenester. Disse varene og tjenestene kan være tilbudt på markedsplassen av Redningsselskapet eller tjenestetilbydere som leverer uavhengig av Redningsselskapet. Hvem som er tjenestetilbyder, vil være tydelig i hvert enkelt tilfelle. Redningsselskapet er uten ansvar for varer og tjenester som tilbys av andre enn Redningsselskapet.
                    </p>

                    <p class="paragraph">
                      <strong>Sjøvenn</strong> er en tjeneste der man kan søke informasjon om hva som skjer på sjøen og kommunisere med båter rundt seg. Når man kommuniserer med båter rundt seg kan man enten forespørre eller gi informasjon eller be båter rundt seg om hjelp med trivielle ting. Merk at dette ikke er noen erstatning for det å be om Assistanse via 02016 eller å melde nød via 120/113.
                    </p>

                    <p class="paragraph">
                      <strong>Bruker</strong> betyr enhver fysisk person eller juridisk enhet som er registrert med en konto på plattformen. Brukere er delt inn i kategoriene tjenestetilbyder og kunde.
                    </p>

                    <p class="paragraph">
                      <strong>Tjenestetilbyder</strong> betyr en fysisk person eller juridisk enhet som tilbyr varer eller tjenester via plattformen og er registrert som «tjenestetilbyder». Dette omfatter både Redningsselskapet som tjenestetilbyder og eksterne tjenestetilbydere.
                    </p>

                    <p class="paragraph">
                      <strong>Kunde</strong> betyr en fysisk person eller juridisk enhet som kjøper varer eller tjenester via plattformen. Alle brukere av plattformen som ikke er registrert som «tjenestetilbyder» er regnet som «kunde».
                    </p>

                    <p class="paragraph">
                      <strong>Vare</strong> betyr enhver fysisk gjenstand som tilbys av en tjenestetilbyder gjennom plattformen. Innhold og aktiviteter på Sjøvenn regnes ikke som varer etter disse vilkår.
                    </p>

                    <p class="paragraph">
                      <strong>Tjeneste</strong> betyr enhver aktivitet, utflukt, leveranse eller tjeneste av kommersiell karakter som tilbys av en tjenestetilbyder gjennom plattformen. Innhold og aktiviteter på Sjøvenn regnes ikke som tjenester etter disse vilkår.
                    </p>

                    <p class="paragraph">
                      <strong>Innhold</strong> betyr tekst-, lyd-, visuelt og audiovisuelt innhold og informasjon som vises eller gjøres tilgjengelig gjennom plattformen, inkludert men ikke begrenset til bilder, filmer og reklamemateriale.
                    </p>

                  </div>


                  <div class="conditions-item">
                    <h4 class="sub-title text-uppercase">
                      <span class="count">2.</span> Plattformen
                    </h4>
                    <h6 class="text-heading">
                      2.1 Eierskap og Lisens
                    </h6>
                    <p class="paragraph">
                      RS Sjøliv innehar de immaterielle rettighetene til tekst, bilde, design, brukergrensesnitt og annet materiale og informasjon som er tilgjengelig for deg på plattformen. Det samme gjelder den underliggende programkoden for tjenestene. Slikt materiale kan ikke brukes på annen måte enn det en normal bruk av plattformen nødvendiggjør.
                    </p>
                    <p class="paragraph">
                      RS Sjøliv gir brukere en begrenset, ikke-eksklusiv, ikke-underlisensierbar, tilbakekallbar, ikke-overførbar lisens for å få tilgang til og bruke plattformen.
                    </p>

                    <p class="paragraph">
                      Med unntak av privat bruk er det ikke tillatt å kopiere, reprodusere, publisere, laste opp, sende eller distribuere noe materiale eller informasjon fra plattformen uten forutgående skriftlig tillatelse fra RS Sjøliv.
                    </p>
                    <p class="paragraph">
                      Bruk av automatiserte tjenester samt andre fremgangsmåter for systematisk eller regelmessig kopi av innholdet på plattformen er heller ikke tillatt uten eksplisitt skriftlig samtykke fra RS Sjøliv.
                    </p>

                    <h6 class="text-heading">
                      2.2 Brukergenerert innhold
                    </h6>
                    <p class="paragraph">
                      Brukergenerert innhold refererer til innholdet som er lagt til av brukere i motsetning til innhold laget av nettstedet. Alt innhold lastet opp til plattformen av våre brukere er brukergenerert innhold.  </p>
                    <p class="paragraph">
                      Enhver bruker er selv ansvarlig for at opplastet/opprettet innhold er korrekt og at bruker innehar de nødvendige rettigheter. RS Sjøliv sjekker ikke innholdet for hensiktsmessighet, brudd på copyright, varemerker eller andre rettigheter eller plikter. RS Sjøliv inviterer alle til å rapportere brudd sammen med bevis på eierforhold etter behov. Rapportert krenkende innhold kan fjernes eller deaktiveres. RS Sjøliv forbeholder seg retten til å utestenge brukere som misbruker, manipulerer eller anvender plattformen i strid med disse vilkårene eller de forutsetningene som ligger til grunn for den.
                    </p>
                    <h6 class="text-heading">
                      2.3 Forbudte tjenester
                    </h6>
                    <p class="paragraph">
                      Brukere skal bruke plattformen utelukkende til dens formål og vi oppfordrer alle til å bruke en trivelig og saklig tone både i annonser og i kommunikasjon med andre brukere og med RS Sjøliv.
                    </p>
                    <p class="paragraph">
                      Følgende er eksempel på handlinger og oppførsel som kan resultere i utestengelse fra plattformen: 
                    </p>
                    <ul class="terms-conditions-list">
                      <li> Bruke plattformen i strid med lover eller forskrifter eller for noe formål som bryter med lover eller forskrifter;</li>
                      <li> lage annonser som ikke er relatert til en konkret vare eller tjeneste;    </li>
                      <li> kommunisere med en annen bruker med mindre kommunikasjonen er relatert til bestilling, planlegging, endring eller kansellering av en vare eller tjeneste;</li>
                      <li> eksplisitt eller implisitt, skriftlig eller muntlig, foreslå eller kreve at ytelsen eller innholdet av en vare eller tjeneste skal avhenge av eventuelle betalinger eller tjenester eller andre forhold som ikke er tydelig angitt i oppføringen;</li>
                      <li> diskriminere eller trakassere noen på grunnlag av rase, nasjonal opprinnelse, religion, kjønn, kjønnsidentitet, fysisk eller psykisk funksjonshemning, medisinsk tilstand, sivilstand, alder eller seksuell legning;</li>
                      <li> omgå betalingsløsningen inkludert på RS Sjøliv ved å gjøre en avtale om betaling utenfor plattformen med en annen bruker som du fant gjennom plattformen;</li>
                      <li> kopiere, lagre eller på annen måte bruke informasjon som er innhentet gjennom plattformen til formål som ikke uttrykkelig er tillatt i henhold til disse vilkårene;</li>
                      <li> krenke RS Sjølivs rettigheter eller andre tredjepersons eller enheters rettigheter, inkludert, uten begrensning, deres immaterielle rettigheter, personvern, publisitet eller kontraktsrettigheter.</li>
                    </ul>
                    <p class="paragraph">
                      Tjenestetilbydere kan ikke benytte plattformen til generell beskrivelse og markedsføring av en bedrift eller generelt tilbud uten særskilt godkjenning fra RS Sjøliv.
                    </p>
                    <p class="paragraph">
                      For å sikre plattformens integritet forbeholder RS Sjøliv seg retten til når som helst, etter eget skjønn, å blokkere brukere fra å få tilgang til plattformen.
                    </p>
                  </div>

                  <div class="conditions-item">
                    <h4 class="sub-title text-uppercase">
                      <span class="count">3.</span> Kontraktsforhold
                    </h4>
                    <p class="paragraph">
                      Ved bestilling og kjøp av varer eller tjenester gjennom plattformen etableres et kontraktsforhold mellom tjenestetilbyder og kunde. Partene i handelen skal til enhver tid overholde lover og øvrig regelverk gjeldende for det konkrete avtaleforhold. 
                    </p>
                    <p class="paragraph">
                      RS Sjøliv er ikke part i kontraktsforholdet og har heller intet ansvar for feil eller mangler. RS Sjøliv er ikke ansvarlig for eventuelle reklamasjoner. Alle innsigelser og eventuelle krav om misligholdsbeføyelser skal ikke rettes mot RS Sjøliv, men gjøres gjeldende overfor den relevante motpart (tjenestetilbyder eller kunde).
                    </p>
                  </div>

                  <div class="conditions-item">
                    <h4 class="sub-title text-uppercase">
                      <span class="count">4.</span> Tilleggsvilkår
                    </h4>
                    <p class="paragraph">
                      Tilleggsvilkår kan gjelde for bestemte deler av varene eller tjenestene som tilbys. Disse skal følge klart av det konkrete tilbud. Hvis det er en konflikt mellom disse vilkårene og tilleggsvilkårene som er lagt ut for en spesifikk vare eller tjeneste, vil tilleggsvilkårene ha forrang med hensyn til den konkrete varen eller tjenesten. Tjenesteyter er ansvarlig for at slike tilleggsvilkår ikke begrenser kundenes rettigheter etter gjeldende lover og øvrige regelverk.
                    </p>
                  </div>

                  <div class="conditions-item">
                    <h4 class="sub-title text-uppercase">
                      <span class="count">5.</span> Søknad, registrering og godkjenning
                    </h4>
                    <p class="paragraph">
                      Alle som ønsker å registrere seg som tjenestetilbyder på plattformen skal godkjennes av RS Sjøliv.
                    </p>
                    <p class="paragraph">
                      Etter godkjenning av registrering vil tjenestetilbyder få tilgang til å tilby tjenester gjennom RS Sjøliv. </p>
                    <p class="paragraph">
                      Ved registrering må du oppgi den informasjon som RS Sjøliv anser nødvendig for din bruk av plattformen. Du samtykker i at du er ansvarlig for å oppdatere informasjonen i kontoen din slik at informasjonen til enhver tid er nøyaktig og fullstendig. Unnlatelse av å oppgi korrekt og tilstrekkelig informasjon kan føre til at RS Sjøliv nekter din tilgang til plattformen. Med mindre annet er tillatt av RS Sjøliv skriftlig, kan du bare ha en konto. Du kan ikke på noen måte overføre kontoen din til noen annen person eller enhet. 
                    </p>
                    <p class="paragraph">
                      Du bekrefter ved registrering at du representer tjenestetilbyder som fysisk person eller juridisk enhet. Du er ansvarlig for alle handlinger som blir utført gjennom kontoen, uavhengig av om slike handlinger blir utført av deg eller noen andre, og uansett om de blir utført med eller uten ditt samtykke. Du samtykker i å ikke gjøre brukernavn og passord tilgjengelig for andre enn de som er berettiget til dette og ta nødvendige forholdsregler for å unngå uautorisert tilgang til kontoen. 
                    </p>
                  </div>


                  <div class="conditions-item">
                    <h4 class="sub-title text-uppercase">
                      <span class="count">6.</span> Tilbuds- og bestillingsbetingelser
                    </h4>
                    <p class="paragraph">
                      Godkjente tjenestetilbydere vil få tilgang til sin egen portal på RS Sjøliv hvor varer og tjenester publiseres.  
                    </p>
                    <p class="paragraph">
                      Varer og tjenester oppført på plattformen kan bestilles av alle som har en konto. Alle bestillinger må gjøres ved hjelp av bestillingsknapp på nettstedet eller applikasjonen. Tjenestetilbydere vil så motta forespørselen/ bestillingen i portalen og/eller på mail.  </p>
                    <p class="paragraph">
                      Når kunder ber om en bestilling av en vare eller tjeneste, inngås en avtale mellom tjenestetilbyderen og kunden på det tidspunktet da tjenestetilbyderen eksplisitt godtar bestillingen fra kunden. Kunden kan trekke forespørselen så lenge forespørselen ikke er akseptert av tjenestetilbyderen. Hvis tjenestetilbyderen ikke svarer på en forespørsel innen 48 timer, blir forespørselen automatisk kansellert. 
                    </p>
                    <p class="paragraph">
                      Ved kurspåmelding er påmeldingen bindende etter at avmeldingsfristen er passert. </p>
                    <p class="paragraph">
                      Innholdet i enhver kommunikasjon mellom tjenestetilbyder og kunde som blir gjort gjennom RS Sjøliv Chat eller på annen måte i forbindelse med en bestilling, i den grad innholdet i en slik samtale ikke er i strid med noen bestemmelse i disse vilkårene, vil bli ansett som en integrert del av avtalen inngått mellom tjenestetilbyderen og kunden. 
                    </p>
                  </div>

                  <div class="conditions-item">
                    <h4 class="sub-title text-uppercase">
                      <span class="count">7.</span> Endring og avbestilling
                    </h4>
                    <p class="paragraph"> Såfremt ikke annet er avtalt mellom tjenestetilbyder og kunden gjelder følgende:  </p>
                    <ul class="terms-conditions-list">
                      <li>En bestilling kan bare kanselleres av kunden hvis avbestilling gjøres via kanselleringsalternativet på plattformen mer enn 48 timer før tjenesten var planlagt å begynne.</li>
                      <li>Hvis en bestilling kanselleres 48 timer eller mindre før tidspunktet da tjenesten var planlagt å begynne, vil kunden bli belastet hele prisen for tjenesten.</li>
                      <li>Disse vilkår innebærer ingen innskrenkninger i de regler som følger av angrerettloven for forbrukere. Tjenestetilbydere har ansvaret for at kunder er informert om gjeldende regler for angrerett for den tilbudte vare og/eller tjeneste.</li>
                    </ul>
                    <p class="paragraph">Omplanlegging kan avtales mellom tjenestetilbyderen og kunden.</p>
                  </div>

                  <div class="conditions-item">
                    <h4 class="sub-title text-uppercase">
                      <span class="count">8.</span> Betaling
                    </h4>
                    <p class="paragraph">
                      Før en bestilling finner sted, skal tjenestetilbyderen informere kunden om totalprisen for varen og/eller tjenesten, inkludert evt. moms (merverdiavgift).  </p>
                    <p class="paragraph">
                      RS Sjøliv benytter seg av Stripe som betalingsløsning. Stripe sine egne vilkår må aksepteres før betalingstjenesten tas i bruk. Transaksjoner via Stripe går direkte fra kunde til tjenestetilbyder. Tjenestetilbyder er ansvarlig for å dekke enhver avgift som pålegges av Stripe i forbindelse med betalingsløsningen. RS Sjøliv påtar seg ingen forpliktelser eller ansvar i tilknytning til betalingsformidlingen.  </p>
                  </div>

                  <div class="conditions-item">
                    <h4 class="sub-title text-uppercase">
                      <span class="count">9.</span> Personvern
                    </h4>

                    <p class="paragraph">
                      Personvern og beskyttelsen av personlige data er veldig viktig for RS Sjøliv. I forbindelse med bruk av plattformen, trenger RS Sjøliv å behandle visse personlige data om tjenestetilbydere. </p>
                    <p class="paragraph">
                      RS Sjøliv behandler kunder sine personlige data i henhold til  <a href="https://www.redningsselskapet.no/personvern/personvern-kursdeltakerer/" target="_blank">personvernerklæring for kunder og kursdeltakere.</a> </p>
                    <p class="paragraph">
                      Tjenestetilbydere har ansvar for å behandle all informasjon de får tilgang til via RS Sjøliv i tråd med gjeldende regler om personvern. </p>
                  </div>
                  

                  <div class="conditions-item">
                    <h4 class="sub-title text-uppercase">
                      <span class="count">10.</span> Ansvarsbegrensning
                    </h4>
                    <p class="paragraph">
                      Din bruk av plattformen, dens innhold og eventuelle varer eller tjenester er på egen risiko. Plattformen, dens innhold og eventuelle varer eller tjenester tilbudt og fått gjennom nettstedet leveres i tråd med disse vilkår og avtalen mellom kunde og tjenestetilbyder «som er» og «som tilgjengelig» uten noen garanti fra RS Sjøliv, uttrykkelig eller implisitt.
                    </p>
                    <p class="paragraph">
                      Tjenestetilbyder er ansvarlig for innholdet, kvaliteten og servicenivået som tilbys på RS Sjøliv. RS Sjøliv påtar seg ikke noe ansvar med hensyn til annonser, levering og kommunikasjon mellom brukere og tjenestetilbydere.
                    </p>
                    <p class="paragraph">
                      RS Sjøliv og dets datterselskaper, tilknyttede selskaper, ledere, ansatte, agenter og partnere holdes skadesløse for eventuelle krav, tap, forpliktelser og utgifter, inkludert advokatsalær, gjort av tredjepart på grunn av eller som følge av brudd på disse vilkårene, eller brudd på lov eller tredjeparts rettigheter. Ingenting i disse vilkårene skal anses å utelukke eller begrense ditt ansvar som bruker med hensyn til forpliktende erstatning i henhold til disse vilkårene.
                    </p>
                  </div>
              </div>
          </div>
      </div>
  </div>
</div>