import { Component, OnInit, Inject, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material//dialog';
import { PrivacyModelData } from '../../models/model-data';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ModalComponent implements OnInit {

  constructor(
    @Inject(MAT_DIALOG_DATA) public _data: PrivacyModelData,
    public matDialogRef: MatDialogRef<ModalComponent>
  ) { }

  ngOnInit() {
  }

}
